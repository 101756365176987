import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Loader from '../../components/company_panel/common/loader';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import { CouponDetails } from '../../services/company_panel/coupons.service';
import { SpecialCharacter, capitalizeAllFirstLetters, capitalizeFirstLetter, formateDate, getOutletId, removeHyphens, removeSpecialCharacter } from '../../helpers/commonFunctions';
import { CustomerDetails } from '../../services/company_panel/customer.services';
import CustomRangepicker from '../../components/company_panel/common/rangepicker';
import notFound from "../../../src/assets/company/img/no-record-found.png"
import CustomPagination from '../../components/company_panel/pginationNew';

const CustomerView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [showDefault, setShowDefault] = useState({})
  const breadcrumbs = [{ title: "Customers", url: "/company/crm/customers/list/1" }, {
    title: "Customer Detail",
    url: ""
  },]
  const [isBrandChanged, setIsBrandChanged] = useState(false);
  const [currentOutlet] = useState(getOutletId());
  const [isOutletChanged, setIsOutletChanged] = useState(false);
  const [globalSearch, setGlobalSearch] = useState("")
  const [itemPerPage, setItemPerPage] = useState(10)
  const [showData, setShowData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [resetdate, setResetDate] = useState(false);
  const [sorting, setSorting] = useState({});
  const [defaultSorting, setDefaultSorting] = useState(true);
  const [search, setSearch] = useState({})
  const [dataLength, setDataLength] = useState()
  const [page, setPage] = useState(1);
  const [query] = useSearchParams();

  useMemo(() => {
    document.addEventListener('brand-changed', function () {
      setIsBrandChanged(!isBrandChanged);
    });
    document.addEventListener('outlet-changed', function () {
      setIsOutletChanged(!isOutletChanged);
    });
    if (currentOutlet != getOutletId()) {
      navigate(`/company/crm/customers/list/${1}`);
    }
  });

  useEffect(() => {
    setLoader(true);
    const formData = new FormData()
    // formData.append("page", 1)
    formData.append("page", page)
    formData.append("per_page", itemPerPage)
    formData.append("sort", JSON.stringify(sorting))
    formData.append("search", JSON.stringify(search))
    formData.append("global_search", globalSearch)
    formData.append('id', params.id);
    CustomerDetails(formData).then(data => {
      setShowDefault(data && data.data && data.data.response ? data.data.response : [])
      setShowData(data && data.data && data.data.response ? data.data.response.customer_orders : [])
      setDataLength(data.data.response.count);
      setLoader(false);
    }).catch(error => {
      console.log("error=====>", error)
      setLoader(false);
    })
  }, [sorting, search, globalSearch])


  const generateOrderId = (orderId) => {
    let id = orderId;
    let countOfDigits = 0;
    while (id) {
      id = Math.floor(id / 10);
      countOfDigits += 1;
    }

    if (countOfDigits >= 4) {
      return orderId
    }
    let zerosCount = 4 - countOfDigits;
    let generatedZeros = '';
    while (zerosCount) {
      generatedZeros += '0';
      zerosCount -= 1;
    }
    return `${generatedZeros}${orderId}`;
  }

  const resetFilter = (e) => {
    e.preventDefault()
    setGlobalSearch("")
    prepareSearch()
    setSearch({})
    setResetDate(!resetdate)
  }

  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  }

  const viewCustomerOrderDetails = (id) => {
    try {
      navigate(`/company/transaction/order/view/${id}`)
    } catch (error) {
      console.log("Error===>", error)
    }
  };

  return (
    <>
      <div class="page-header d-flex align-items-center">
        <div class="page-header-left">
          <Breadcrums data={breadcrumbs} />
          <h4>Customers</h4>
        </div>
      </div>
      <div className="card custom-card">
        {showDefault && Object.keys(showDefault).length > 0 ?
          <div className="card-body p-0">
            <div className="col-lg-12 form-group">
              <table id="simple-table" className="table table-custom  ">
                <tbody>
                  <tr>
                    <td>Customer ID</td>
                    <td className='text-end'>{showDefault && showDefault.id ? '#' + (showDefault.id) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td className='text-end'>{showDefault && showDefault.customer_name ? capitalizeFirstLetter(showDefault.customer_name) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td >Phone Number</td>
                    <td className='text-end'>{showDefault && showDefault.customer_number ? (showDefault.customer_number) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td className='text-end'>{showDefault && showDefault.customer_address ? (capitalizeFirstLetter(showDefault.customer_address)) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Created Date</td>
                    <td className='text-end'>{showDefault && showDefault.created_at ? formateDate(showDefault.created_at) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>First Order Date</td>
                    <td className='text-end'>{showDefault && showDefault.first_order_date ? formateDate(showDefault?.first_order_date) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Last Order Date</td>
                    <td className='text-end'>{showDefault && showDefault.last_order_date ? formateDate(showDefault?.last_order_date) : "N/A"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> : <Loader />}
      </div>
      <div className="animation_fade">
        <div className='page-header d-flex align-items-center'>
          <div className='page-header-left mt-5'>
            <h4>Order Details</h4>
          </div>
        </div>
        <div className="card custom-card overflow-hidden">
          <div className='gap-20'>
            <div className='filter-header d-flex justify-content-between align-items-center'>
              <div className='d-flex gap-3 align-items-center'>
                <div className="form-group mb-0 rangepicker_container">
                  <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                </div>
              </div>
              <div className='d-flex gap-3 align-items-center'>
                <div className="date-input">
                  <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />
                </div>
                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
              </div>
            </div>
          </div>
          <div className="tabler-wrap">
            <div className="table-responsive">
              <table className="table table-custom">
                <thead>
                  <tr>
                    {/* <th className="sr_head" width="10%">S.No</th> */}
                    <th width="8%">
                      <div className='sorting_column'>
                        <span>Order ID</span>

                      </div>
                    </th>
                    <th width="9%">
                      <div className='sorting_column'>
                        <span>Brand</span>
                        {/* <Sorting sort={sorting} handleSort={handleSort} column="name" /> */}
                      </div>
                    </th>
                    <th width="9%">
                      <div className='sorting_column'>
                        <span>Outlet</span>
                        {/* <Sorting sort={sorting} handleSort={handleSort} column="name" /> */}
                      </div>
                    </th>
                    <th width="9%">
                      <div className='sorting_column'>
                        <span>Order mode</span>
                        {/* <Sorting sort={sorting} handleSort={handleSort} column="name" /> */}
                      </div>
                    </th>
                    <th width="10%">
                      <div className='sorting_column'>
                        <span>Aggregator Name</span>
                        {/* <Sorting sort={sorting} handleSort={handleSort} column="name" /> */}
                      </div>
                    </th>
                    <th width="7%">
                      <div className='sorting_column'>
                        <span>Order Status</span>
                        {/* <Sorting sort={sorting} handleSort={handleSort} column="name" /> */}
                      </div>
                    </th>
                    <th width="9%">
                      <div className='sorting_column'>
                        <span>Total Price</span>
                        {/* <Sorting sort={sorting} handleSort={handleSort} column="name" /> */}
                      </div>
                    </th>
                    <th width="8%">
                      <div className='sorting_column'>
                        <span>Payment Method</span>
                        {/* <Sorting sort={sorting} handleSort={handleSort} column="name" /> */}
                      </div>
                    </th>
                    <th width="8%">
                      <div className='sorting_column'>
                        <span>Payment Status</span>
                        {/* <Sorting sort={sorting} handleSort={handleSort} column="name" /> */}
                      </div>
                    </th>
                    <th width="9%">
                      <div className='sorting_column'>
                        <span>Service Type</span>
                        {/* <Sorting sort={sorting} handleSort={handleSort} column="name" /> */}
                      </div>
                    </th>
                    <th width="10%">
                      <div className='sorting_column'>
                        <span>Order Date</span>
                        {/* <Sorting sort={sorting} handleSort={handleSort} column="name" /> */}
                      </div>
                    </th>
                    <th className="created_head text-end" width="5%">
                      <span>Action</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loader ?
                    <tr>
                      <td colSpan={12}>
                        <Loader />
                      </td>
                    </tr> : <>
                      {showData.length ? showData.map((row, i) => (
                        <tr>
                          <td>#{(generateOrderId(row?.id))}</td>
                          <td>{row?.brand?.name ? row?.brand?.name : "N/A"}</td>
                          <td>{row?.outlet?.outlet_name ? row?.outlet?.outlet_name : "N/A"}</td>
                          <td>{row?.order_mode_configuration?.name ? row?.order_mode_configuration?.name : "N/A"}</td>
                          <td>{row?.aggregators_configuration?.name ? row?.aggregators_configuration?.name : "N/A"}</td>
                          <td className=''>{
                            row?.order_status == 'ready' ?
                              'Done' :
                              (row.order_status == "pending" ? "In kitchen" : capitalizeFirstLetter(row.order_status))
                          }</td>
                          <td>{row.transaction.amount ? (row?.transaction.amount + " " + "SAR") : "N/A"}</td>
                          <td>{(row.transaction.payment_method ? capitalizeFirstLetter(row?.transaction.payment_method) : "N/A")}</td>
                          <td>{(row.transaction.payment_status ? capitalizeFirstLetter(row?.transaction.payment_status) : "N/A")}</td>
                          <td>{capitalizeFirstLetter(removeSpecialCharacter(row.service_type.service_type))}</td>
                          <td>{formateDate(row?.created_at ? row?.created_at : "N/A")}</td>
                          <td className='text-end'>
                            <div class="dropdown  d-inline-block">
                              <button class="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className='ti ti-dots-vertical'></i>
                              </button>
                              <ul class="dropdown-menu">
                                <li><div className='dropdown-item' onClick={() => viewCustomerOrderDetails(row?.id)}><i class="ti ti-eye" aria-hidden="true"></i> View details</div></li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      )) : <tr>
                        <td colSpan={12} className="text-center">
                          <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                          <p>No records</p>
                        </td>
                      </tr>}
                    </>}
                </tbody>
              </table>
            </div>
            <div className='tabler-footer'>
              <div className='d-flex align-items-center'>
                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                {dataLength && dataLength > 0 ?
                  <CustomPagination datalength={dataLength} itemperpage={itemPerPage} currentPage={page} setPage={setPage} query={true} pageRoute={[{ name: "customer", path: `/company/crm/customers/view/${params.id}` }]} /> : ""}
              </div>
            </div>
          </div>
          <div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomerView