import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { Formik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoginService } from '../../services/company_panel/LoginCompanyServices';
import $ from 'jquery';
import LoginLayout from '../../companyloginlayout';
import Logo from '../../assets/company/img/logo.png';
import loginBg from '../../assets/company/img/login1.jpg';


const BrandListShow = () => {
  // localStorage.removeItem('company_user');

  const navigate = useNavigate()
  const [show, setShow] = useState('false')
  const [loader, setLoader] = useState('false')
  const [brandList, setBrandList] = useState([]);

  useEffect(() => {
    const brand = localStorage.getItem('brand_list');

    setBrandList(JSON.parse(brand));


  }, [])

  const handleshow = () => {
    setShow(!show)
  }

  const setOutlet = () => {

    const email = $('#username').val();
    const outletId = $('.outlet_id:checked').val();
    if (outletId) {
      setLoader(true)
      $('#outlet_error').html('');
      localStorage.setItem("brand_id", outletId);

      const brand = JSON.parse(localStorage.getItem('login_detail'));
      localStorage.setItem("company_token", brand.company_token);
      localStorage.setItem("company_user", JSON.stringify(brand.company_user));
      localStorage.setItem("company_detail", JSON.stringify(brand.company_detail));
      localStorage.setItem("permission", JSON.stringify(brand.permission));
      localStorage.removeItem('login_detail');
      localStorage.removeItem('brand_list');

      toast('Login Successfully');

      setTimeout(() => {
        setLoader(false)
        navigate(`/company/dashboard`)
      }, 2000);
      $('#addCardModal').hide();
      $('.modal-backdrop').remove();
    } else {
      $('#outlet_error').html("Brand is required");
    }

  }

  return (
    <LoginLayout>
      <div className="page">
        <div className="login-wraper">
          <div className='login-wraper-in'>
            <div className='login-left'>
              <figure>
                <img src={loginBg} />
              </figure>
              <div className='login-text'>
                <h4>Efficiency at your fingertips! </h4>
                <p>With POS Restaurant Admin, managing your  restaurant has never been easier. From orders to inventory, we've got  you covered.</p>
              </div>
            </div>
            <div className='login-right'>
              <div className='login-right-in'>
                <div class="login-head">
                  <Link to="/"> <img src={Logo} /> </Link>
                  <div class="login-head-text">
                    <h4>Choose Your Preferred Brand</h4>
                    <p>Please Choose One Brand from the List Below </p>
                  </div>
                </div>
                <div className='login-body'>
                  <div className='form-box'>
                    <div className="choose-outlet-list">
                      <ul>
                        {brandList.length ? brandList.map((row, i) => (
                          <li><label for={row.id}>{row.name} </label><input className='outlet_id' value={row.id} name='outlet_id' type="radio" id={row.id} />
                          </li>
                        )) : ''}
                      </ul>
                      <span className='text-danger text-left' id='outlet_error'>
                      </span>
                    </div>
                    <button className={"btn btn-primary mt-2 w-100 position-relative" + (loader === true ? " disabled" : "")} type="submit" onClick={(e) => setOutlet()}>Continue
                      {loader === true ?
                        <div className="position-absolute top-50 start-50 translate-middle">
                          <div className="spinner-border text-white mt-2 pe-2 text-center" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                        : ""}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        autoClose={true | 1000}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
    </LoginLayout>
  );
}

export default BrandListShow