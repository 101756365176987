import React, { useEffect, useMemo, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Breadcrums from '../../../components/company_panel/common/breadcrumbs';
import { AssignItems, DetailsGroups, GetAssignedItems, GetAvailableItems } from '../../../services/company_panel/routingStationService';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { capitalizeFirstLetter, getBrandId } from '../../../helpers/commonFunctions';
import Swal from 'sweetalert2';

const AssignItem = () => {
    const params = useParams();
    const navigate = useNavigate();
    const breadcrumbs = [
        {
            title: "Routing Station",
            url: "/company/menu/routing-station/groups/list/1"
        },
        {
            title: "Groups",
            url: "/company/menu/routing-station/groups/list/1"
        },
        {
            title: "Assign Items",
            url: ""
        },
    ]

    const [showDefault, setShowDefault] = useState({})
    const [availableItems, setAvailableItems] = useState([]);
    const [assignedItems, setAssignedItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentBrand] = useState(getBrandId());
    const [isBrandChanged, setIsBrandChanged] = useState(false);

    // useEffect(() => {
    //     GetAvailableItems(params.id, getBrandId()).then((response) => {
    //         setAvailableItems(response?.data?.response);
    //     })
    //         .catch((error) => {
    //             console.log("error===>", error)
    //         })
    // }, [])

    // useEffect(() => {
    //     GetAssignedItems(params.id).then((response) => {
    //         setAssignedItems(response?.data?.response);
    //     })
    //         .catch((error) => {
    //             console.log("error===>", error)
    //         })
    // }, [])
    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        setTimeout(() => {
            if (currentBrand != getBrandId()) {
                navigate(`/company/menu/routing-station/groups/list/${1}`);
            }
        }, 100);
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const availableResponse = await GetAvailableItems(params.id, getBrandId());
                setAvailableItems(availableResponse?.data?.response);
                const assignedResponse = await GetAssignedItems(params.id);
                setAssignedItems(assignedResponse?.data?.response);
            } catch (error) {
                console.log("error===>", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [params.id]);

    useEffect(() => {
        DetailsGroups(params.id).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    // const handleOnDragEnd = (result) => {
    //     const { source, destination } = result;
    //     if (!destination) return;

    //     let newAvailableItems = [...availableItems];
    //     let newAssignedItems = [...assignedItems];

    //     if (source.droppableId === 'availableItems' && destination.droppableId === 'assignedItems') {
    //         const [movedItem] = newAvailableItems.splice(source.index, 1);
    //         newAssignedItems.splice(destination.index, 0, movedItem);
    //     } else if (source.droppableId === 'assignedItems' && destination.droppableId === 'availableItems') {
    //         const [movedItem] = newAssignedItems.splice(source.index, 1);
    //         newAvailableItems.splice(destination.index, 0, movedItem);
    //     } else if (source.droppableId === destination.droppableId) {
    //         const items = source.droppableId === 'availableItems' ? newAvailableItems : newAssignedItems;
    //         const [movedItem] = items.splice(source.index, 1);
    //         items.splice(destination.index, 0, movedItem);
    //     }

    //     setAvailableItems(newAvailableItems);
    //     setAssignedItems(newAssignedItems);
    // };

    const handleOnDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return;

        // Determine the source and destination arrays based on the droppableId
        const isSourceAvailable = source.droppableId === 'availableItems';
        const isDestinationAvailable = destination.droppableId === 'availableItems';

        // Create copies of the original arrays to avoid directly mutating state
        const newAvailableItems = [...availableItems];
        const newAssignedItems = [...assignedItems];

        // Identify and move the item between arrays
        const [movedItem] = isSourceAvailable
            ? newAvailableItems.splice(source.index, 1)
            : newAssignedItems.splice(source.index, 1);

        if (isDestinationAvailable) {
            newAvailableItems.splice(destination.index, 0, movedItem);
        } else {
            newAssignedItems.splice(destination.index, 0, movedItem);
        }

        // Update the state based on the modified arrays
        setAvailableItems(newAvailableItems);
        setAssignedItems(newAssignedItems);
    };


    function handleSave() {
        const itemIds = assignedItems.map((item) => item.id);
        AssignItems({ id: params.id, brand_id: getBrandId(), assigned_items: JSON.stringify(itemIds) })
            .then((response) => {
                if (response.data.status === 1 || response.data.status === "1") {
                    Swal.fire({
                        customClass: 'swal-wide',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 1500,
                        toast: true,
                        position: 'top-right',
                    });
                    setTimeout(() => {
                        navigate(`/company/menu/routing-station/groups/list/${1}`)
                    }, 1000);
                } else {
                    Swal.fire({
                        customClass: 'swal-wide',
                        icon: 'error',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 5000,
                        toast: true,
                        position: 'top-right',
                    })
                }

            })
            .catch((error) => {
                console.log("error===>", error)
            })
    }


    return (
        <>
            <div class="page-header d-flex align-items-center">
                <div class="page-header-left">
                    <h4>Routing Station</h4>
                    <Breadcrums data={breadcrumbs} />
                </div>
                <div class="page-header-right ms-auto">
                </div>
            </div>
            <div className="card custom-card">
                {!loading && availableItems?.length === 0 && assignedItems?.length === 0 ? (
                    <div className="alert alert-warning text-center mt-2">
                        There is no Menu Item created yet. Please{' '}
                        <Link to="/company/menu/menu/menu_items/add">
                            create a menu Item
                        </Link>.
                    </div>
                ) : null}


                <div className="card-body">
                    <div className="card">

                        <div className="card-body">

                            <div className="d-flex justify-content-between p-0">
                                <p className='m-0 fw-bold'>Group Name</p>
                                <div>{showDefault?.name}</div>
                            </div>
                        </div>
                    </div>
                    <p className='mt-4 fw-bold'>Drag and drop items onto the right-hand side<span className='requirestar'>*</span></p>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <div className="row">
                            <Droppable droppableId="availableItems">
                                {(provided) => (
                                    <div className="col-md-6" {...provided.droppableProps} ref={provided.innerRef} >
                                        <div className='card'>
                                            <div className="card-body">
                                                <h5>Available Items</h5>
                                                {availableItems.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={"item_" + item.id} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className="draggable-item"
                                                            >
                                                                {capitalizeFirstLetter(item?.item_name)}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                            <Droppable droppableId="assignedItems">
                                {(provided) => (
                                    <div className="col-md-6" {...provided.droppableProps} ref={provided.innerRef} >
                                        <div className='card'>
                                            <div className="card-body">
                                                <h5>Assigned Items</h5>
                                                {assignedItems.map((item, index) => (
                                                    <Draggable key={index} draggableId={"item_" + item.id} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className="draggable-item"
                                                            >
                                                                {capitalizeFirstLetter(item?.item_name)}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            </div>
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </DragDropContext>
                    <div className="mt-5">
                        <button className="btn btn-primary mr-2" type="button" onClick={handleSave}>
                            Save
                        </button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AssignItem;
