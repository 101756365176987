import React, { useState, useEffect, useRef, useMemo } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import {
  GetChangeStatus,
  GetOrderModeList,
  DeleteOrderMode,
  CreateOrderMode,
  GetOrderModeDetail,
  GetEditOrderMode,
} from "../../../services/company_panel/setting/orderModeConfigurationService";
import Swal from "sweetalert2";
import CustomPagination from "../../../components/company_panel/common/custompagination";
import Sorting from "../../../components/company_panel/common/sorting";
import Loader from "../../../components/company_panel/common/loader";
import $, { error, trim } from "jquery";
import {
  capitalizeAllFirstLetters,
  checkPermission,
  getBrandId,
  getCompany,
  removeModalBackdrops,
} from "../../../helpers/commonFunctions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfigurationTab from "../configurationTab";
import { GetAllActiveTaxes } from "../../../services/company_panel/setting/taxConfigurationService";
import { Dropdown } from "semantic-ui-react";
import * as yup from "yup";
import notFound from "../../../assets/company/img/no-record-found.png";
import { LOADER_TIME } from "../../../utils/Constants";
import defaultImage from '../../../assets/company/img/default_img.png';
import { GetAggregatorAllDetails, GetAggregatorList } from "../../../services/company_panel/setting/aggregatorConfigurationService";
import CustomMultiSelect from "../../../components/common/CustomMultiSelect";

// import * as bootstrap from 'bootstrap';

const OrderModeList = () => {
  const breadcrumbs = [
    { title: "Dashboard", url: "/company/dashboard" },
    { title: "Pages", url: "" },
  ];
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const params = useParams();
  const location = useLocation();
  const [list, setList] = useState([]);
  const [statsUpdate, setStatusUpdate] = useState("false");
  const [dataLength, setDataLength] = useState();
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sorting, setSorting] = useState({});
  const [defaultSorting, setDefaultSorting] = useState(true);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState({});
  const [globalSearch, setGlobalSearch] = useState("");
  const [resetdate, setResetDate] = useState(false);
  const [showDefault, setShowDefault] = useState({});
  const [actionType, setActionType] = useState("add");
  const [taxGroupList, setTaxGroupList] = useState([]);
  const [isOverwrite, setIsOverwrite] = useState(false)
  const [isBrandChanged, setIsBrandChanged] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [aggregatorList, setAggregatorList] = useState({});
  const addCardModalCloseBtn = useRef();
  const imageUploadRef = useRef(null);

  useMemo(() => {
    document.addEventListener('brand-changed', function () {
      setIsBrandChanged(!isBrandChanged);
    });
  });

  // useEffect(() => {
  //   return () => {
  //     const modals = document.querySelectorAll('.modal-backdrop');
  //     modals.forEach((item=>{
  //       item.remove();
  //     }))
  //   }
  // }, []);
  useEffect(() => {
    return () => {
      removeModalBackdrops();
    }
  }, []);

  useEffect(() => {
    GetAggregatorAllDetails({ brand_id: getBrandId() })
      .then(data => {
        setAggregatorList(data && data?.data && data?.data?.response ? data?.data?.response : []);
      })
      .catch((error) => {
        console.log("error===>", error);
      })
  }, [isBrandChanged])

  useEffect(() => {
    if (search) {
      setLoader(true);
      const formData = new FormData();
      formData.append("page", params.id);
      formData.append("per_page", itemPerPage);
      formData.append("sort", JSON.stringify(sorting));
      formData.append("search", JSON.stringify(search));
      formData.append("global_search", globalSearch);
      formData.append('brand_id', getBrandId());
      GetOrderModeList(formData)
        .then((data) => {
          setDataLength(data.data.response.count);
          setList(data && data.data && data.data.response ? data.data.response.rows : []);
          setTimeout(() => {
            setLoader(false);
          }, LOADER_TIME);
        })
        .catch((error) => {
          setTimeout(() => {
            setLoader(false);
          }, LOADER_TIME);
          console.log("error ====> ", error);
        });
    }
    generateSKU();
  }, [location, statsUpdate, sorting, search, globalSearch, isBrandChanged]);

  useEffect(() => {
    GetAllActiveTaxes({ brand_id: getBrandId(), type: 'taxes' })
      .then((data) => {
        const taxGroup =
          data && data?.data && data?.data?.response
            ? data.data.response
            : [];
        const taxGroupDropdown = taxGroup.map((res) => ({
          key: res.id,
          text: capitalizeAllFirstLetters(res?.name),
          value: res.id,
        }));
        setTaxGroupList(taxGroupDropdown);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, []);

  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    setSearch({});
    prepareSearch();
    setResetDate(!resetdate);
    $("#defaultstatus")[0].selectedIndex = 0;
  };

  // Delete functionality
  function deleteFunction(row) {
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2f9bff",
      cancelButtonColor: "#dc3545",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const formdata = new FormData();
        formdata.append("id", row);
        DeleteOrderMode(row)
          .then((data) => {
            if (data.status === 200) {
              Swal.fire({
                customClass: "swal-wide",
                position: "absolute",
                icon: "success",
                title: "Order mode deleted successfully",
                showConfirmButton: false,
                timer: 2000,
                toast: true,
                position: "top-right",
              });
              setStatusUpdate(!statsUpdate);
            }
          })
          .catch((error) => {
            console.log("deleteError");
          });
      }
    });
  }

  // Change Status functionality

  const ChangeStatus = (row, Num) => {
    const formData = new FormData();
    formData.append("id", row);
    formData.append("status", Num);
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2f9bff",
      cancelButtonColor: "#dc3545",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        GetChangeStatus(formData)
          .then((data) => {
            if (data.status === 200) {
              Swal.fire({
                customClass: "swal-wide",
                position: "absolute",
                icon: "success",
                title: data.data.message,
                showConfirmButton: false,
                timer: 2000,
                toast: true,
                position: "top-right",
              });
              setStatusUpdate(!statsUpdate);
            }
          })
          .catch((error) => {
            console.log("error===>", error);
          });
      }
    });
  };

  // edit functionality

  const editFunction = (row) => {
    GetOrderModeDetail(row)
      .then((data) => {
        console.log("data.data.response--->", data.data.response.tax_group);
        setShowDefault(
          data && data.data && data.data.response ? data.data.response : []
        );
        setPreviewImage(data?.data?.response?.logo);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
    setActionType("edit");
    // $("#addCardModal").show().css("opacity", 1).addClass("show");
    // $("body").append('<div className="modal-backdrop fade show"></div>');
  };

  const copyFunction = (row) => {
    GetOrderModeDetail(row)
      .then((data) => {
        setShowDefault(
          data && data.data && data.data.response ? data.data.response : []
        );
        setPreviewImage(data?.data?.response?.logo);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
    setActionType("add");
    // $("#addCardModal").show().css("opacity", 1).addClass("show");
    // $("body").append('<div className="modal-backdrop fade show"></div>');
  };

  const handleSort = (e, column) => {
    setDefaultSorting(false);
    let sort = { order: 0, column: column };
    if (e.target.classList.contains("assc")) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  };

  // search or filter function
  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };
  // search or filer end

  // addMore

  const generateSKU = (length = 8) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let sku = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      sku += characters[randomIndex];
    }
    formik.setFieldValue("sku", sku);
  };

  const closeModel = (id) => {
    // $("#addCardModal").hide();
    // $(".modal-backdrop").remove();
    setShowDefault({});
    setStatusUpdate(!statsUpdate);
    formik.setErrors({});
    formik.resetForm();
    // formik.setFieldValue('logo',)
    setPreviewImage("");
  };

  const openModel = (id, type) => {
    formik.setErrors({});
    formik.resetForm();
    setActionType(type);
    $("#" + id).show().css("opacity", 1).addClass("show");
    $("body").append('<div className="modal-backdrop fade show"></div>');
  };

  // Add and Edit Data
  const validationSchema = yup.object().shape({
    logo: yup.mixed().required("Logo is required"),
    name: yup
      .string()
      .trim()
      .required("Name is required")
      .min(3, "Name must be at least 3 digits")
      .max(30, "Name cannot exceed 30 digits"),
    name_ar: yup
      .string()
      .trim()
      .required("Name (Arabic) is required")
      .min(3, "Name (Arabic) must be at least 3 digits")
      .max(30, "Name (Arabic) cannot exceed 30 digits"),
    sku: yup.string().trim().required("SKU is required"),
    surcharge: yup
      .number()
      .required("Surcharge is required")
      .max(30, "Surcharge cannot exceed 30 digits"),
    // status: yup.string().required('Status is required'),
    // tax_group: isOverwrite ? yup.string().required('Tax Group is required') : '',
    tax: yup.string().required('Tax is required'),
    tax_group: yup.string().test(
      'is-required',
      'Tax is required',
      function (value) {
        const { tax } = this.parent;
        if (tax === 'overwrite' && !value) {
          return false;
        }
        return true;
      }
    ),
    aggregators: yup.array().test(
      'aggregators-required',
      'At least one aggregator is required',
      function (value) {
        const { aggregator_exists } = this.parent; 
        if (aggregator_exists) {
          return value && value.length > 0; 
        }
        return true; 
      }
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: showDefault && showDefault.name ? showDefault.name : "",
      name_ar: showDefault && showDefault.name_ar ? showDefault.name_ar : "",
      sku: showDefault && showDefault.sku ? showDefault.sku : "",
      surcharge:
        showDefault && showDefault.surcharge ? showDefault.surcharge : "",
      status: showDefault && showDefault.status == 1 ? "on" : "",
      table_service_exists: showDefault && showDefault.table_service_exists ? showDefault.table_service_exists : false,
      aggregator_exists: showDefault && showDefault.aggregator_exists ? showDefault.aggregator_exists : false,
      aggregators: showDefault && showDefault.order_mode_relations ? showDefault.order_mode_relations : [],
      tax: showDefault && showDefault.tax ? showDefault.tax : 'primary',
      tax_group:
        showDefault && showDefault?.tax_id ? showDefault?.tax_id : "",
      logo:
        showDefault && showDefault?.logo ? showDefault?.logo : "",
      // tax_group:12,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSubmitted(true);
      let formData = new FormData();
      formData.append("name", values?.name?.trim());
      formData.append("name_ar", values?.name_ar?.trim());
      formData.append("sku", values?.sku?.trim());
      formData.append("surcharge", values.surcharge);
      formData.append("status", values.status == "on" ? 1 : 0);
      formData.append("aggregator_exists", values.aggregator_exists);
      if (values.aggregator_exists) {
        formData.append("aggregators", JSON.stringify(values.aggregators));
      }
      formData.append("table_service_exists", values.table_service_exists);
      formData.append("company_id", getCompany().company_id);
      formData.append('brand_id', getBrandId());
      formData.append(
        "id",
        showDefault && showDefault.id ? showDefault.id : ""
      );
      formData.append("tax", values.tax);
      if (values?.tax == "overwrite") {
        formData.append("tax_id", values.tax_group);
      }
      formData.append("logo", values.logo);

      if (actionType === "edit") {
        GetEditOrderMode(formData)
          .then((response) => {
            if (response.data.status === 1 || response.data.status === "1") {
              Swal.fire({
                customClass: "swal-wide",
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                position: "top-right",
              });
              setTimeout(() => {
                // const myModal = new bootstrap.Modal(document.getElementById('addCardModal'))
                // myModal.hide(); 
                // $("#addCardModal").hide();
                // $(".modal-backdrop").remove();
                addCardModalCloseBtn?.current?.click();
                setStatusUpdate(!statsUpdate);
                formik.resetForm();
                imageUploadRef.current.value = "";
                setPreviewImage('');
                setShowDefault({});
              }, 1000);
            } else {
              Swal.fire({
                customClass: "swal-wide",
                icon: "error",
                title: response.data.message,
                showConfirmButton: false,
                timer: 5000,
                toast: true,
                position: "top-right",
              });
              setSubmitted(false);
            }
          })
          .catch((error) => {
            console.log("error ====> ", error);
          });
      } else {
        CreateOrderMode(formData)
          .then((response) => {
            if (response.data.status === 1 || response.data.status === "1") {
              Swal.fire({
                customClass: "swal-wide",
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                position: "top-right",
              });
              setTimeout(() => {
                addCardModalCloseBtn?.current?.click();
                setStatusUpdate(!statsUpdate);
                formik.resetForm();
                imageUploadRef.current.value = "";
                setPreviewImage('');
                setShowDefault({});
              }, 1000);
            } else {
              Swal.fire({
                customClass: "swal-wide",
                icon: "error",
                title: response.data.message,
                showConfirmButton: false,
                timer: 5000,
                toast: true,
                position: "top-right",
              });
              setSubmitted(false);
            }
          })
          .catch((error) => {
            console.log("error ====> ", error);
          });
      }
    },
  });

  return (
    <>
      <div className="animation_fade">
        <div className="page-header d-flex align-items-center">
          <div className="page-header-left">
            <h4>Settings</h4>
          </div>
          <div className="page-header-right ms-auto"></div>
        </div>
        <div className="card custom-card overflow-hidden">
          <div className="gap-20 border-bottom">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center">
                <ConfigurationTab tab="order-mode" />
              </div>
            </div>
          </div>
          <div className="gap-20">
            <div className="page-header d-flex align-items-center mb-0">
              <div className="page-header-left"></div>
              <div className="page-header-right ms-auto">
              {checkPermission('store_settings', 'add') ?
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-toggle="modal" data-bs-target="#addCardModal"
                  // onClick={(e) => openModel("addCardModal", "add")}
                  onClick={() => setActionType('add')}
                >
                  Add Order Mode
                </button>
                : '' }
              </div>
            </div>
          </div>
          <div className="tabler-wrap">
            <div className="table-responsive">
              <table className="table table-custom">
                <thead>
                  <tr>
                    <th width="10%">
                      <div className="sorting_column">
                        <span>ID </span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          column="id"
                        />
                      </div>
                    </th>
                    <th width="20%">
                      <div className="sorting_column">
                        <span>Name</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          column="name"
                        />
                      </div>
                    </th>
                    <th width="20%">
                      <div className="sorting_column">
                        <span>Name (Arabic)</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          column="name_ar"
                        />
                      </div>
                    </th>
                    <th width="15%">
                      <div className="sorting_column">
                        <span>SKU </span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          column="sku"
                        />
                      </div>
                    </th>
                    <th width="15%">
                      <div className="sorting_column">
                        <span>Surcharge </span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          column="surcharge"
                        />
                      </div>
                    </th>
                    <th className="status_head text-center" width="10%">
                      Status
                    </th>
                    <th className="action_head text-end" width="10%">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <tr>
                      <td colSpan={11}>
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {list.length ? (
                        list.map((row, i) => (
                          <tr>
                            <td>{row?.id}</td>
                            <td>{capitalizeAllFirstLetters(row?.name)}</td>
                            <td>{capitalizeAllFirstLetters(row?.name_ar)}</td>
                            <td>{row?.sku}</td>
                            <td>{row?.surcharge}</td>
                            <td>
                              <div className="form-check form-switch w-25 mx-auto">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="status-change"
                                  name="status"
                                  checked={row?.status == 1 ? "checked" : ""}
                                  onClick={() => {
                                    ChangeStatus(
                                      row?.id,
                                      row?.status == 1 ? 0 : 1
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  for="status-change"
                                ></label>
                              </div>
                            </td>
                            <td className="text-end">
                              <div className="dropdown  d-inline-block">
                                <button
                                  className="btn btn-icon btn-secondary"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="ti ti-dots-vertical"></i>
                                </button>
                                <ul className="dropdown-menu">
                                {checkPermission('store_settings', 'edit') ?
                                  <li>
                                    <div
                                      className="dropdown-item"
                                      data-bs-toggle="modal" data-bs-target="#addCardModal"
                                      onClick={() => {
                                        editFunction(row?.id);
                                      }}
                                    >
                                      <i
                                        className="ti ti-edit"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Edit
                                    </div>
                                  </li>
                                  : '' }
                                  {
                                    row.default_slug != 'default' ||  checkPermission('store_settings', 'delete')  ?
                                      <li>
                                        {" "}
                                        <div
                                          className="dropdown-item"
                                          onClick={() => {
                                            deleteFunction(row?.id);
                                          }}
                                        >
                                          <i
                                            className="ti ti-trash"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Delete
                                        </div>
                                      </li> : null
                                  }
                                   {checkPermission('store_settings', 'add') ?
                                  <li> <div className='dropdown-item' data-bs-toggle="modal" data-bs-target="#addCardModal" onClick={() => { copyFunction(row?.id) }}><i class="ti ti-copy" aria-hidden="true"></i> Copy Order Mode</div></li>
                                  : '' }
                                </ul>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={11} className="text-center">
                            <img
                              src={notFound}
                              style={{ width: "5%" }}
                              alt="Not Found"
                            />
                            <p>No record found</p>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className="tabler-footer">
              <div className="d-flex align-items-center">
                <div
                  id="example1_info"
                  role="status"
                  className="table-result"
                  aria-live="polite"
                >
                  <b>Total Records : {dataLength ? dataLength : "0"}</b>
                </div>
                {dataLength && dataLength > 0 ? (
                  <CustomPagination
                    datalength={dataLength}
                    itemperpage={itemPerPage}
                    setPage={setPage}
                    currentPage={page}
                    pageRoute={[
                      {
                        name: "outlet-management",
                        path: "/company/setting/order-mode-configuration/list",
                      },
                    ]}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        {/*  Modal*/}

        <div
          className="modal fade"
          id="addCardModal"
          aria-labelledby="addCardModalLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title" id="addCardModalLabel">
                  {actionType === "edit" ? "Edit" : "Add"} Order Mode
                </h2>
                <button
                  type="button"
                  className="btn-close"
                  ref={addCardModalCloseBtn}
                  onClick={(e) => closeModel("addCardModal")}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="modal-body">

                  <div className='upload-logo d-flex align-items-center gap-3 mb-3'>
                    <div class="avatar avatar-xl">

                      {previewImage ?

                        <img alt="avatar" src={previewImage} className={previewImage ? "" : "d-none"} />
                        :
                        <img alt="avatar" src={defaultImage} />
                      }

                    </div>
                    <div className='btn btn-black btn-file-type'>Upload logo <span className="requirestar">*</span><input id="logo" ref={imageUploadRef} name="logo" accept="image/*" type="file" onBlur={formik.handleBlur} onChange={(event) => {
                      formik.setFieldValue("logo", event.currentTarget.files[0] || "");
                      { event.currentTarget.files.length == 1 ? (setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))) : (setPreviewImage("")) }
                    }}
                    /></div>
                  </div>
                  <span className="text-danger d-flex text-left mb-2"> {formik.errors.logo && formik.touched.logo && formik.errors.logo} </span>

                  <div className="mb-3">
                    <label for="name" className="col-form-label">
                      Name<span className="requirestar">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Enter Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      autoComplete="off"
                    />
                    <span className="text-danger d-flex text-left">
                      {formik.errors.name &&
                        formik.touched.name &&
                        formik.errors.name}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label for="name_ar" className="col-form-label">
                      Name (Arabic)<span className="requirestar">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name_ar"
                      id="name_ar"
                      placeholder="Enter Name (Arabic)"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name_ar}
                      autoComplete="off"
                    />
                    <span className="text-danger d-flex text-left">
                      {formik.errors.name_ar &&
                        formik.touched.name_ar &&
                        formik.errors.name_ar}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label for="sku" className="col-form-label">
                      SKU<span className="requirestar">*</span>
                    </label>
                    <div className="set-plus-icon-input">
                      <input
                        type="text"
                        name="sku"
                        className="form-control"
                        id="sku"
                        placeholder="Enter SKU"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.sku}
                        autoComplete="off"
                      />
                      {actionType != "edit" ? (
                        <div className="icon-box">
                          <a onClick={(e) => generateSKU(8)}>
                            <i className="fa fa-rotate-right"></i>
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <span className="text-danger d-flex text-left">
                      {formik.errors.sku &&
                        formik.touched.sku &&
                        formik.errors.sku}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label for="surcharge" className="col-form-label">
                      Surcharge<span className="requirestar">*</span>
                    </label>
                    <input
                      type="number"
                      name="surcharge"
                      className="form-control"
                      id="surcharge"
                      placeholder="Enter Surcharge"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.surcharge}
                      autoComplete="off"
                    />
                    <span className="text-danger d-flex text-left">
                      {formik.errors.surcharge &&
                        formik.touched.surcharge &&
                        formik.errors.surcharge}
                    </span>
                  </div>

                  <div className="mb-3">
                    <label for="order_mode" className="col-form-label">
                      Tax
                    </label>
                    <div className="row ps-3">
                      <div className="form-check w-25">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="tax"
                          id="primary"
                          value="primary"
                          autoComplete="off"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={
                            formik.values.tax == "primary" ? "checked" : ""
                          }
                        />
                        <label className="form-check-label" for="primary">
                          Primary
                        </label>
                      </div>
                      <div className="form-check w-25">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="tax"
                          id="secondary"
                          value="secondary"
                          autoComplete="off"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={
                            formik.values.tax == "secondary" ? "checked" : ""
                          }
                        />
                        <label className="form-check-label" for="secondary">
                          Secondary
                        </label>
                      </div>
                      <div className="form-check w-25">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="tax"
                          id="overwrite"
                          value="overwrite"
                          autoComplete="off"
                          onChange={(e) => { formik.handleChange(e); setIsOverwrite(!isOverwrite) }}
                          onBlur={formik.handleBlur}
                          checked={
                            formik.values.tax == "overwrite" ? "checked" : ""
                          }
                        />
                        <label className="form-check-label" for="overwrite">
                          Overwrite
                        </label>
                      </div>
                      {/* <div className="form-check w-25">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="tax"
                          id="none"
                          value="none"
                          autoComplete="off"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.tax == "none" ? "checked" : ""}
                        />
                        <label className="form-check-label" for="none">
                          None
                        </label>
                      </div> */}
                    </div>
                    {/* <span className="text-danger d-flex text-left">
                      {formik.errors.tax &&
                        formik.touched.tax &&
                        formik.errors.tax}
                    </span> */}
                  </div>
                  {formik.values.tax === "overwrite" ? (
                    <div className="mb-3">
                      {/* <Dropdown
                        className="form-control"
                        id="tax_group"
                        defaultValue={formik?.values?.tax_group}
                        search
                        fluid
                        selection
                        options={taxGroupList}
                        value={formik?.values?.tax_group}
                        onChange={(event, data) => {
                          formik.setFieldValue("tax_group", data?.value);
                        }}
                      /> */}
                      <select id="tax_group" className='form-control' name='tax_group' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik?.values?.tax_group}>
                        <option value="">Please Select</option>
                        {taxGroupList?.length ? taxGroupList.map((row) => (
                          <option value={row.value}>{capitalizeAllFirstLetters(row?.text)}</option>
                        )) : ''}
                      </select>
                      <span className="text-danger d-flex text-left">
                        {formik.errors.tax_group &&
                          formik.touched.tax &&
                          formik.errors.tax_group}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="form-check text-start mb-3">
                    <input
                      className="form-check-input cp"
                      type="checkbox"
                      name="table_service_exists"
                      id="table_service_exists"
                      autocomplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.table_service_exists}
                    />
                    <label className="form-check-label cp" for="table_service_exists">
                      Table Service
                    </label>
                  </div>

                  <div className="form-check text-start mb-3">
                    <input
                      className="form-check-input cp"
                      type="checkbox"
                      name="aggregator_exists"
                      id="aggregator_exists"
                      autocomplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.aggregator_exists}
                    />
                    <label className="form-check-label cp" for="aggregator_exists">
                      Aggregator
                    </label>
                  </div>

                  {formik?.values?.aggregator_exists &&
                    <div className="mb-3">
                      <label for="aggregators" className="col-form-label">Aggregator<span className='requirestar'>*</span></label>
                      <CustomMultiSelect menuClassName='popup-select' overFlowVisibleOptions={1} data={aggregatorList.map(item => { return { label: item.name, value: item.id } })} form={formik} name='aggregators' />
                      <span className='text-danger d-flex text-left'>{formik.errors.aggregators && formik.touched.aggregators && formik.errors.aggregators}</span>
                    </div>
                  }

                  <div className="form-check form-switch w-25">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="status"
                      name="status"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.status == "on" ? "checked" : ""}
                      autoComplete="off"
                    />
                    <label className="form-check-label" for="status">
                      Status
                    </label>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    style={{ width: "48%" }}
                    data-bs-dismiss="modal"
                    onClick={(e) => closeModel("addCardModal")}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{ width: "48%" }}
                  >
                    {actionType === "edit" ? "Update" : "Add"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          hideProgressBar={false}
          autoClose={true | 1000}
          newestOnTop={true}
          closeOnClick={false}
          draggable={false}
          rtl={false}
        />
      </div>
    </>
  );
};

export default OrderModeList;
