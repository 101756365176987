import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCompany, companyDetail, checkPermission, getUser } from '../../helpers/commonFunctions';
import { GetSubscriptionsList, GetSingleSubscription, EmailSend, RenewSubscriptionEmailSend, UpgradeSubscriptionEmailSend } from '../../services/company_panel/subscriptionService';
import { SUBSCRIPTION_CONTENT_LENGTH } from '../../utils/Constants';

const ListSubscription = () => {
    const navigate = useNavigate();
    const [showDefault, setShowDefault] = useState([]);
    const [currentSubscription, setCurrentSubscription] = useState({});
    const [expandedPlans, setExpandedPlans] = useState({});

    useEffect(() => {
        GetSubscriptionsList({})
            .then(data => {
                setShowDefault(data?.data?.response || []);
            })
            .catch(error => {
                console.log("error=====>", error);
            });

        GetSingleSubscription({ company_register_id : companyDetail().company_id })
            .then(data => {
                setCurrentSubscription(data?.data?.response || {});
            })
            .catch(error => {
                console.log("error=====>", error);
            });
    }, []);

    function stripHtml(html) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || '';
    }

    // Function to toggle read more/less
    const toggleReadMore = (id) => {
        setExpandedPlans(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    // Function to get the truncated or full text based on the expanded state
    const renderContent = (content, isExpanded) => {
        const words = content.split(' ');
        if (words.length > SUBSCRIPTION_CONTENT_LENGTH) {
            return isExpanded ? content : words.slice(0, SUBSCRIPTION_CONTENT_LENGTH).join(' ') + '...';
        }
        return content;
    };

const sendEmail = () => {
    const formData = new FormData()
    formData.append("user_id", getUser().id);
    formData.append("company_id", getCompany().company_id);
    Swal.fire({
        customClass: 'swal-wide',
        title: 'Are you sure?',
        text: "You want to cancel this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2f9bff',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Yes, cancel it!'
    }).then((result) => {
        if (result.isConfirmed) {
            EmailSend(formData).then(data => {
                if (data.status === 200) {
                    Swal.fire({
                        customClass: 'swal-wide',
                        position: 'absolute',
                        icon: 'success',
                        title: 'Email send successfully to administrator for cancel subscription',
                        showConfirmButton: false,
                        timer: 2000,
                        toast: true,
                        position: 'top-right',
                    })
                }
            }).catch(error => {
                console.log("error===>")
            })
        }
    })
}

const sendEmailRenewSubscription = () => {
    const formData = new FormData()
    formData.append("user_id", getUser().id);
    formData.append("company_id", getCompany().company_id);
    Swal.fire({
        customClass: 'swal-wide',
        title: 'Are you sure?',
        text: "You want to renew this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2f9bff',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Yes, renew it!'
    }).then((result) => {
        if (result.isConfirmed) {
            RenewSubscriptionEmailSend(formData).then(data => {
                if (data.status === 200) {
                    Swal.fire({
                        customClass: 'swal-wide',
                        position: 'absolute',
                        icon: 'success',
                        title: 'Email send successfully to administrator for renew subscription',
                        showConfirmButton: false,
                        timer: 2000,
                        toast: true,
                        position: 'top-right',
                    })
                }
            }).catch(error => {
                console.log("error===>")
            })
        }
    })
}

const sendEmailUpgradeSubscription = (data) => {
    const formData = new FormData()
    formData.append("user_id", getUser().id);
    formData.append("subscription_id", data?.id);
    formData.append("company_id", getCompany().company_id);
    Swal.fire({
        customClass: 'swal-wide',
        title: 'Are you sure?',
        text: "You want to upgrade this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2f9bff',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Yes, upgrade it!'
    }).then((result) => {
        if (result.isConfirmed) {
            UpgradeSubscriptionEmailSend(formData).then(data => {
                if (data.status === 200) {
                    Swal.fire({
                        customClass: 'swal-wide',
                        position: 'absolute',
                        icon: 'success',
                        title: 'Email send successfully to administrator for upgrade subscription',
                        showConfirmButton: false,
                        timer: 2000,
                        toast: true,
                        position: 'top-right',
                    })
                }
            }).catch(error => {
                console.log("error===>")
            })
        }
    })
}

    return (
        <>
            <div className="animation_fade">
                <div className="page-header d-flex align-items-center">
                    <div className="page-header-left">
                        <h4>Subscription</h4>
                    </div>
                    <div className='page-header-right ms-auto'>
                        <span className='d-block' style={{ height: "42px" }}></span>
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20'>
                        <div className='row plan-row mb-5'>
                            <div className='col-md-6'>
                                <div className='plan-box active'>
                                    <div className='badges-pill'>
                                        {currentSubscription.status ? 'Active' : 'In-active'}
                                    </div>
                                    <div className='plan-head'>
                                        <div className='plan-left'>
                                            <h4>{currentSubscription?.name || "N/A"}</h4>
                                            <div className='plan-info'>
                                                <span>Purchase Date: 10-10-2023</span>
                                                <span>Expiry Date: 10-10-2024</span>
                                                <span>
                                                    {currentSubscription?.month || "N/A"}
                                                    {currentSubscription?.month > 1 ? ' months' : " month"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='plan-price'>
                                            <span>{currentSubscription?.amount || "N/A"} SAR</span>
                                        </div>
                                    </div>
                                    <div className='plan-body'>
                                        <ul className='plan-details'>
                                            <li>{stripHtml(currentSubscription?.access_and_benefits || "N/A")}</li>
                                        </ul>
                                    </div>
                                    <div className='plan-footer d-flex gap-3'>
                                        <button className='btn btn-primary' onClick={() => sendEmailRenewSubscription()}>Request to Renew</button>
                                        <button className='btn btn-primary' onClick={() => sendEmail()}>Request to Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row plan-row'>
                            {showDefault?.length > 1 ?
                            <h4 className='heading-text'>Other Plans</h4>
                            :null}
                            {showDefault.length > 0 && showDefault.map((value) => (
                                value.id !== currentSubscription?.id && (
                                    <div className='col-md-6' key={value.id}>
                                        <div className='plan-box'>
                                            <div className='plan-head'>
                                                <div className='plan-left'>
                                                    <h4>{value?.name}</h4>
                                                    <div className='plan-info'>
                                                        <span>Purchase Date: 10-10-2023</span>
                                                        <span>Expiry Date: 10-10-2024</span>
                                                        <span>{value?.month} months</span>
                                                    </div>
                                                </div>
                                                <div className='plan-price'>
                                                    <span>{value?.amount} SAR</span>
                                                </div>
                                            </div>
                                            <div className='plan-body'>
                                                <ul className='plan-details'>
                                                    <li>
                                                        {renderContent(stripHtml(value.access_and_benefits || "N/A"), expandedPlans[value.id])}
                                                        {stripHtml(value.access_and_benefits).split(' ').length > SUBSCRIPTION_CONTENT_LENGTH && (
                                                            <button
                                                                className='btn-link p-0 ms-2'
                                                                onClick={() => toggleReadMore(value.id)}
                                                            >
                                                                {expandedPlans[value.id] ? 'Show Less' : 'Read More...'}
                                                            </button>
                                                        )}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='plan-footer d-flex gap-3'>
                                                <button className='btn btn-primary' onClick={() => sendEmailUpgradeSubscription(value)}>Request to Upgrade</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListSubscription;
