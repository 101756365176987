import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import { GetModulesAndPermissions, CreateRole } from '../../../services/company_panel/platform_user/platformUserService';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import Breadcrums from '../../../components/company_panel/common/breadcrumbs';
import { getCompany } from '../../../helpers/commonFunctions';
import * as yup from 'yup';
import { ToastContainer } from 'react-toastify';

const RoleAdd = () => {

  const breadcrumbs = [
    { title: "Platform User", url: "/company/business/platform-User/outlet-user/list/1" },
    { title: "Roles", url: "/company/business/platform-User/role/list/1" },
    { title: "Create role", url: "" },
  ];

  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [moduleList, setModuleList] = useState([]);

  const [role, setRole] = useState({});
  const [cashierModuleList, setCashierModuleList] = useState([]);
  const [cashierRole, setCashierRole] = useState({});

  const [checked, setChecked] = useState([false, false, false, false, false, false, false, false]);
  useEffect(() => {
    GetModulesAndPermissions().then(data => {
      const moduleAllPermissionsData = data?.data?.response?.moduleAllPermissions ? data?.data?.response?.moduleAllPermissions : [];
      let storeManagerModules={};
      let cashierPanelModules={};
      moduleAllPermissionsData.forEach((moduleAllPermission)=>{
        if(moduleAllPermission.module_type == 'store_manager'){
          if(!storeManagerModules[moduleAllPermission.group_name]){
            storeManagerModules[moduleAllPermission.group_name]=[
              {
                module_name: moduleAllPermission.module_name,
                module_id: moduleAllPermission.module_id,
                permissions : moduleAllPermission.permissions,
                data: moduleAllPermission.permissions.reduce((acc, permission) => ({ ...acc, [permission.permission_id]: false }), {}),
              }
            ]
          }else{
            let existingModules = [...storeManagerModules[moduleAllPermission.group_name]];
            existingModules.push({
              module_name: moduleAllPermission.module_name,
              module_id: moduleAllPermission.module_id,
              permissions : moduleAllPermission.permissions,
              data: moduleAllPermission.permissions.reduce((acc, permission) => ({ ...acc, [permission.permission_id]: false }), {}),
            })
            storeManagerModules[moduleAllPermission.group_name]= [...existingModules];
          }
        }else{
          if(!cashierPanelModules[moduleAllPermission.group_name]){
            cashierPanelModules[moduleAllPermission.group_name]=[
              {
                module_name: moduleAllPermission.module_name,
                module_id: moduleAllPermission.module_id,
                permissions : moduleAllPermission.permissions,
                data: moduleAllPermission.permissions.reduce((acc, permission) => ({ ...acc, [permission.permission_id]: false }), {}),
              }
            ]
          }else{
            let existingModules = [...cashierPanelModules[moduleAllPermission.group_name]];
            existingModules.push({
              module_name: moduleAllPermission.module_name,
              module_id: moduleAllPermission.module_id,
              permissions : moduleAllPermission.permissions,
              data: moduleAllPermission.permissions.reduce((acc, permission) => ({ ...acc, [permission.permission_id]: false }), {}),
            })
            cashierPanelModules[moduleAllPermission.group_name]= [...existingModules];
          }
        }
      })
      setRole(storeManagerModules);
      setCashierRole(cashierPanelModules);
      console.log(storeManagerModules);
      console.log(cashierPanelModules);
    }).catch(error => {
      console.log("error=====>", error)
    });
  }, []);

  const selectAllModulesAndPermissions = (key, outletStorePermission, checkedIndex) => {
    if (outletStorePermission) {
      const roleAll = moduleList.map((item, index) => {
        return { module_name: item.name, module_id: item.id, data: { ...role[index].data, [key]: !checked[checkedIndex] } };
      });
      setRole(roleAll);
    } else {
      const cashierRoles = cashierModuleList.map((item, index) => {
        return { module_name: item.name, module_id: item.id, data: { ...cashierRole[index].data, [key]: !checked[checkedIndex] } };
      });
      setCashierRole(cashierRoles);
    }

    let tempChecked = checked;
    tempChecked[checkedIndex] = !tempChecked[checkedIndex];
    setChecked(tempChecked);
  }

  const handleRole = (keyId, newValue, group_name, i) => {
    // if (!newValue) {
    //   let tempChecked = checked;
    //   tempChecked[key] = false;
    //   setChecked(tempChecked);
    // }
    console.log('--------groupname',group_name);
    let group = role[group_name];
    let module = group[i];
    module.data ={
      ...module.data, 
      [keyId]: newValue
    }
    group[i] = module;
    role[group_name] = group;
    setRole(role); 
    // setRole(prevRole =>
    //   prevRole.map(module =>
    //     module.module_id === moduleId
    //       ? { ...module, data: { ...module.data, [keyId]: newValue } }
    //       : module
    //   )
    // );
    formik.setFieldValue('role', 'updated'); // Trigger validation
  };

  const handleCashierRole = (keyId, newValue, group_name, i) => {
    // if (!newValue) {
    //   let tempChecked = checked;
    //   tempChecked[4 + key] = false;
    //   setChecked(tempChecked);
    // }
    // setCashierRole(prevRole =>
    //   prevRole.map(module =>
    //     module.module_id === moduleId
    //       ? { ...module, data: { ...module.data, [keyId]: newValue } }
    //       : module
    //   )
    // );
    let group = cashierRole[group_name];
    let module = group[i];
    module.data ={
      ...module.data, 
      [keyId]: newValue
    }
    group[i] = module;
    cashierRole[group_name] = group;
    setCashierRole(cashierRole); 
    formik.setFieldValue('role', 'updated'); // Trigger validation
  };

  const validationSchema = yup.object().shape({
    name: yup.string().trim().required('Name is required').max(40, 'Name must be 40 characters or less'),
    name_ar: yup.string().trim().required('Name (Arabic) is required').max(40, 'Name (Arabic) must be 40 characters or less'),
    role: yup.mixed().test('role-check', 'Please select at least one permission in either Outlet Store User Permission or Cashier Permissions.', function () {
      const isRoleSelected = Object.entries(role).some(([group_name, modules])=>{
        return modules?.some(module =>{
          return Object.values(module.data).includes(true);
        })
      })
      // const isRoleSelected = role.some(module => Object.values(module.data).includes(true));
      // const isCashierRoleSelected = cashierRole.some(module => Object.values(module.data).includes(true));
      const isCashierRoleSelected = Object.entries(cashierRole).some(([group_name, modules])=>{
        return modules.some(module =>{
          return Object.values(module.data).includes(true);
        })
      })
      return isRoleSelected || isCashierRoleSelected;
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      name_ar: "",
      role: "",
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      setSubmitted(true);
      let formData = new FormData();
      formData.append('name', values.name.trim());
      formData.append('name_ar', values.name_ar.trim());
      formData.append('company_id', getCompany().company_id);
      let roleArr=[];
      Object.entries(role).forEach(([group, modules])=>{
        modules.forEach(module=>{
          roleArr.push(module);
        })
      })

      let cashierRoleArr=[];
      Object.entries(cashierRole).forEach(([group, modules])=>{
        modules.forEach(module=>{
          cashierRoleArr.push(module);
        })
      })
      formData.append('rolePermissions', JSON.stringify(roleArr));
      formData.append('cashierRolePermissions', JSON.stringify(cashierRoleArr));

      CreateRole(formData).then(response => {
        if (response.data.status === 1 || response.data.status === "1") {
          Swal.fire({
            customClass: 'swal-wide',
            icon: 'success',
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
            toast: true,
            position: 'top-right',
          });
          setTimeout(() => {
            navigate(`/company/business/platform-User/role/list/${1}`);
          }, 1000);
        } else {
          Swal.fire({
            customClass: 'swal-wide',
            icon: 'error',
            title: response.data.message,
            showConfirmButton: false,
            timer: 5000,
            toast: true,
            position: 'top-right',
          });
          setSubmitted(false);
        }
      }).catch(error => {
        console.log("error ====> ", error);
      });
    }
  });
  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <form onSubmit={formik.handleSubmit}>
        <div className="row row-sm">
          <div className="col-lg-12 col-md-12 animation_fade">
            <div className="card custom-card">
              <div className="card-body">
                <div>
                  <h2 className="main-content-label mb-3">Create Role</h2>
                </div>
                <div className="row row-sm">
                  <div className='col-lg-6 text-center form-group mt-3'>
                    <label htmlFor='name' className='text-left d-flex'>Name<span className="requirestar">*</span></label>
                    <input name='name' id="name" type='text' onChange={formik.handleChange} placeholder='Enter name' onBlur={formik.handleBlur} value={formik.values.name} className='form-control' />
                    <span className='text-danger d-flex text-left'>{formik.errors.name && formik.touched.name && formik.errors.name}</span>
                  </div>
                  <div className='col-lg-6 text-center form-group mt-3'>
                    <label htmlFor='name_ar' className='text-left d-flex'>Name (Arabic)<span className="requirestar">*</span></label>
                    <input name='name_ar' id="name_ar" type='text' placeholder='Enter name (Arabic)' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name_ar} className='form-control' />
                    <span className='text-danger d-flex text-left'>{formik.errors.name_ar && formik.touched.name_ar && formik.errors.name_ar}</span>
                  </div>
                  <div className='col-lg-6 text-center form-group mt-3'>
                    <label htmlFor='role' className='text-left d-flex'><b>Outlet Store User Permissions</b><span className="requirestar">*</span></label>
                    <div className='user_permission mt-3'>

                      <div className='row mb-3'>
                        <div className='col-5'>
                          <p><b>Select all</b></p>
                        </div>
                        <div className='col-7'>
                          {/* <ul className='row'>
                            <li>
                              <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(1, true, 0) }} checked={checked[0] === true ? true : false} />
                            </li>
                            <li>
                              <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(2, true, 1) }} checked={checked[1] === true ? true : false} />
                            </li>
                            <li>
                              <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(3, true, 2) }} checked={checked[2] === true ? true : false} />
                            </li>
                            <li>
                              <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(4, true, 3) }} checked={checked[3] === true ? true : false} />
                            </li>
                          </ul> */}


                          {/* <ul className='row'>
                              {permissionsList.length ? permissionsList.map((value, key) => (
                                <li key={key}>
                                  <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(value?.id, true, value?.id - 1) }} checked={checked[value?.id - 1] === true ? true : false} />
                                  <label className="form-check-label">{value.action}</label>
                                </li>
                              )) : ''}
                            </ul> */}
                        </div>
                      </div>

                      {Object.entries(role).length ? Object.entries(role).map(([group_name, modules]) => {
                        return <div>
                          <h2 className='mt-1'>{group_name}</h2>
                          {
                              modules.map((row, i)=>{
                                return <>
                                <div className='row'>
                                <div className='col-5'>
                                  <p>{row.module_name}</p>
                                </div>
                                <div className='col-7'>
                                  <ul className='row'>
                                    {row.permissions.length ? row.permissions.map((value, key) => (
                                      <li key={key}>
                                        <input className="form-check-input" type="checkbox" name="shift" id={value.permission_name}
                                          onChange={e => handleRole(value.permission_id, e.target.checked, group_name, i)}
                                          checked={
                                            role[group_name][i]?.data[value.permission_id] === true ? true : false
                                          }
                                        />
                                        <label className="form-check-label">{value.permission_name}</label>
                                      </li>
                                      )) : ''}
                                    </ul>
                                  </div>
                                </div>
                              </>
                              })  
                          }
                        </div>  
                      }) : ''}

                    </div>
                  </div>
                  <div className='col-lg-6 text-center form-group mt-3'>
                    <label htmlFor='cashierRole' className='text-left d-flex'><b>Cashier Permissions</b><span className="requirestar">*</span></label>
                    <div className='user_permission mt-3'>

                       <div className='row mb-3'>
                        <div className='col-5'>
                          <p><b>Select all</b></p>
                        </div>
                        <div className='col-7'>
                          {/* <ul className='row'>
                              {permissionsList.length ? permissionsList.map((value, key) => (
                                <li key={key}>
                                  <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(value?.id, false, (permissionsList?.length + value?.id) - 1) }} checked={checked[permissionsList?.length + value?.id - 1] === true ? true : false} />
                                  <label className="form-check-label">{value.action}</label>
                                </li>
                              )) : ''}
                            </ul> */}
                        </div>
                      </div>

                      {/* {cashierRole.length ? cashierRole.map((row, i) => (
                        <div className='row' key={i}>
                          <div className='col-5'>
                            <p><b>{row.module_name}</b></p>
                          </div>
                          <div className='col-7'>
                            <ul className='row'>
                              {row.permissions.length ? row.permissions.map((value, key) => (
                                <li key={key}>
                                  <input className="form-check-input" type="checkbox" name="shift" id={value.permission_name}
                                    onChange={e => handleCashierRole(row.module_id, value.permission_id, e.target.checked, key)}
                                    checked={
                                      cashierRole[i]?.data[value.permission_id] === true ? true : false
                                    }
                                  />
                                  <label className="form-check-label">{value.permission_name}</label>
                                </li>
                              )) : ''}
                            </ul>
                          </div>
                        </div>
                      )) : ''} */}

                      {Object.entries(cashierRole).length ? Object.entries(cashierRole).map(([group_name, modules]) => {
                        return <div>
                          <h2 className='mt-1'>{group_name}</h2>
                          {
                              modules.map((row, i)=>{
                                return <>
                                <div className='row'>
                                <div className='col-5'>
                                  <p>{row.module_name}</p>
                                </div>
                                <div className='col-7'>
                                  <ul className='row'>
                                    {row.permissions.length ? row.permissions.map((value, key) => (
                                      <li key={key}>
                                        <input className="form-check-input" type="checkbox" name="shift" id={value.permission_name}
                                          onChange={e => handleCashierRole(value.permission_id, e.target.checked, group_name, i)}
                                          checked={
                                            cashierRole[group_name][i]?.data[value.permission_id] === true ? true : false
                                          }
                                        />
                                        <label className="form-check-label">{value.permission_name}</label>
                                      </li>
                                      )) : ''}
                                    </ul>
                                  </div>
                                </div>
                              </>
                              })  
                          }
                        </div>  
                      }) : ''}
                    </div>
                  </div>
                  {formik.errors.cashierRole && formik.touched.cashierRole && (
                    <div className='text-danger text-left'>{formik.errors.cashierRole}</div>
                  )}
                  {formik.errors.role && formik.touched.role && (
                    <div className='text-danger text-left mb-2'>{formik.errors.role}</div>
                  )}
                </div>

                <div className='form-group text-start mt-2'>
                  <button type="submit" className="btn btn-primary">Add</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </form>
    </>
  );
};

export default RoleAdd;
