import React, { useEffect, useMemo, useState } from 'react';
import Breadcrums from '../../../components/company_panel/common/breadcrumbs';
import downArrowImg from '../../../assets/company/img/downArrow.png'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { AddStationFlow, DeleteStationFlow, DetailsStation, FlowList, GetStationFlowList } from '../../../services/company_panel/routingStationService';
import { getBrandId, getCompany } from '../../../helpers/commonFunctions';
import { useNavigate, useParams } from 'react-router-dom';
import { globalLoader } from '../../../helpers/global';
import { LOADER_TIME } from '../../../utils/Constants';
import {IconTrashX} from '@tabler/icons-react';


const StationFlow = () => {
    const params = useParams();
    const navigate = useNavigate();
    const breadcrumbs = [
        {
            title: "Routing Station",
            url: "/company/menu/routing-station/groups/list/1"
        },
        {
            title: "Stations",
            url: "/company/menu/routing-station/stations/list/1"
        },
        {
            title: "Station Flow",
            url: ""
        },
    ]

    const [showDefault, setShowDefault] = useState({});
    const [stationsList, setStationsList] = useState({});
    const [stations, setStations] = useState([]);
    const [listStations, setListStations] = useState([]);
    const [selectedStationIds, setSelectedStationIds] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [newStation, setNewStation] = useState('');
    const [loader, setLoader] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [currentBrand] = useState(getBrandId());

    useMemo(()=>{
		document.addEventListener('brand-changed', function(){
			setIsBrandChanged(!isBrandChanged);
		});
        setTimeout(() => {
            if (currentBrand != getBrandId()) {
                navigate(`/company/menu/routing-station/stations/list/${1}`);
            }
        }, 100);
	});

    useEffect(() => {
        DetailsStation(params.id).then(data => {
            setShowDefault(data && data?.data && data?.data?.response ? data?.data?.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, []);


    const getStationFlow = () => {
        GetStationFlowList(params.id).then((response) => {
            // let stationsName = response.data.response;
            setStationsList(response && response.data && response.data.response ? response?.data?.response : [])
            // stationsName = stationsName.map(item => item.name)
            // setStations(stationsName)
            // let stationsIds = response.data.response.map(item => item.id)
            // setSelectedStationIds(stationsIds)
            // setListStations(response && response.data && response.data.response ? response?.data?.response : [])
        })
            .catch((error) => {
                console.log("error===>", error)
            })
    }


    function GetStationList() {
        const listFlowData = new FormData();
        listFlowData.append("brand_id", getBrandId());
        listFlowData.append("id", params.id)
        FlowList(listFlowData)
            .then((response) => {
                setListStations(response && response.data && response.data.response ? response?.data?.response : [])
            })
            .catch((error) => {
                console.log("error===>", error)
            })
    }


    useEffect(() => {
        getStationFlow();
        GetStationList();
    }, [isBrandChanged]);

    // const handleShowModal = () => setShowModal(true);
    // const handleCloseModal = () => setShowModal(false);


    const handleAddStation = (e) => {
        // if (newStation.trim()) {
        // setStations([newStation, ...stations]);
        // setNewStation('');
        // const newSelectedStationId = listStations.filter(item => item.name == newStation);
        // setSelectedStationIds([...selectedStationIds, newSelectedStationId[0].id]);
        // handleCloseModal();
        // let Id = [newSelectedStationId[0].id]
        setLoader(true);

        AddStationFlow({ stationFlows: [newStation], brand_id: getBrandId(), company_id: getCompany().id, station_id: params.id })
            .then((response) => {
                setLoader(false);
                if (response.data.status === 1 || response.data.status === "1") {
                    Swal.fire({
                        customClass: 'swal-wide',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 1500,
                        toast: true,
                        position: 'top-right',
                    });
                    setNewStation(null);
                    getStationFlow();
                    GetStationList();
                    document.getElementById('closeAddCardModal').click();
                } else {
                    Swal.fire({
                        customClass: 'swal-wide',
                        icon: 'error',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 5000,
                        toast: true,
                        position: 'top-right',
                    })
                }

            })
            .catch((error) => {
                setLoader(false);
                console.log("error===>", error)
            })
        // }
    };

    const handelDeleteStation = (station, index) => {
        // const stationData = listStations.filter(item => item.name === station)[0];

        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setDeleteLoader(true);
                const deleteForm = new FormData();
                deleteForm.append("station_id", params.id);
                deleteForm.append("id", station);
                DeleteStationFlow(deleteForm)
                    .then((response) => {
                        setDeleteLoader(false);
                        if (response.data.status === 1 || response.data.status === "1") {
                            getStationFlow();
                            GetStationList();
                            Swal.fire({
                                customClass: 'swal-wide',
                                icon: 'success',
                                title: response.data.message,
                                showConfirmButton: false,
                                timer: 1500,
                                toast: true,
                                position: 'top-right',
                            });
                        } else {
                            Swal.fire({
                                customClass: 'swal-wide',
                                icon: 'error',
                                title: response.data.message,
                                showConfirmButton: false,
                                timer: 5000,
                                toast: true,
                                position: 'top-right',
                            })
                        }

                    })
                    .catch((error) => {
                        setDeleteLoader(false);
                        console.log("error===>", error)
                    })
            }
        }).catch((error) => {
            console.log("Error==>", error);

        })



        // const newStations = stations.filter((_, i) => i !== index);
        // setStations(newStations);
    };

    // function handelSubmitStationFlow() {

    //     AddStationFlow({ stationFlows: selectedStationIds, brand_id: getBrandId(), company_id: getBrandId(), station_id: params.id })
    //         .then((response) => {
    //             if (response.data.status === 1 || response.data.status === "1") {
    //                 Swal.fire({
    //                     customClass: 'swal-wide',
    //                     icon: 'success',
    //                     title: response.data.message,
    //                     showConfirmButton: false,
    //                     timer: 1500,
    //                     toast: true,
    //                     position: 'top-right',
    //                 });
    //                 setTimeout(() => {
    //                     navigate(`/company/menu/routing-station/stations/list/1`)
    //                 }, 1000);
    //             } else {
    //                 Swal.fire({
    //                     customClass: 'swal-wide',
    //                     icon: 'error',
    //                     title: response.data.message,
    //                     showConfirmButton: false,
    //                     timer: 5000,
    //                     toast: true,
    //                     position: 'top-right',
    //                 })
    //             }

    //         })
    //         .catch((error) => {
    //             console.log("error===>", error)
    //         })
    // }

    console.log("newStation---->", newStation);
    


    return (
        <>
            <div className="page-header d-flex align-items-center">
                <div className="page-header-left">
                    <h4>Routing Station</h4>
                    <Breadcrums data={breadcrumbs} />
                </div>
                <div className="page-header-right ms-auto">
                </div>
            </div>
            <div className="card custom-card">
                <div className="card-body d-flex justify-content-center">
                    <div className="card custom-card w-50">
                        <div className="card-body">
                            <h2 className='text-center'>{showDefault?.name}</h2>
                            {stationsList && stationsList.length > 0 && stationsList.map((station, index) => (
                                <div key={station?.id}>
                                    <div className="d-flex align-items-center p-1 rounded station-tab">
                                        <div className="me-3">{index + 1}</div>
                                        <div className="flex-grow-1 text-center">{station?.name}</div>
                                        {/* <button className="m-2 p-2 station-tab-btn" data-bs-toggle="modal" data-bs-target="#addCardModal" href='#addCardModal'>
                                            <IconEdit stroke={2} />
                                        </button> */}
                                        {index === stationsList.length - 1 && (
                                            // <button className="m-2 p-2 station-tab-btn" onClick={() => handelDeleteStation(station?.id, index)} ><IconTrashX stroke={2} /></button>
                                            <button className={deleteLoader ? `loading-button m-2 p-2 station-tab-btn` : `m-2 p-2 station-tab-btn`} onClick={() => handelDeleteStation(station?.id, index)} >{deleteLoader ? <i class="fa fa-spinner fa-spin me-2"></i> : null}<IconTrashX stroke={2} /></button>
                                        )}
                                    </div>
                                    <div className='downArrow'><img src={downArrowImg} /></div>
                                </div>
                            ))}
                            <div className="d-flex justify-content-center align-items-center mb-3 p-3 border rounded station-tab-btn cp" data-bs-toggle="modal" data-bs-target="#addCardModal" href='#addCardModal'>Add Next Station</div>
                        </div>
                    </div>

                    {/* <div className="modal d-block" tabIndex="-1"> */}
                    <div className="modal fade " id="addCardModal" aria-labelledby="addCardModalLabel" aria-hidden="true" data-bs-backdrop="static">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h2 className="modal-title">Next Station</h2>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeAddCardModal" onClick={()=> setNewStation('')}></button>
                                </div>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <select
                                            className="form-control cp"
                                            name="newStation"
                                            value={newStation}
                                            onChange={(e) => setNewStation(e.target.value)}
                                        >
                                            <option value="">Select Station</option>
                                            {listStations && listStations.length > 0 && listStations.map((station) => (
                                                <option key={station?.id} value={station?.id}>
                                                    {station.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button type="button" className="btn btn-outline-primary" style={{ width: '48%', marginRight: '10px' }}  onClick={()=> setNewStation('')} data-bs-dismiss="modal" aria-label='Close'>Cancel</button>
                                    <button type="submit" disabled={!newStation} className={loader ? `loading-button btn btn-primary` : ' btn btn-primary'} style={{ width: '48%' }} onClick={handleAddStation}>{loader ? <i class="fa fa-spinner fa-spin me-2"></i> : null}Add</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="button-container mb-4 pl-1 d-flex justify-content-center">
                    {/* <button className="btn btn-primary ms-2 btn-submit" type="submit" onClick={handelSubmitStationFlow}>Submit</button> */}
                    <button className="btn btn-secondary ms-2 btn-submit" type="submit" onClick={() => navigate(-1)}>Back</button>
                </div>

            </div>
        </>
    );
};

export default StationFlow;
