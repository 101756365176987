import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import Sorting from '../../../components/common/sorting'
import Loader from '../../../components/company_panel/common/loader'
import { capitalizeAllFirstLetters, capitalizeFirstLetter, formateDate, getBrandId, TrimText } from '../../../helpers/commonFunctions'
import CustomPagination from '../../../components/company_panel/common/custompagination'
import notFound from '../../../assets/company/img/no-record-found.png'
import Breadcrums from '../../../components/company_panel/common/breadcrumbs'
import { CreateInventoryEndOfDaysCount, GetInventoryEndOfDaysList } from '../../../services/company_panel/inventoryService'
import { LOADER_TIME } from '../../../utils/Constants'
import Swal from 'sweetalert2'
import { DetailsLoyaltyPoint } from '../../../services/company_panel/loyaltypoints.service'
import logo from '../../../../src/assets/company/img/logo.png';


const BiWeeklyList = () => {

    const breadcrumbs = [
        {
            title: "Inventory",
            url: "/company/raw_materials/inventory/list/1"
        },
        {
            title: "End Of Day",
            url: ""
        },
    ]
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false)

    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [physicalCounts,setPhysicalCounts] = useState({});

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
    });




    useEffect(() => {
        setLoader(true)
        const formData = new FormData()
        formData.append("page", params.id)
        formData.append("per_page", itemPerPage)
        formData.append('brand_id', getBrandId());
        formData.append('duration', 'bi_weekly');
        GetInventoryEndOfDaysList(formData).then(data => {
            setDataLength(data.data.response.count);
            setList(data && data.data && data.data.response ? data.data.response.rows : [])
            setTimeout(() => {
                setLoader(false);
            }, LOADER_TIME);
        }).catch(error => {
            setTimeout(() => {
                setLoader(false);
            }, LOADER_TIME);
            console.log("error ====> ", error);
        })
}, [isBrandChanged,location])

const handleInputChange = (inventoryId) => (event) => {
    setPhysicalCounts((prev) => ({
        ...prev,
        [inventoryId]: event.target.value,
    }));
};
const handleSubmit = () => {
    const formData = new FormData();
    formData.append('brand_id', getBrandId());
    formData.append('duration', 'bi_weekly');
    const dataArray = [];
    for (const inventoryId in physicalCounts) {
        if (physicalCounts[inventoryId]) {
            dataArray.push({
                inventory_id: inventoryId,
                physical_count: physicalCounts[inventoryId]
            });
        }
    }
    formData.append('data', JSON.stringify(dataArray));
    CreateInventoryEndOfDaysCount(formData).then(response => {
        if (response.data.status === 1 || response.data.status === "1") {
            Swal.fire({
                customClass: 'swal-wide',
                icon: 'success',
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                position: 'top-right',
            });
        } else {
            Swal.fire({
                customClass: 'swal-wide',
                icon: 'error',
                title: response.data.message,
                showConfirmButton: false,
                timer: 5000,
                toast: true,
                position: 'top-right',
            });
        }
    }).catch(error => {
        console.log("error ====> ", error);
    });
};

const handlePrint = () => {
    const formData = new FormData()
    formData.append("page", 1)
    formData.append("per_page", dataLength)
    formData.append('brand_id', getBrandId());
    formData.append('duration', 'bi_weekly');
    GetInventoryEndOfDaysList(formData).then(data => {
        setDataLength(data.data.response.count);
        const listData = data && data.data && data.data.response ? data.data.response.rows : [];
        const dateTime = new Date().toLocaleString();
        const printContent = `
         <div style="text-align: center;">
               <img src=${logo} alt="Logo" style="max-width: 200px; height: auto;"/>
                </div>
               <div style="text-align: right; margin-bottom: 20px;">
                   ${dateTime}
                 </div>
            <table className="table table-custom">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Category</th>
                        <th>Unit Per Measure</th>
                        <th>Physical Count</th>
                    </tr>
                </thead>
                <tbody>
                    ${listData.length ? listData.map((row, i) => `
                        <tr key="${i}">
                            <td>${capitalizeFirstLetter(TrimText(row.item_name, 30))}</td>
                            <td>${capitalizeFirstLetter(row.category.name)}</td>
                            <td>${capitalizeFirstLetter(row.inventory_unit_converisons.find(unit => unit.unit_type === "Count Unit")?.uomDetails.uom_code) || "N/A"}</td>
                            <td><input type="text" class="form-control" autoComplete='off' /></td>
                        </tr>
                    `).join('') : `
                        <tr>
                            <td colspan="4" class="text-center">No Record</td>
                        </tr>
                    `}
                </tbody>
            </table>
        `;

        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.width = '0';
        iframe.style.height = '0';
        iframe.style.border = 'none';
        document.body.appendChild(iframe);

        const doc = iframe.contentWindow.document;
        doc.open();
        doc.write(`
            <html>
                <head>
                    <title></title>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            margin: 20px;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        th, td {
                            border: 1px solid #000;
                            padding: 8px;
                            text-align: left;
                        }
                        th {
                            background-color: #f2f2f2;
                        }
                        tr:nth-child(even) {
                            background-color: #f9f9f9;
                        }
                                @media print {
    @page {
        margin: 0; /* Remove default margins */
    }
    body {
        -webkit-print-color-adjust: exact; /* Ensure color is preserved */
    }
    header, footer, nav {
        display: none; /* Hide headers, footers, and navigation */
    }
}
                    </style>
                </head>
                <body>
                    ${printContent}
                </body>
            </html>
        `);
        doc.close();

        iframe.contentWindow.focus();
        iframe.contentWindow.print();
        document.body.removeChild(iframe);
    }).catch(error => {
        console.log("Error fetching data for print:", error);
    });
};

const isSubmitDisabled = () => {
    return Object.values(physicalCounts).every(count => {
        const numCount = parseFloat(count);
        return count === '' || 
               isNaN(numCount) || 
               numCount < 0 || 
               (numCount === 0 && count.includes('-')) ||
               count.length > 8;
    });
};

    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Inventory</h4>
                        <Breadcrums data={breadcrumbs} />
                    </div>
                    <div className='page-header-right ms-auto'>
                    <button className="btn btn-primary ms-2" onClick={handlePrint} disabled={dataLength === 0} >Print Count Template</button>
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                    <div className="date-display mb-2 d-flex justify-content-right ms-3">
                            <div className="d-flex flex-column align-items-start me-4">
                                <span>Date Of Business</span>
                                <span className="date-value fw-bold">{formateDate(new Date())}</span>
                            </div>
                            <div className="d-flex flex-column align-items-start me-4 ms-3">
                                <span>Date Of System</span>
                                <span className="date-value fw-bold">{formateDate(new Date())}</span>
                            </div>
                            <div className="d-flex flex-column align-items-start ms-3">
                                <span>Date Of Inventory</span>
                                <span className="date-value fw-bold">{formateDate(new Date())}</span>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center mt-4'>
                            <ul className="d-flex setting w-100 mb-0 p-0">
                                <li className="ms-3"><Link to={`/company/raw_materials/inventory/end_of_day/daily/list/${1}`} className="btn">Daily</Link></li>
                                <li className="ms-3"><Link to={`/company/raw_materials/inventory/end_of_day/weekly/list/${1}`} className="btn">Weekly</Link></li>
                                <li className="ms-3 active"><Link to={`/company/raw_materials/inventory/end_of_day/bi_weekly/list/${1}`} className="btn btn-primary">Bi Weekly</Link></li>
                                <li className="ms-3"><Link to={`/company/raw_materials/inventory/end_of_day/monthly/list/${1}`} className="btn">Monthly</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='gap-20'>
                    </div>
                    <div className="table-wrap">
                        <div className="table-responsive">
                        <div id="printableArea">
                                <table className="table table-custom">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Category</th>
                                            <th>Unit Per Measure</th>
                                            <th>Physical Count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loader ? (
                                            <tr>
                                                <td colSpan={4}><Loader /></td>
                                            </tr>
                                        ) : (
                                            list.length ? list.map((row, i) => (
                                                <tr key={i}>
                                                    <td>{capitalizeFirstLetter(TrimText(row.item_name, 30))}</td>
                                                    <td>{capitalizeFirstLetter(row.category.name)}</td>
                                                    <td>{capitalizeFirstLetter(row.inventory_unit_converisons.find(unit => unit.unit_type === "Count Unit")?.uomDetails.uom_code) || "N/A"}
                                                    </td>
                                                    <td>
                                                        <input type="text" className="form-control"
                                                            placeholder='Please Enter'
                                                            autoComplete='off'
                                                            onChange={handleInputChange(row.id)}
                                                        />
                                                    </td>
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td colSpan={4} className="text-center">
                                                        <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                        <p>No Record</p>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite">
                                    {/* <b>Total Records : {dataLength ? dataLength : "0"}</b> */}
                                    <div className="button-container">
                                        <button className="btn btn-primary ms-2 btn-submit" type="button" onClick={handleSubmit} disabled={isSubmitDisabled()}>Submit</button>
                                    </div>
                                </div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "inventory", path: "/company/raw_materials/inventory/end_of_day/bi_weekly/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BiWeeklyList