import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GetOutletDetail, DeleteHardwareDevice, GetBrandOutletsList } from '../../services/company_panel/outletService';
import { GetSingleOrderDetails, RefundStatus } from '../../services/company_panel/orderService';
import toHtml from 'html-react-parser'
import { capitalizeAllFirstLetters, formateDate, formateDateWithTime, getBrandId, getOutletId, showPrice, SpecialCharacter, TrimText } from '../../helpers/commonFunctions';
import Breadcrums from '../../components/company_panel/common/breadcrumbs'
import Loader from '../../components/company_panel/common/loader'
import Swal from 'sweetalert2'
import { Modal, Button, Form } from 'react-bootstrap';
import { AllRefundReasons } from '../../services/company_panel/refund.services';
import { countries } from 'countries-list';

const RefundOrderView = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [orderDetails, setOrderDetails] = useState({});
    const [outletList, setOutletList] = useState({});
    const breadcrumbs = [
        {
            title: "Orders ",
            url: "/company/transaction/order/list/1"
        },
        {
            title: "Order Detail",
            url: ""
        },
    ]
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [showRefundModal, setShowRefundModal] = useState(false);
    const [refundOrderId, setRefundOrderId] = useState(null);
    const [refundReason, setRefundReason] = useState('');
    const [refundReasonText, setRefundReasonText] = useState('');
    const [error, setError] = useState('');
    const [allRefundReasons, setAllRefundReasons] = useState([]);
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [isOutletChanged, setIsOutletChanged] = useState(false);
    const [currentOutlet, setCurrentOutlet] = useState(getOutletId());

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        });
        setTimeout(() => {
            if (currentOutlet != getOutletId()) {
                navigate(`/company/transaction/order/list/${1}`);
            }
        }, 500);

    });

    useEffect(() => {
        GetSingleOrderDetails({ id: params.id }).then(data => {
            setOrderDetails(data && data?.data && data?.data?.response ? data?.data?.response : [])
            setRefundOrderId(data?.data?.response?.id)
        }).catch(error => {
            console.log("error=====>", error)
        })
        GetBrandOutletsList({ brand_id: getBrandId() }).then(data => {
            setOutletList(data && data?.data && data?.data?.response ? data?.data?.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [statsUpdate]);

    useEffect(() => {
        AllRefundReasons({ brand_id: getBrandId() })
            .then((data) => {
                setAllRefundReasons(data && data?.data && data?.data?.response ? data?.data?.response : [])
            })
            .catch((error) => {
                console.log("errors===>", error)
            })
    }, [isBrandChanged]);

    console.log("refundOrderId--->", refundOrderId);

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    };

    const handleRefundSubmit = () => {
        if (refundReasonText.length > 250) {
            setError('Reason cannot exceed 250 characters');
            return;
        }
        // Implement your refund logic here
        const formData = new FormData()
        formData.append("id", refundOrderId)
        formData.append("refund_reason_id", refundReason);
        if (refundReason === 'other') {
            formData.append("refund_reason", refundReasonText);
        }
        formData.append("payment_status", "refunded");
        RefundStatus(formData).then(response => {
            setShowRefundModal(false);
            Swal.fire({
                customClass: 'swal-wide',
                icon: 'success',
                title: response.data.message,
                showConfirmButton: false,
                timer: 5000,
                toast: true,
                position: 'top-right',
            })
            setRefundReason('')
            setRefundReasonText('')
            setStatusUpdate(!statsUpdate)
        }).catch(error => {
            console.log("error ====> ", error);
        })

    };

    function OutletAddress() {
        const fullAddress = [
            orderDetails?.outlet?.location,
            orderDetails?.outlet?.outlet?.city_id?.name,
            orderDetails?.outlet?.state_id?.name,
            orderDetails?.outlet?.country_id?.name,
        ]?.filter(Boolean)?.join(' , ')

        return fullAddress;
    }

    // const menuItemData = orderDetails.customer_order_menu_item_relations
    // .map(data => data?.menu_item?.item_name)
    // .filter(Boolean)
    // .join(', ');

    const generateOrderId = (orderId) => {
        let id = orderId;
        let countOfDigits = 0;
        while (id) {
            id = Math.floor(id / 10);
            countOfDigits += 1;
        }

        if (countOfDigits >= 4) {
            return orderId
        }
        let zerosCount = 4 - countOfDigits;
        let generatedZeros = '';
        while (zerosCount) {
            generatedZeros += '0';
            zerosCount -= 1;
        }
        return `${generatedZeros}${orderId}`;
    }
    return (
        <>

            <div class="page-header d-flex align-items-center">
                <div class="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Orders</h4>
                </div>
                <div class="page-header-right ms-auto">

                </div>
            </div>
            <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div>
                                <h3 className="main-content-label mb-3">Orders Info</h3>
                            </div>
                            <div className='row'>
                                <div className="col-lg-12 form-group">
                                    <table id="simple-table" className="table table">
                                        <tbody>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Order ID</td>
                                                    <td>{orderDetails?.id ? `#order ${generateOrderId(orderDetails?.id)}` : "N/A"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Oder Mode</td>
                                                    <td>{orderDetails?.order_mode_configuration && orderDetails?.order_mode_configuration?.name ? capitalizeFirstLetter(orderDetails?.order_mode_configuration?.name) : "N/A"}</td>
                                                </div>
                                            </tr>
                                            {orderDetails?.aggregator_id &&
                                                <tr>
                                                    <div className='d-flex justify-content-between'>
                                                        <td>Aggregator</td>
                                                        <td>{orderDetails?.aggregators_configuration && orderDetails?.aggregators_configuration?.name ? capitalizeFirstLetter(orderDetails?.aggregators_configuration?.name) : "N/A"}</td>
                                                    </div>
                                                </tr>
                                            }
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Date & Time</td>
                                                    <td>{orderDetails && orderDetails.updated_at ? formateDateWithTime(orderDetails?.updated_at) : "N/A"}</td>
                                                </div>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <div>
                                        <h3 className="main-content-label mb-3">Outlet Details</h3>
                                    </div>
                                    <table id="simple-table" className="table table">
                                        <tbody>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Outlet name</td>
                                                    <td>
                                                        {/* {orderDetails?.outlet_id} */}
                                                        {capitalizeFirstLetter(orderDetails?.outlet?.outlet_name)}
                                                        {/* {outletList?.find((outlet) => outlet?.id === Number(orderDetails?.outlet_id))?.outlet_name} */}
                                                    </td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td className='w-25'>Outlet location</td>
                                                    <td>{capitalizeAllFirstLetters(OutletAddress())}</td>
                                                </div>
                                            </tr>

                                            {orderDetails?.service_type?.service_type &&
                                                <tr>
                                                    <div className='d-flex justify-content-between'>
                                                        <td>Service type</td>
                                                        <td>{orderDetails && orderDetails?.service_type && orderDetails?.service_type?.service_type === 'table_service' ? "Table service" : "Quick service"}</td>
                                                    </div>
                                                </tr>
                                            }

                                            {orderDetails?.service_type?.service_type === 'table_service' &&
                                                <>
                                                    <tr>
                                                        <div className='d-flex justify-content-between'>
                                                            <td>Table Name</td>
                                                            <td>{orderDetails && orderDetails?.service_type && orderDetails?.service_type.table_name ? capitalizeFirstLetter(orderDetails?.service_type?.table_name) : "N/A"}</td>
                                                        </div>
                                                    </tr>
                                                    <tr>
                                                        <div className='d-flex justify-content-between'>
                                                            <td>Table Number</td>
                                                            <td>{orderDetails && orderDetails?.service_type && orderDetails?.service_type?.table_number ? orderDetails?.service_type?.table_number : "N/A"}</td>
                                                        </div>
                                                    </tr>
                                                </>
                                            }

                                        </tbody>
                                    </table>
                                    <div className='row row-sm'>
                                        <div className='col-lg-6 col-md-6 animation_fade'>
                                            <div className="card custom-card">
                                                <div className="card-body">
                                                    <h3 className="main-content-label mb-3">Other Details</h3>
                                                    <table id="simple-table" className="table ">
                                                        <>
                                                            <tbody style={{ border: 'none' }}>
                                                                <tr>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <td>Customer name :</td>
                                                                        <td>{capitalizeAllFirstLetters(orderDetails?.customer_name)}</td>
                                                                    </div>
                                                                </tr>
                                                                {/* <tr>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <td>Customer name (Arabic) :</td>
                                                                        <td>{orderDetails?.customer_name_ar ? orderDetails?.customer_name_ar : 'N/A'}</td>
                                                                    </div>
                                                                </tr> */}
                                                                <tr>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <td>Customer Phone Number :</td>
                                                                        {/* <td>{orderDetails && orderDetails?.customer?.country_code ? `+${countries[orderDetails?.customer?.country_code].phone[0]} ` : null}{orderDetails?.customer?.customer_number}</td> */}
                                                                        <td>{orderDetails?.customer?.customer_number}</td>
                                                                    </div>
                                                                    {/* <div className='d-flex justify-content-between'>
                                                                        <td>Customer Address :</td>
                                                                        <td>{capitalizeAllFirstLetters(orderDetails?.customer_address)}</td>
                                                                    </div> */}
                                                                    <div className='d-flex justify-content-between'>
                                                                        <td> Payment method :</td>
                                                                        <td>{capitalizeFirstLetter(orderDetails?.transaction?.payment_method ? orderDetails?.transaction?.payment_method : '-')}</td>
                                                                    </div>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <td> Order payment status :</td>
                                                                        <td>{capitalizeFirstLetter(orderDetails?.transaction?.payment_status)}</td>
                                                                    </div>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <td>Order Status :</td>
                                                                        <td>{capitalizeFirstLetter(orderDetails?.order_status)}</td>
                                                                    </div>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <td>Order Mode :</td>
                                                                        <td>{capitalizeFirstLetter(orderDetails?.order_mode_configuration?.name)}</td>
                                                                    </div>
                                                                    {/* <div className='d-flex justify-content-between'>
                                                                        <td>Menu Items :</td>
                                                                        <td>{capitalizeFirstLetter(menuItemData)}</td>
                                                                    </div> */}
                                                                </tr>

                                                            </tbody>
                                                        </>
                                                    </table>
                                                    {orderDetails?.customer_instruction &&
                                                        <div className="form-group mb-3">
                                                            <label>Customer note</label>
                                                            <textarea
                                                                className="form-control"
                                                                value={capitalizeFirstLetter(orderDetails?.customer_instruction)}
                                                                readOnly
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                                {/* <div className="card-body">
                                                    <h3 className="main-content-label mb-3">Tip Details</h3>
                                                    <table id="simple-table" className="table ">
                                                        <>
                                                            <tbody style={{ border: 'none' }}>
                                                                <tr>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <td>Tip Amount :</td>
                                                                        <td>{orderDetails?.tip_amount ? orderDetails?.tip_amount  : 'N/A'}</td>
                                                                    </div>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <td>Tip Type :</td>
                                                                        <td>{capitalizeFirstLetter(orderDetails?.tip_type)}</td>
                                                                    </div>
                                                                </tr>
                                                            </tbody>
                                                        </>
                                                    </table>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 animation_fade">
                                            <div className="card custom-card">
                                                <div className="card-body">
                                                    <h3 className="main-content-label mb-3">Order Summary</h3>

                                                    {/* Order Items List */}
                                                    {orderDetails?.customer_order_menu_item_relations?.map((menuItem, index) => (
                                                        <>
                                                            <div key={index} className="order-item mb-3 d-flex align-items-center justify-content-between">
                                                                <div className="d-flex align-items-center">
                                                                    <img src={menuItem?.menu_item?.logo} alt="Cheese Burger" className="order-item-img me-3" />
                                                                    <div>
                                                                        <div className="order-item-name" style={{ fontWeight: '500' }}>{capitalizeAllFirstLetters(menuItem?.menu_item?.item_name)}</div>
                                                                        <div className="order-item-price">{menuItem?.price} {menuItem?.price ? "SAR" : ""}</div>
                                                                        <span className="order-item-price" style={{ fontSize: '0.8rem' }}>
                                                                            {menuItem?.tax_amount ? `Tax: ${menuItem.tax_amount} ${menuItem.tax_type ? `SAR (${menuItem.tax_type})` : ''}` : ''}
                                                                        </span>

                                                                    </div>
                                                                </div>
                                                                <span className="order-item-quantity" style={{ fontWeight: '500' }}>x{menuItem?.quantity}</span>
                                                            </div>
                                                            {/* {console.log("menuItem===>", menuItem.menu_add_on)} */}
                                                            {menuItem?.menuAddOns?.length > 0 && <h2>Add Ons</h2>}
                                                            {menuItem?.menuAddOns?.map((menuAddOn, idx) => (
                                                                <div className='d-flex justify-content-end'>
                                                                    <div key={idx} className="order-item py-1 px-2 mb-3 d-flex align-items-center justify-content-between" style={{ fontSize: '0.7rem', width: '90%' }}>
                                                                        <div className="d-flex align-items-center">
                                                                            <div>
                                                                                <div className="order-item-name" style={{ fontWeight: '500' }}>{capitalizeAllFirstLetters(menuAddOn?.menu_add_on?.name)}</div>
                                                                                <div className="order-item-price">Price: {menuAddOn?.price} {menuAddOn?.price ? "SAR" : ""}</div>
                                                                                <span className="order-item-price" style={{ fontSize: '0.8rem' }}>
                                                                                    Tax: {menuAddOn.tax_amount} SAR
                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                        <span className="order-item-quantity" style={{ fontWeight: '500' }}>x{menuAddOn?.quantity}</span>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>
                                                    ))}
                                                    {orderDetails?.customer_order_meal_combo_relations?.map((mealCombo, index) => (
                                                        <>
                                                            <div key={index} className="order-item mb-3 d-flex align-items-center justify-content-between">
                                                                <div className="d-flex align-items-center">
                                                                    <img src={mealCombo?.menu_meal_combo?.logo} alt={mealCombo?.menu_meal_combo?.name} className="order-item-img me-3" />
                                                                    <div>
                                                                        <div className="order-item-name" style={{ fontWeight: '500' }}>{capitalizeAllFirstLetters(mealCombo?.menu_meal_combo?.name)}</div>
                                                                        <div className="order-item-price">{mealCombo?.price} {mealCombo?.price ? "SAR" : ""}</div>
                                                                        <span className="order-item-price" style={{ fontSize: '0.8rem' }}>
                                                                            {mealCombo?.tax_amount ? `Tax: ${mealCombo.tax_amount} ${mealCombo.tax_type ? `SAR (${mealCombo.tax_type})` : ''}` : ''}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <span className="order-item-quantity" style={{ fontWeight: '500' }}>x{mealCombo?.quantity}</span>
                                                            </div>

                                                            {console.log("mealCombo===>", mealCombo?.menu_meal_combo?.menu_meal_combo_items)}
                                                             {mealCombo?.menu_meal_combo?.menu_meal_combo_items.length > 0 && mealCombo?.menu_meal_combo?.menu_meal_combo_items.length > 1 ? <h2>Items</h2> :  <h2>Item</h2>}
                                                            {mealCombo?.menu_meal_combo?.menu_meal_combo_items.map((menuAddOn, idx) => (
                                                                menuAddOn.menu_meal_combo_menu_items.length ?
                                                                <div className='d-flex justify-content-end'>
                                                                    <div key={idx} className="order-item py-1 px-2 mb-3 d-flex align-items-center justify-content-between" style={{ fontSize: '0.7rem', width: '90%' }}>
                                                                        <div className="d-flex align-items-center">
                                                                            <div>
                                                                                <div className="order-item-name" style={{ fontWeight: '500' }}>{capitalizeAllFirstLetters(menuAddOn?.item_type)}</div>
                                                                                {menuAddOn?.menu_meal_combo_menu_items.map((mItem,i) => (
                                                                                    <li key={i}>{mItem?.item_name}</li>
                                                                                )) }
                                                                            </div>
                                                                        </div>
                                                                        <span className="order-item-quantity" style={{ fontWeight: '500' }}>x{menuAddOn?.quantity}</span>
                                                                    </div>
                                                                </div> : null
                                                            ))} 

                                                        </>
                                                    ))}
                                                    {/* Order Summary Details */}
                                                    <div className="order-summary-details mt-4">
                                                        <div className="d-flex justify-content-between">
                                                            <span>Sub-Total</span>
                                                            <span>{showPrice(orderDetails?.sub_total_price)} SAR</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <span>Taxes</span>
                                                            <span>{showPrice(orderDetails?.tax_total_price)} SAR</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <span>Discount</span>
                                                            <span>{showPrice(orderDetails?.discount_price)} SAR</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <span>Surcharge</span>
                                                            <span>{showPrice(orderDetails?.surcharge_price)} SAR</span>
                                                        </div>
                                                        {/* {
                                                            orderDetails?.aggregator_commission ?
                                                                <div className="d-flex justify-content-between">
                                                                    <span>Aggregator commission</span>
                                                                    <span>{showPrice(orderDetails?.aggregator_commission)} SAR</span>
                                                                </div> :
                                                                null
                                                        } */}
                                                        <div className="d-flex justify-content-between fw-bold mt-3">
                                                            <span>Total</span>
                                                            <span>{showPrice(orderDetails?.total_price)} SAR</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {orderDetails?.transaction?.payment_status === 'paid' && orderDetails?.order_status === 'pending' ?
                                        <div className=" mt-2">
                                            <button className="btn btn-primary" type='button' onClick={() => { setShowRefundModal(true); }}>
                                                {/* <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i> */}
                                                Refund Order
                                            </button>
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-5 col-md-5 animation_fade"></div> */}
            </div>
            <Modal show={showRefundModal} onHide={() => { setShowRefundModal(false); setRefundReason(''); setRefundReasonText(''); }}>
                <Modal.Header closeButton>
                    <Modal.Title>Refund Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="refundReason" className='mb-3'>
                            <Form.Label>Select reason to refund order</Form.Label>
                            <Form.Control
                                className='cp'
                                as="select"
                                rows={3}
                                value={refundReason}
                                onChange={(e) => setRefundReason(e.target.value)}
                                placeholder="Select reason"
                            >
                                <option value="">Select a reason</option>
                                {allRefundReasons?.map((reason) => (
                                    <option key={reason.id} value={reason.id}>
                                        {reason.reason}
                                    </option>
                                ))}
                                {/* <option value="other">Other</option> */}
                            </Form.Control>
                            {/* {error && <small className="text-danger">{error}</small>} */}
                        </Form.Group>
                        {refundReason === 'other' &&
                            <Form.Group controlId="refundReasonText">
                                <Form.Label>Enter reason to refund order</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={refundReasonText}
                                    onChange={(e) => setRefundReasonText(e.target.value)}
                                    placeholder="Enter reason"
                                />
                                {error && <small className="text-danger">{error}</small>}
                            </Form.Group>
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-outline-primary" onClick={() => { setShowRefundModal(false); setRefundReason(''); setRefundReasonText('') }} >
                        Close
                    </button>
                    <Button variant="primary" onClick={handleRefundSubmit} disabled={!refundReason || (refundReason === 'other' && !refundReasonText)}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default RefundOrderView