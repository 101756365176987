import React, { useEffect, useState } from 'react'
import { getCompany } from '../../helpers/commonFunctions';
import { getAllLoggedInUser } from '../../services/company_panel/dashboardServices';
import { useParams } from 'react-router-dom';
import { LOADER_TIME } from '../../utils/Constants';
import Loader from '../../components/company_panel/common/loader';
import notFound from '../../assets/company/img/no-record-found.png'
import CustomPagination from '../../components/company_panel/common/custompagination';
import RsuiteCustomPagination from '../../components/company_panel/common/rsuitPagination';

const DashboardList = () => {

    const params = useParams()
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("");
    const [sorting, setSorting] = useState({});
    const [page, setPage] = useState(1);
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [loader, setLoader] = useState(true)
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [loggedInUser, setLoggedInUser] = useState([]);

    useEffect(() => {
        if (search) {
            setLoader(true)
            setTimeout(() => {
                const formData = new FormData();
                formData.append("page", page)
                formData.append("per_page", itemPerPage)
                formData.append("sort", JSON.stringify(sorting))
                formData.append("search", JSON.stringify(search))
                formData.append("global_search", globalSearch)
                formData.append("company_id", getCompany().company_id);
                getAllLoggedInUser(formData).then(data => {
                    // console.log("========****LoggedInUser****===>", data?.data?.response);
                    setDataLength(data.data.response.count);
                    setLoggedInUser(data && data.data && data?.data?.response ? data?.data?.response?.rows : [])
                    setLoader(false)
                }).catch(error => {
                    console.log("error ====> ", error);
                })
            }, LOADER_TIME);
        }
    }, [sorting, search, globalSearch, statsUpdate, page]);

    return (
        <>
            <div className='card mt-4'>
                <div className='card-header d-flex align-items-center justify-content-between'>
                    <div className="card-header-left">
                        <h4>Recent Activities</h4>
                    </div>
                    <div className="card-header-right">
                        <ul className="nav nav-pills" id="pills-tab2" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-Logged-Users-tab" data-bs-toggle="pill" data-bs-target="#pills-Logged-Users" type="button" role="tab" aria-controls="pills-Logged-Users" aria-selected="true">Logged Users</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-Today-orders-tab" data-bs-toggle="pill" data-bs-target="#pills-Today-orders" type="button" role="tab" aria-controls="pills-Today-orders" aria-selected="false">Today's orders</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-Today-payment-tab" data-bs-toggle="pill" data-bs-target="#pills-Today-payment" type="button" role="tab" aria-controls="pills-Today-payment" aria-selected="false">Today's payment</button>
                            </li>
                        </ul>
                    </div>
                </div>

                {loggedInUser?.length > 0 && <div className="tabler-wrap">
                    <div className="table-responsive">
                        <table className="table table-custom">
                            <thead>
                                <tr>
                                    <th style={{ width: '33%' }} className='action_head'>Name</th>
                                    <th style={{ width: '33%' }} className='action_head'>Role</th>
                                    <th style={{ width: '33%' }} className='action_head'>Date</th>
                                </tr>
                            </thead>

                            <tbody>
                                {loader ?
                                    <tr>
                                        <td colSpan={11}><Loader /></td>
                                    </tr> :
                                    <>
                                        {loggedInUser?.length > 0 ? (
                                            loggedInUser?.map((user, index) => (
                                                <tr key={index}>
                                                    <td>{`${user?.first_name} ${user?.last_name}`}</td>
                                                    <td>{user?.role}</td>
                                                    <td>{"21-23-2345"}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={9} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No record found</p>
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='tabler-footer'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                            {dataLength && dataLength > 0 ?
                                // <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "user", path: "/company/dashboard" }]} /> 

                                <RsuiteCustomPagination
                                    datalength={dataLength}
                                    itemperpage={itemPerPage}
                                    setPage={setPage}
                                    currentPage={page}
                                />

                                : ""}
                        </div>
                    </div>
                </div>}

            </div>
        </>
    )
}

export default DashboardList;
