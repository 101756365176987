import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GetSingleCategory } from '../../../services/company_panel/menuCategoryService'
import toHtml from 'html-react-parser'
import { formateDate, getBrandId, TrimText } from '../../../helpers/commonFunctions'
import Breadcrums from '../../../components/company_panel/common/breadcrumbs';
import Loader from '../../../components/company_panel/common/loader';
import { SingleAddOn } from '../../../services/company_panel/menuAddOnCategory'

const AddOnsView = () => {
    const navigate = useNavigate()
    const params = useParams()
    console.log(params)
    const [showDefault, setShowDefault] = useState({});
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [currentBrand] = useState(getBrandId());
    const breadcrumbs = [{ title: "Menu", url: "/company/menu/menu/categories/list/1" }, { title: "Add-ons", url: "/company/menu/menu/add_ons/list/1" }, { title: "Add-Ons Listing", url: "/company/menu/menu/add_ons/list/1" }, { title: "Add-Ons Detail", url: "" }]

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        if (currentBrand != getBrandId()) {
            navigate(`/company/menu/menu/add_ons/list/${1}`);
        }
    });

    useEffect(() => {
        SingleAddOn({ id: params.id }).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    const handleEdit = () => {
        navigate(`/company/menu/menu/add_ons/edit/${params.id}`);
    }


    return (
        <>
            <div class="page-header d-flex align-items-center">
                <div class="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Menu </h4>
                </div>
                <div class="page-header-right ms-auto">
                    <button className='btn btn-primary' onClick={() => { handleEdit() }}>Edit Category</button>
                </div>
            </div>
            <div className="card custom-card">

                <div className="card-body">
                    <div className="profile-info">
                        <div className="row">
                            <div className="col-6 profile-info-in">
                                <h5>Name</h5>
                                <p>{showDefault && showDefault?.name ? showDefault?.name : "N/A"}</p>
                            </div>
                            <div className="col-6 profile-info-in">
                                <h5>Name (Arabic)</h5>
                                <p>{showDefault && showDefault?.name_ar ? showDefault?.name_ar : "N/A"}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 profile-info-in">
                                <h5>Category</h5>
                                <p>{showDefault && showDefault?.menu_add_on_category?.name ? showDefault?.menu_add_on_category?.name : "N/A"}</p>
                            </div>
                            <div className="col-6 profile-info-in">
                                <h5>Price</h5>
                                <p>{showDefault && showDefault.price ? parseFloat(showDefault.price).toFixed(2) : "N/A"} SAR</p>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 profile-info-in">
                                <h5>Short description</h5>
                                <p>{showDefault && showDefault?.short_description}</p>
                            </div>
                            <div className="col-6 profile-info-in">
                                <h5>Short description (Arabic)</h5>
                                <p>{showDefault && showDefault?.short_description_ar}</p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}

export default AddOnsView