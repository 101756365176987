import React, { useEffect, useMemo, useState } from 'react';
import { useAsyncError, useNavigate, useParams } from 'react-router-dom';
import { GetOutletDetail, DeleteHardwareDevice } from '../../services/company_panel/outletService';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import Loader from '../../components/company_panel/common/loader';
import Swal from 'sweetalert2';
import { Button, Modal } from 'react-bootstrap';
import { EmailSend, GetPurchaseOrderDetail, changePurchaseOrderStatus, confirmPurchaseOrderInventories } from '../../services/company_panel/purchaseOrderService';
import { capitalizeFirstLetter, getCompany, getOutletId } from '../../helpers/commonFunctions';

const OrderView = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [showDefault, setShowDefault] = useState({});
    const breadcrumbs = [{ title: "Purchase order", url: "/company/store_manager/purchase_order/list/1" }, { title: "Create Order", url: "" }, { title: "Order invoice", url: "" }];
    const [statsUpdate, setStatusUpdate] = useState("false");
    const [showModal, setShowModal] = useState(false);
    const [quantities, setQuantities] = useState({});
    // let totalPrice = 0;
    const [totalPrice, setTotalPrice] = useState(0);
    // const [finalPrice, setFinalPrice] = useState(0);
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [currentOutlet] = useState(getOutletId());
    const [isOutletChanged, setIsOutletChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        });
        if (currentOutlet != getOutletId()) {
            navigate(`/company/store_manager/purchase_order/list/${1}`);
        }
    });

    useEffect(() => {
        GetPurchaseOrderDetail({ id: params.id }).then(data => {
            const responseData = data && data.data && data.data.response ? data.data.response : [];
            setShowDefault(responseData);
            const initialQuantities = responseData.purchase_orders_inventories.reduce((acc, item) => {
                acc[item.inventory_id] = item.ordered_quantity;
                return acc;
            }, {});
            setQuantities(initialQuantities);
        }).catch(error => {
            console.log("error=====>", error);
        });
    }, [statsUpdate]);

    useEffect(() => {
        if (showDefault?.purchase_orders_inventories) {
            const newTotalPrice = showDefault.purchase_orders_inventories.reduce((acc, item) => {
                const quantity = showDefault.order_status ? item?.recieved_quantity : quantities[item.inventory_id];
                return acc + (quantity * item?.price);
            }, 0);
            setTotalPrice(newTotalPrice);
        }
    }, [showDefault]);

    // Delete functionality
    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData();
                formdata.append("id", row);
                DeleteHardwareDevice(formdata).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: 'Record deleted successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        });
                        setStatusUpdate(!statsUpdate);
                    }
                }).catch(error => {
                    console.log("deleteError");
                });
            }
        });
    }

    const handleQuantityChange = (inventory_id, newQuantity) => {
        console.log(newQuantity);
        if (newQuantity != Math.floor(newQuantity)) {
            return;
        }
        setQuantities(prevQuantities => ({
            ...prevQuantities,
            [inventory_id]: newQuantity
        }));
    };

    const handleConfirm = () => {
        const updatedItems = showDefault.purchase_orders_inventories.map(item => ({
            inventory_id: item.inventory_id,
            item_name: item.inventory.item_name,
            quantity: Number(quantities[item.inventory_id]),
            ordered_quantity: item.ordered_quantity,
            price: item.price
        }));
        const newTotalPrice = updatedItems.reduce((acc, item) => {
            const quantity = item?.quantity ? item?.quantity : 0
            return acc + (quantity * item?.price);
        }, 0);
        setTotalPrice(newTotalPrice);
        confirmPurchaseOrderInventories({
            purchase_order_id: params.id,
            final_price: newTotalPrice ? newTotalPrice : totalPrice,
            inventories: updatedItems,
            company_id: getCompany().company_id,
            outlet_id: showDefault.outlet_id
        }).then(response => {

            setShowModal(false);
            Swal.fire({
                customClass: 'swal-wide',
                position: 'absolute',
                icon: 'success',
                title: 'Order marked as received',
                showConfirmButton: false,
                timer: 2000,
                toast: true,
                position: 'top-right',
            });
            setStatusUpdate(!statsUpdate);
        }).catch(error => {
            console.log("updateError", error);
        });
    };

    function sendEmail(data) {
        const formData = new FormData();
        formData.append("supplier_id", data?.supplier_id);
        formData.append("outlet_id", getOutletId());
        formData.append("order_id", data?.id);
        if (showDefault?.purchase_orders_inventories && showDefault?.purchase_orders_inventories.length) {
            formData.append("tableData", JSON.stringify(showDefault?.purchase_orders_inventories));
        }
        EmailSend(formData).then(data => {
            if (data.status === 200) {
                Swal.fire({
                    customClass: 'swal-wide',
                    position: 'absolute',
                    icon: 'success',
                    title: 'Email send successfully',
                    showConfirmButton: false,
                    timer: 2000,
                    toast: true,
                    position: 'top-right',
                });
            }
        }).catch(error => {
            console.log("deleteError");
        });
    }


    return (
        <>
            <div className="page-header d-flex align-items-center justify-content-between">
                <div class="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Purchase order</h4>
                </div>
                {/* {
                    showDefault && showDefault.order_status == false ?
                        <Button variant="primary" onClick={() => setShowModal(true)}>
                            Mark as Received
                        </Button> :
                        <Button variant="secondary">
                            Order Received
                        </Button>
                } */}
                {
                    showDefault && showDefault.order_status === false && showDefault?.status === false ?
                        null :
                        showDefault && showDefault.order_status === false ?
                            <Button variant="primary" onClick={() => setShowModal(true)}>
                                Mark as Received
                            </Button> :
                            <Button variant="secondary">
                                Order Received
                            </Button>
                }

            </div>

            <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                    <div className="card custom-card">
                        <div className="card-body p-0   ">
                            <div className="d-flex justify-content-between p-3" style={{ background: '#F2F5F9' }}>
                                <div>
                                    <div style={{ display: "flex" }}>
                                        <h5 style={{ fontSize: '1.5em', width: '200px', }}>{capitalizeFirstLetter(showDefault?.supplier?.name)}</h5>
                                        {showDefault?.status === true && showDefault?.order_status == false ?
                                            <a variant="secondary" className="ms-3 cp" onClick={() => sendEmail(showDefault)}>
                                                Send Email to Supplier
                                            </a>
                                            : null}
                                    </div>
                                    <p>Email: <a href={`mailto:${showDefault?.supplier?.email}`}>{showDefault?.supplier?.email}</a></p>
                                    <p>Phone: {showDefault?.supplier?.phone_number}</p>
                                    <p>Address: {showDefault?.supplier?.street_address}, {showDefault?.supplier?.city}</p>
                                </div>
                                {/* <div className="d-flex align-items-start"></div> */}
                                {/* <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>   */}
                                <div className='d-flex flex-column gap-2 w-25'>
                                    <div className='d-flex justify-content-between'>
                                        <div>Outlet</div>
                                        <div>{showDefault?.outlet?.outlet_name}</div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div>Delivery Date</div>
                                        <div>{showDefault?.delivery_date}</div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div>Order ID</div>
                                        <div>#Purchase id {showDefault?.id}</div>
                                    </div>
                                </div>
                            </div>
                            {showDefault && showDefault?.purchase_orders_inventories && Object.keys(showDefault?.purchase_orders_inventories).length > 0 ? (
                                <div className='row'>
                                    <div className="col-lg-12 form-group">
                                        <table id="simple-table" className="table table-custom">
                                            <thead>
                                                <tr>
                                                    <th>Item Name</th>
                                                    <th>Ordered quantity</th>
                                                    <th>Recieved quantity</th>
                                                    <th>Unit of measure</th>
                                                    <th>Price</th>
                                                    <th>Total price</th>
                                                    {/* <th>UOM</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {showDefault?.purchase_orders_inventories?.map((item, index) => {
                                                    // if (showDefault.order_status == true) {
                                                    //     totalPrice = totalPrice + (item?.recieved_quantity * item?.price);
                                                    // } else {
                                                    //     totalPrice = totalPrice + (item?.ordered_quantity * item?.price);
                                                    // }
                                                    return (
                                                        <tr key={index}>
                                                            <td>{item?.inventory?.item_name}</td>
                                                            <td>{item?.ordered_quantity}</td>
                                                            <td>{item?.recieved_quantity}</td>
                                                            <td>{item?.inventory?.uom?.uom} ({item?.inventory?.uom?.uom_code})</td>
                                                            <td>{item?.price}</td>
                                                            <td>{showDefault ?
                                                                showDefault.order_status == true ?
                                                                    item?.recieved_quantity * item?.price :
                                                                    item?.ordered_quantity * item?.price
                                                                : "N/A"}</td>
                                                            {/* <td>{capitalizeFirstLetter(item?.inventory?.uom?.uom)}</td> */}
                                                        </tr>
                                                    );
                                                })}
                                                <tr>
                                                    <td colSpan="5" className="text-end" style={{ borderBottom: 'none', textDecoration: 'none' }}><strong>Sub-Total:</strong></td>
                                                    <td colSpan="1" style={{ borderBottom: 'none', textDecoration: 'none' }}>{totalPrice}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="p-3">
                                            <button className="btn ripple btn-secondary " type='button' onClick={() => navigate(-1)}>
                                                <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <p style={{ textAlign: 'center' }}>No data found</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)} >
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '1.5em' }}>Mark as Received</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: '0' }}>
                    <table className="table table-custom">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Unit Of Measure</th>
                                <th>Inventory Stock</th>
                            </tr>
                        </thead>
                        <tbody>
                            {console.log("showDefault", showDefault)}
                            {showDefault?.purchase_orders_inventories?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{capitalizeFirstLetter(item?.inventory?.item_name) || "N/A"}</td>
                                        <td>{capitalizeFirstLetter(item?.inventory?.uom?.uom) || "N/A"}</td>
                                        <td>
                                            <input
                                                type="number"
                                                value={quantities[item.inventory_id]}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (value === '' || (parseInt(value, 10) > 0 && parseInt(value, 10) <= 100000)) {
                                                        handleQuantityChange(item.inventory_id, value);
                                                    }
                                                }}
                                                onInvalid={(e) => {
                                                    if (!e.target.value) {
                                                        e.target.setCustomValidity('Please enter a value.');
                                                    }
                                                }}
                                                onInput={(e) => {
                                                    e.target.setCustomValidity('');
                                                }}
                                                required
                                            />

                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-outline-primary' onClick={() => setShowModal(false)}>
                        Close
                    </button>
                    <Button variant="primary" onClick={handleConfirm}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default OrderView;
