import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import { ChangeStatusOutletUser, GetOutletUserList, DeleteOutletUser } from '../../../services/company_panel/platform_user/platformUserService';
import Swal from 'sweetalert2'
import CustomPagination from '../../../components/company_panel/common/custompagination'
import { formateDate, getCompany, checkPermission, getOutletId, capitalizeFirstLetter } from '../../../helpers/commonFunctions';
import Sorting from '../../../components/company_panel/common/sorting';
import Loader from '../../../components/company_panel/common/loader'
import Breadcrums from '../../../components/company_panel/common/breadcrumbs'
import StatusFilter from '../../../components/company_panel/common/statusFilter'
import { showFilterlist } from '../../../utils/Constants'
import CustomRangepicker from '../../../components/company_panel/common/rangepicker'
import $ from "jquery"
import { Button, Card, Table, Container, Row, Col, Modal } from 'react-bootstrap';
import { acceptTransferStockInRequest, GetTransferStockInRequestsList, recievedTransferStockInRequest, rejectTransferStockInRequest } from '../../../services/company_panel/transferStockService';
import notFound from '../../../assets/company/img/no-record-found.png'



const TransferInList = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Pages", url: "" },]
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false)
    const [fetchData, setFetchData] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState();
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [isOutletChanged, setIsOutletChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        });
    });

    useEffect(() => {
        if (search) {
            setLoader(true)
            setTimeout(() => {
                const formData = new FormData()
                formData.append("page", params.id)
                formData.append("per_page", itemPerPage)
                formData.append("sort", JSON.stringify(sorting))
                formData.append("search", JSON.stringify(search))
                formData.append("global_search", globalSearch)
                formData.append('outlet_id', getOutletId());
                GetTransferStockInRequestsList(formData).then(data => {
                    setDataLength(data.data.response.count);
                    setList(data && data.data && data.data.response ? data.data.response.rows : [])

                    setLoader(false)
                }).catch(error => {
                    console.log("error ====> ", error);
                })
            }, 1000);
        }
    }, [location, statsUpdate, sorting, search, globalSearch, fetchData, isBrandChanged, isOutletChanged])


    const acceptRequest = (id, value) => {
        acceptTransferStockInRequest({ id, value }).then(data => {
            console.log('accepted successfully');
            setFetchData(!fetchData);
        }).catch(error => {
            console.log("error ====> ", error);
        })
    }

    // const rejectRequest = (id, value) => {
    //     Swal.fire({
    //         customClass: 'swal-wide',
    //         title: 'Are you sure?',
    //         text: "You won't be able to revert this!",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#2f9bff',
    //         cancelButtonColor: '#dc3545',
    //         confirmButtonText: 'Yes, reject it!'
    //     })
    //     rejectTransferStockInRequest({ id, value }).then(data => {
    //         console.log('rejected successfully');
    //         setFetchData(!fetchData);
    //     }).catch(error => {
    //         console.log("error ====> ", error);
    //     })
    // }

    function rejectRequest(id, value) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, reject it!'
        }).then((result) => {
            if (result.isConfirmed) {
                rejectTransferStockInRequest({ id, value }).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: 'Order rejected successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setFetchData(!fetchData);
                    }
                }).catch(error => {
                    console.log("deleteError")
                })
            }
        })
    }

    const recievedRequest = (id, value) => {
        recievedTransferStockInRequest({ id, value }).then(data => {
            console.log('recieved successfully');
            setFetchData(!fetchData);
        }).catch(error => {
            console.log("error ====> ", error);
        })
    }

    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
    }



    const viewFunction = (row) => {
        navigate(`/company/platform-User/outlet-user/view/${row}`)
    }


    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end

    const closeModel = (id) => {
        $('#addCardModal').hide();
        $('.modal-backdrop').remove();
        setStatusUpdate(!statsUpdate)
    }

    const openModel = (id, type) => {
        $('#' + id).show().css('opacity', 1).addClass('show');
        $('body').append('<div class="modal-backdrop fade show"></div>')
    }
    return (
        <>
            <div className="animation_fade" >
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left' >
                        <h4>Transfer stock</h4>
                    </div>
                    <div className='page-header-right ms-auto'>

                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <ul className="d-flex setting w-100 mb-0 p-0"   >
                            {checkPermission('store_transfer_in', 'view') ?
                                <li className="active">
                                    <Link to={`/company/store_manager/transfer_stock/transfer_in/list/${1}`} className="btn btn-primary" color='black'>Transfer In</Link>
                                </li>
                                :''}
                                  {checkPermission('store_transfer_out', 'view') ?
                                <li className="ms-3">
                                    <Link to={`/company/store_manager/transfer_stock/transfer_out/list/${1}`} className="btn" color='black'>Transfer Out</Link>
                                </li>
                                : ''}
                            </ul>
                        </div>
                    </div>
                    {/* <div className='gap-20'>
                        <div className='gap-20'>
                            <div className='filter-header d-flex justify-content-between align-items-center'>
                                <div className='d-flex gap-3 align-items-center'>
                                    <div className="form-group mb-0 rangepicker_container">
                                        <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                    </div>
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <div className="date-input">
                                        <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />
                                    </div>
                                    <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                    <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th width="20%">Outlet</th>
                                        <th width="20%">Date</th>
                                        <th width="20%">Status</th>
                                        <th width="20%"></th>
                                        {checkPermission('store_transfer_in', 'view') ?
                                        <th width="20%"></th>
                                        :''}
                                    </tr>
                                </thead>
                                {/* <tbody>
                                    {loader ? (
                                        <tr>
                                            <td colSpan={6}><Loader /></td>
                                        </tr>
                                    ) : (
                                        <>
                                            {list.length ? list.map((row, i) => (
                                                <tr key={i}>
                                                    <td>{row.outlet?.outlet_name}</td>
                                                    <td>{formateDate(row?.created_at)}</td>
                                                    <td >{row.rejected == true ?
                                                        'Rejected' :
                                                        (row.recieved ?
                                                            'Recieved' :
                                                            (row.accepted ?
                                                                'Accepted' :
                                                                'Created'
                                                            )
                                                        )}
                                                    </td>
                                                    <td className="d-flex justify-content-start align-items-center">
                                                        {
                                                            (row.recieved == true || row.rejected == true) ?
                                                                null :
                                                                (
                                                                    row.accepted == true ?
                                                                        <button className='btn btn-primary' variant="primary"  style={{ width: '200px' }} onClick={() => recievedRequest(row.id, true)}>Mark as Recieved</button > :
                                                                        <>
                                                                            <button className='btn btn-primary' variant="primary"  style={{ width: '200px' }} onClick={() => acceptRequest(row.id, true)}>Accept</button  >
                                                                            <button className='btn btn-primary' style={{ width: '200px', color: 'black', backgroundColor: 'white', border: '1px solid black' }} onClick={() => rejectRequest(row.id, true)}>Reject</button >
                                                                        </>
                                                                )
                                                        }
                                                    </td>
                                                    <td>
                                                        <button className='btn btn-primary' variant="primary"  style={{ width: '200px' }} onClick={() => { setShowModal(true); setModalData(row) }}>View</button >
                                                    </td>
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td colSpan={6} className="text-center">
                                                        <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                        <p>No Record.</p>
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    )}
                                </tbody> */}
                                <tbody>
                                    {loader ? (
                                        <tr>
                                            <td colSpan={6}><Loader /></td>
                                        </tr>
                                    ) : (
                                        <>
                                            {list.length ? list.map((row, i) => (
                                                <tr key={i}>
                                                    <td>{row.outlet?.outlet_name} {row.outlet?.short_code ? `(${row.outlet?.short_code})` : ""}</td>
                                                    <td>{formateDate(row?.created_at)}</td>
                                                    <td>
                                                        {row.rejected ? 'Rejected' : (row.recieved ? 'Received' : (row.accepted ? 'Accepted' : 'Created'))}
                                                    </td>
                                                    <td>
                                                        {!(row.recieved || row.rejected) && (
                                                            row.accepted ? (
                                                                <button
                                                                    className='btn btn-primary'
                                                                    style={{ width: '200px' }}
                                                                    onClick={() => recievedRequest(row.id, true)}
                                                                >
                                                                    Mark as Received
                                                                </button>
                                                            ) : (
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    <button
                                                                        className='btn btn-primary me-3'
                                                                        style={{ width: '100px' }}
                                                                        onClick={() => acceptRequest(row.id, true)}
                                                                    >
                                                                        Accept
                                                                    </button>
                                                                    <button
                                                                        className='btn btn-primary'
                                                                        style={{ width: '100px', color: 'black', backgroundColor: 'white', border: '1px solid black' }}
                                                                        onClick={() => rejectRequest(row.id, true)}
                                                                    >
                                                                        Reject
                                                                    </button>
                                                                </div>
                                                            )
                                                        )}
                                                    </td>
                                                    {checkPermission('store_transfer_in', 'view') ?
                                                    <td>
                                                        <button
                                                            className='btn btn-primary'
                                                            style={{ width: '200px' }}
                                                            onClick={() => { setShowModal(true); setModalData(row); }}
                                                        >
                                                            View
                                                        </button>
                                                    </td>
                                                :''}
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td colSpan={6} className="text-center">
                                                        <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                        <p>No Record.</p>
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    )}
                                </tbody>

                            </table>
                        </div>
                    </div>
                    <div className='tabler-footer'>
                        <div className='d-flex align-items-center'>
                            <div id="example1_info" role="status" className="table-result" aria-live="polite">
                                <b>Total Records : {dataLength || "0"}</b>
                            </div>
                            {dataLength && dataLength > 0 ? (
                                <CustomPagination
                                    datalength={dataLength}
                                    itemperpage={itemPerPage}
                                    setPage={setPage}
                                    currentPage={page}
                                    pageRoute={[{ name: "outlet-management", path: "/company/store_manager/transfer_stock/transfer_in/list" }]}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>

            <Modal centered show={showModal} onHide={() => setShowModal(false)} >
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '1.5em' }}>
                        {capitalizeFirstLetter(modalData?.outlet?.outlet_name)}
                        {modalData?.outlet?.short_code ? `(${modalData?.outlet?.short_code})` : ""}
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body style={{ padding: '0' }}>
                    <table className="table table-custom">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Inventory Stock</th>
                                <th>Unit of measure</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                modalData?.transfer_stock_request_inventories.map((request) => {
                                    return <tr>
                                        <td>{request?.inventory.item_name}</td>
                                        <td>{request?.quantity}</td>
                                        <td>{request?.transfer_unit_uom}</td>
                                    </tr>
                                })
                            }

                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-outline-primary' onClick={() => setShowModal(false)}>
                        Close
                    </button>

                </Modal.Footer>
            </Modal>
        </>
    );
}

export default TransferInList