import React, { useState, useEffect, useMemo } from 'react'
import { useFormik } from 'formik';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import { GetChangeStatus, GetTenderList, DeleteTender, CreateTender, GetTenderDetail, GetEditTender } from '../../../services/company_panel/setting/tenderConfigurationService';
import Swal from 'sweetalert2'
import CustomPagination from '../../../components/company_panel/common/custompagination'
import Sorting from '../../../components/company_panel/common/sorting';
import Loader from '../../../components/company_panel/common/loader'
import $ from "jquery";
import { capitalizeAllFirstLetters, checkPermission, getBrandId, getCompany, removeModalBackdrops } from '../../../helpers/commonFunctions';
import Select from 'react-select';
import { GetActiveOrderModes } from '../../../services/company_panel/setting/orderModeConfigurationService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfigurationTab from '../configurationTab';
import { GetTaxGroupAllList } from '../../../services/company_panel/setting/taxConfigurationService';
import { Dropdown } from 'semantic-ui-react';
import * as yup from 'yup';
import notFound from '../../../assets/company/img/no-record-found.png'
import { LOADER_TIME } from '../../../utils/Constants';
import CustomMultiSelect from '../../../components/common/CustomMultiSelect';


const TenderList = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Pages", url: "" },]
    const navigate = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const params = useParams()
    const location = useLocation()
    const [isOverwrite, setIsOverwrite] = useState(false)

    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false);
    const [tenderType, setTenderType] = useState('Card')
    const [name, setName] = useState('Card Type')
    const [showDefault, setShowDefault] = useState({})
    const [actionType, setActionType] = useState('add');

    const [orderMode, setOrderMode] = useState([]);
    const [orderModeList, setOrderModeList] = useState([]);
    const [taxGroupList, setTaxGroupList] = useState([]);
    const [isBrandChanged, setIsBrandChanged] = useState(false);

    const handleChangeOrderMode = (selected) => {
        formik.setFieldValue("orderMode", selected);
    };

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
    });

    useEffect(() => {
        return () => {
            removeModalBackdrops();
        }
    }, []);

    useEffect(() => {
        GetActiveOrderModes(getBrandId()).then(data => {
            setOrderModeList(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error ====> ", error);
        })

        GetTaxGroupAllList(getBrandId()).then(data => {
            const taxGroup = data && data.data && data.data.response ? data.data.response.tax_groups : []
            const taxGroupDropdown = taxGroup.map((res) => ({
                key: res.id,
                text: capitalizeAllFirstLetters(res.name),
                value: res.id,
            }))
            setTaxGroupList(taxGroupDropdown)

        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [isBrandChanged])


    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            formData.append('tender_type', tenderType);
            formData.append('brand_id', getBrandId());
            GetTenderList(formData).then(data => {
                // console.log(data.data.response.rows);
                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response ? data.data.response.rows : [])
                setTimeout(() => {
                    setLoader(false)
                }, LOADER_TIME);
            }).catch(error => {
                setTimeout(() => {
                    setLoader(false)
                }, LOADER_TIME);
                console.log("error ====> ", error);
            })
        }
        generateSKU();
    }, [location, statsUpdate, sorting, search, globalSearch, tenderType, isBrandChanged]);

    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        setSearch({})
        prepareSearch()
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
    }

    // Delete functionality
    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData()
                formdata.append("id", row)
                DeleteTender(row).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: 'Tender deleted successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError")
                })
            }
        })
    }

    // Change Status functionality

    const ChangeStatus = (row, Num) => {
        const formData = new FormData()
        formData.append("id", row)
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                GetChangeStatus(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("error===>")
                })
            }
        })
    }

    // edit functionality

    const editFunction = (row) => {

        GetTenderDetail(row).then(data => {

            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
            setOrderMode(data && data.data && data.data.response ? data.data.response.order_modes : []);
        }).catch(error => {
            console.log("error=====>", error)
        })
        setActionType('edit');
        $('#addCardModal').show().css('opacity', 1).addClass('show');
        $('body').append('<div class="modal-backdrop fade show"></div>')
    }

    const copyFunction = (row) => {

        GetTenderDetail(row).then(data => {

            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
            setOrderMode(data && data.data && data.data.response ? data.data.response.order_modes : []);
        }).catch(error => {
            console.log("error=====>", error)
        })
        setActionType('add');
        $('#addCardModal').show().css('opacity', 1).addClass('show');
        $('body').append('<div class="modal-backdrop fade show"></div>')
    }

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end



    // addMore 

    const tenderConfiguration = (value) => {
        setTenderType(value);
        let nameEn = name;

        if (value === 'Card') {
            nameEn = 'Card Type';
        } else if (value === 'Cash') {
            nameEn = 'Currency Type';
        } else if (value === 'Online') {
            nameEn = 'Bank Name';
        } else {
            nameEn = 'Credit Name'; // or any default value you want
        }
        setName(nameEn);
    }


    const generateSKU = (length = 8) => {

        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let sku = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            sku += characters[randomIndex];
        }
        formik.setFieldValue("sku", sku);
    }

    const closeModel = (id) => {
        formik.setErrors({});
        formik.resetForm();
        $('#addCardModal').hide();
        $('.modal-backdrop').remove();
        setShowDefault({});
        setStatusUpdate(!statsUpdate)
    }

    const openModel = (id, type) => {
        formik.setErrors({});
        formik.resetForm();
        setActionType(type);
        $('#' + id).show().css('opacity', 1).addClass('show');
        $('body').append('<div class="modal-backdrop fade show"></div>')
        setOrderMode([]);
    }



    // Add and Edit Data
    const validationSchema = yup.object().shape({
        name: yup.string().trim().required('This field is required').min(3, 'This field must be at least 3 characters').max(30, 'This field must be at most 30 characters'),
        name_ar: yup.string().trim().required('This field (Arabic) is required').min(3, 'This field (Arabic) must be at least 3 characters').max(30, 'This field (Arabic) must be at most 30 characters'),
        sku: yup.string().trim().required("SKU is required"),
        orderMode: yup.array()
            .of(
                yup.number().required()
            )
            .min(1, 'At least one order mode must be selected')
            .required('Order mode is required'),
        // tax: yup.string().required('Tax is required'),
        // tax_group: isOverwrite ? "" : yup.string().required('Tax Group is required'),
        // tax: yup.string().required('Tax is required'),
        tax_group: yup.string().test(
            'is-required',
            'Tax Group is required when "Overwrite" is selected',
            function (value) {
                const { tax } = this.parent;
                if (tax === 'overwrite' && !value) {
                    return false;
                }
                return true;
            }
        ),
        sku: yup.string().trim().required('SKU is required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: showDefault && showDefault?.name ? showDefault?.name : "",
            name_ar: showDefault && showDefault?.name_ar ? showDefault?.name_ar : "",
            sku: showDefault && showDefault?.sku ? showDefault?.sku : "",
            commission: showDefault && showDefault?.commission ? showDefault?.commission : "",
            // orderMode: Array.isArray(showDefault?.order_modes)? [showDefault.order_modes]:[],
            orderMode: Array.isArray(showDefault?.order_modes) ? showDefault?.order_modes?.map(item => { return item.id }) : [],
            // orderMode: showDefault && showDefault?.order_modes && showDefault?.order_modes[0]?.name ? showDefault?.order_modes : [],
            status: showDefault && showDefault?.status == 1 ? 'on' : "",
            tax: showDefault && showDefault?.tax ? showDefault?.tax : "primary",
            tax_group: showDefault && showDefault?.tax_group ? showDefault?.tax_group : ""
        },
        validationSchema: validationSchema,
        onSubmit: values => {

            setSubmitted(true)
            let formData = new FormData();
            formData.append('name', values?.name?.trim());
            formData.append('name_ar', values?.name_ar?.trim());
            formData.append('sku', values?.sku?.trim());
            formData.append('status', values.status == 'on' ? 1 : 0);
            formData.append('company_id', getCompany().company_id);
            formData.append('brand_id', getBrandId());
            formData.append('tender_type', tenderType);
            formData.append('id', showDefault && showDefault.id ? showDefault.id : "");
            formData.append('tax', values.tax);
            formData.append('tax_group', values?.tax === 'overwrite' ? values?.tax_group : "");


            if (values.orderMode) {
                formData.append('order_mode', JSON.stringify(values.orderMode));
            }

            if (actionType === 'edit') {
                GetEditTender(formData).then(response => {


                    if (response.data.status === 1 || response.data.status === "1") {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                        setTimeout(() => {
                            $('#addCardModal').hide();
                            $('.modal-backdrop').remove();
                            setStatusUpdate(!statsUpdate)
                            formik.resetForm();
                            setShowDefault({});
                        }, 1000);
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'error',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                        setSubmitted(false)
                    }
                }).catch(error => {
                    console.log("error ====> ", error);
                })

            } else {
                CreateTender(formData).then(response => {

                    if (response.data.status === 1 || response.data.status === "1") {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                        setTimeout(() => {
                            $('#addCardModal').hide();
                            $('.modal-backdrop').remove();
                            setStatusUpdate(!statsUpdate)
                            formik.resetForm();
                            setShowDefault({});
                        }, 1000);
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'error',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                        setSubmitted(false)
                    }
                }).catch(error => {
                    console.log("error ====> ", error);
                })

            }

        },
    });
    console.log("formik--", formik.values);
    console.log("orderModeList ", orderModeList, "*****", showDefault);
    return (
        <>
            {console.log("formik Error", formik.errors)}

            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Settings</h4>
                    </div>
                    <div className='page-header-right ms-auto'>

                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='col-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <ConfigurationTab tab='tender' />
                            </div>
                        </div>
                    </div>
                    <div className='gap-20 ps-0 pb-0 pe-0'>
                        <ul className='d-flex setting submenu-list border-bottom mb-0 p-0'>
                            <li className={tenderType === 'Card' ? 'active' : ''} onClick={() => { tenderConfiguration('Card') }}>Cards</li>
                            <li className={tenderType === 'Cash' ? 'active' : ''} onClick={() => { tenderConfiguration('Cash') }}>Cash</li>
                            <li className={tenderType === 'Online' ? 'active' : ''} onClick={() => { tenderConfiguration('Online') }}>Online</li>
                            <li className={tenderType === 'Credit' ? 'active' : ''} onClick={() => { tenderConfiguration('Credit') }}>Credit</li>
                        </ul>
                    </div>
                    <div className="gap-20">
                        <div className='page-header d-flex align-items-center mb-0'>
                            <div className='page-header-left'>

                            </div>
                            <div className='page-header-right ms-auto'>
                            {checkPermission('store_settings', 'add') ?
                                <button className="btn btn-primary btn-lg" type="button" onClick={(e) => openModel('addCardModal', 'add')}>
                                    Add {name}
                                </button>
                                :''}
                            </div>

                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>ID  </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="id" />
                                            </div>
                                        </th>
                                        <th width="20%">
                                            <div className='sorting_column'>
                                                <span>{name}</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="name" />
                                            </div>
                                        </th>
                                        <th width="20%">
                                            <div className='sorting_column'>
                                                <span>{name + ' (Arabic)'}</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="name_ar" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>SKU </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="sku" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <span>Order Mode  </span>
                                        </th>
                                        <th className='status_head text-center' width="10%">Status</th>
                                        <th className='action_head text-end' width="10%">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={11}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => (
                                                <tr>
                                                    <td>{row?.id}</td>
                                                    <td>{capitalizeAllFirstLetters(row?.name)}</td>
                                                    <td>{capitalizeAllFirstLetters(row?.name_ar)}</td>
                                                    <td>{capitalizeAllFirstLetters(row?.sku)}</td>
                                                    {/* <td>{row?.order_mode_name ? capitalizeAllFirstLetters(row?.order_mode_name?.toString()) : ''}</td> */}
                                                    <td>
                                                        {row?.order_mode_name ? row?.order_mode_name?.map((item, index) => (
                                                            <span key={index}>{capitalizeAllFirstLetters(item?.toString())}
                                                                {index < row?.order_mode_name.length - 1 && ', '}
                                                            </span>
                                                        )) : ""}
                                                    </td>
                                                    <td>
                                                        <div className="form-check form-switch w-25 mx-auto">
                                                            <input className="form-check-input" type="checkbox" id="status-change" name="status" checked={row.status == 1 ? 'checked' : ''} onClick={() => { ChangeStatus(row.id, row.status == 1 ? 0 : 1) }} />
                                                            <label className="form-check-label" for="status-change"></label>
                                                        </div>
                                                    </td>
                                                    <td className='text-end'>
                                                        <div class="dropdown  d-inline-block">
                                                            <button class="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                            {checkPermission('store_settings', 'edit') ?
                                                                <li><div className='dropdown-item' onClick={() => { editFunction(row.id) }}><i class="ti ti-edit" aria-hidden="true"></i> Edit</div></li>
                                                                :''}
                                                            {checkPermission('store_settings', 'delete') ?
                                                                <li> <div className='dropdown-item' onClick={() => { deleteFunction(row.id) }}><i class="ti ti-trash" aria-hidden="true"></i> Delete</div></li>
                                                                :''}
                                                            {checkPermission('store_settings', 'add') ?
                                                                <li> <div className='dropdown-item' onClick={() => { copyFunction(row?.id) }}><i class="ti ti-copy" aria-hidden="true"></i> Copy Tender</div></li>
                                                                :''}
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr>
                                                <td colSpan={11} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No record found</p>
                                                </td>
                                            </tr>
                                            }</>}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "outlet-management", path: "/company/setting/tender-configuration/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>

                {/*  Modal*/}
                <div className="modal fade" id="addCardModal" aria-labelledby="addCardModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="modal-title lg" id="addCardModalLabel">{actionType === 'edit' ? 'Edit' : 'Add'} {name}</h2>
                                <button type="button" className="btn-close" onClick={(e) => closeModel('addCardModal')} data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <label for="name" className="col-form-label">{name}<span className='requirestar'>*</span></label>
                                        <input type="text" className="form-control" id="name" name="name" placeholder={'Enter ' + name} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} autoComplete='off' />
                                        <span className='text-danger d-flex text-left'>{formik.errors.name && formik.touched.name && formik.errors.name}</span>
                                    </div>
                                    <div className="mb-3">
                                        <label for="name_ar" className="col-form-label">{name + ' (Arabic)'}<span className='requirestar'>*</span></label>
                                        <input type="text" className="form-control" name="name_ar" id="name_ar" placeholder={'Enter ' + name + ' ' + '(Arabic)'} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name_ar} autoComplete='off' />
                                        <span className='text-danger d-flex text-left'>{formik.errors.name_ar && formik.touched.name_ar && formik.errors.name_ar}</span>
                                    </div>
                                    <div className="mb-3">
                                        <label for="sku" className="col-form-label">SKU<span className='requirestar'>*</span></label>
                                        <div className="set-plus-icon-input">
                                            <input type="text" name="sku" className="form-control" id="sku" placeholder='Enter SKU' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.sku} autoComplete='off' />
                                            <div className="icon-box">
                                                <a onClick={(e) => generateSKU(8)}><i className="fa fa-rotate-right"></i></a>
                                            </div>
                                        </div>
                                        <span className='text-danger d-flex text-left'>{formik.errors.sku && formik.touched.sku && formik.errors.sku}</span>
                                    </div>
                                    <div className="mb-3">
                                        <label for="order_mode" className="col-form-label">Order Mode<span className='requirestar'>*</span></label>
                                        {/* <Select
                                            isMulti
                                            options={orderModeList}
                                            value={formik.values.orderMode}
                                            onChange={handleChangeOrderMode}
                                            getOptionLabel={option => capitalizeAllFirstLetters(option.name)}
                                            getOptionValue={option => option?.id?.toString()}
                                            placeholder='Select order mode'
                                        /> */}
                                        {console.log("******", orderModeList.map(item => { return { label: item.name, value: item.id } }))}
                                        <CustomMultiSelect menuClassName='popup-select' data={orderModeList.map(item => { return { label: item.name, value: item.id } })} overFlowVisibleOptions={1} form={formik} name='orderMode' />
                                        <span className='text-danger d-flex text-left'>{formik.errors.orderMode && formik.touched.orderMode && formik.errors.orderMode}</span>
                                    </div>

                                    {tenderType === 'Card' ?
                                        <>
                                            <div className="mb-3">
                                                <label for="order_mode" className="col-form-label">Tax :</label>
                                                <div className='row ps-3'>
                                                    <div className="form-check w-25">
                                                        <input className="form-check-input" type="radio" name="tax" id="primary" value="primary" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} checked={formik.values.tax == 'primary' ? 'checked' : ''} />
                                                        <label className="form-check-label" for="primary">Primary</label>
                                                    </div>
                                                    <div className="form-check w-25">
                                                        <input className="form-check-input" type="radio" name="tax" id="secondary" value="secondary" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} checked={formik.values.tax == 'secondary' ? 'checked' : ''} />
                                                        <label className="form-check-label" for="secondary">Secondary</label>
                                                    </div>
                                                    <div className="form-check w-25">
                                                        <input className="form-check-input" type="radio" name="tax" id="overwrite" value="overwrite" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} checked={formik.values.tax == 'overwrite' ? 'checked' : ''} />
                                                        <label className="form-check-label" for="overwrite">Overwrite</label>
                                                    </div>
                                                    <div className="form-check w-25">
                                                        <input className="form-check-input" type="radio" name="tax" id="none" value="none" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} checked={formik.values.tax == 'none' ? 'checked' : ''} />
                                                        <label className="form-check-label" for="none">None</label>
                                                    </div>
                                                </div>
                                                <span className='text-danger d-flex text-left'>{formik.errors.tax && formik.touched.tax && formik.errors.tax}</span>
                                            </div>
                                            {formik.values.tax === 'overwrite' ?
                                                <div className="mb-3">
                                                    {/* <Dropdown
                                                        className='form-control'
                                                        id="tax_group"
                                                        placeholder='Select Tax group'
                                                        search
                                                        fluid
                                                        selection
                                                        options={taxGroupList}
                                                        value={formik.values.tax_group}
                                                        onChange={(event, data) => { formik.setFieldValue("tax_group", data.value) }}
                                                    /> */}
                                                    <select id="tax_group" className='form-control' name='tax_group' onChange={(e) => { formik.handleChange(e); setIsOverwrite(!isOverwrite) }} onBlur={formik.handleBlur} value={formik?.values?.tax_group}>
                                                        <option value="">Select Tax group</option>
                                                        {taxGroupList?.length ? taxGroupList.map((row) => (
                                                            <option value={row.value}>{capitalizeAllFirstLetters(row?.text)}</option>
                                                        )) : ''}
                                                    </select>
                                                    <span className='text-danger d-flex text-left'>{formik.errors.tax_group && formik.touched.tax_group && formik.errors.tax_group}</span>
                                                </div>
                                                : ''}
                                        </>
                                        : ''}

                                    <div className="form-check form-switch w-25">
                                        <input className="form-check-input" type="checkbox" id="status" name="status" onChange={formik.handleChange} onBlur={formik.handleBlur} checked={formik.values.status == 'on' ? 'checked' : ''} autoComplete='off' />
                                        <label className="form-check-label" for="status">Status</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-primary" style={{ width: '48%' }} data-bs-dismiss="modal" onClick={(e) => closeModel('addCardModal')}>Cancel</button>
                                    <button type="submit" className="btn btn-primary" style={{ width: '48%' }}>{actionType === 'edit' ? 'Update' : 'Add'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={true | 1000}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />

            </div>
        </>
    );
}

export default TenderList