import React, { useEffect, useMemo, useState } from 'react'
import { Field, useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../../components/company_panel/common/loader';
import Breadcrums from '../../../components/company_panel/common/breadcrumbs';
import { PromoDetails } from '../../../services/company_panel/coupons.service';
import { SpecialCharacter, capitalizeAllFirstLetters, convertTo12Hour, formateDate, getBrandId } from '../../../helpers/commonFunctions';

const PromosView = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const breadcrumbs = [{ title: "Coupons", url: "/company/crm/coupons/coupon/list/1" }, { title: "Promos", url: "/company/crm/coupons/promos/list/1" }, {
        title: "Promo Details",
        url: ""
    },]
    const [isBrandChanged, setIsBrandChanged] = useState(false);
  const [currentBrand] = useState(getBrandId());

  useMemo(() => {
    document.addEventListener('brand-changed', function () {
      setIsBrandChanged(!isBrandChanged);
    });
    if (currentBrand != getBrandId()) {
      navigate(`/company/crm/coupons/promos/list/${1}`);
    }
  });

    useEffect(() => {
        PromoDetails({ id: params.id }).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    const showData = (data) => {
        return (
            <div className='d-flex flex-wrap'>
                {data?.map((option, i) => {
                    return (
                        <span key={i} className="me-1 badge  btn-primary d-inline-flex align-items-center justify-content-center text-capitalize">{capitalizeAllFirstLetters(SpecialCharacter(option))}</span>
                    )
                })}
            </div>
        )
    }

    const showGetData = (item) => {
        return (
            <div className='d-flex flex-wrap'>
                {item?.map((option, i) => {
                    return (
                        <span key={i} className="me-1 badge  btn-primary d-inline-flex align-items-center justify-content-center text-capitalize">{capitalizeAllFirstLetters(SpecialCharacter(option.name))}</span>
                    )
                })}
            </div>
        )
    }

    const showBuyData = (data1) => {
        return (
            <div className='d-flex flex-wrap'>
                {data1?.map((option, i) => {
                    return (
                        <span key={i} className="me-1 badge  btn-primary d-inline-flex align-items-center justify-content-center text-capitalize">{capitalizeAllFirstLetters(SpecialCharacter(option.name))}</span>
                    )
                })}
            </div>
        )
    }
    const showWeek = (data1) => {
        return (
            <div className='d-flex flex-wrap'>
                {data1?.map((option, i) => {
                    return (
                        <span key={i} className="me-1 badge  btn-primary d-inline-flex align-items-center justify-content-center text-capitalize">{capitalizeAllFirstLetters(SpecialCharacter(option))}</span>
                    )
                })}
            </div>
        )
    }


    return (
        <>
            <div class="page-header d-flex align-items-center">
                <div class="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Promo</h4>
                </div>
            </div>
            <div className="card custom-card">
                {showDefault && Object.keys(showDefault).length > 0 ?
                    <div className="card-body p-0">
                        <div className="col-lg-12 form-group">
                            <table id="simple-table" className="table table-custom">
                                <tbody>
                                    <tr >
                                        <td>Name</td>
                                        <td className='text-end'>{showDefault && showDefault.name ? capitalizeAllFirstLetters(showDefault.name) : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td>Promo Code</td>
                                        <td className='text-end'>{showDefault && showDefault.promo_code ? capitalizeAllFirstLetters(showDefault.promo_code) : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td>Buy</td>
                                        <td className='text-end'>{showDefault && showDefault.buy ? showDefault.buy : "N/A"}</td>
                                    </tr>
                                    {/* <tr>
                                        <td>Buy Items</td>
                                        <td>{showDefault && showDefault.buy_items ? showBuyData(showDefault.buy_items) : "N/A"}</td>
                                    </tr> */}
                                    <tr>
                                        <td>Get</td>
                                        <td className='text-end'>{showDefault && showDefault.get ? showDefault.get : "N/A"}</td>
                                    </tr>
                                    {/* <tr>
                                        <td>Get Items</td>
                                        <td>{showDefault && showDefault.get_items ? showGetData(showDefault.get_items) : "N/A"}</td>
                                    </tr> */}
                                    <tr>
                                        <td>Offer Type</td>
                                        <td className='text-end'>{showDefault && showDefault.offer_type ? capitalizeAllFirstLetters(SpecialCharacter(showDefault.offer_type)) : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td>Offer Amount</td>
                                        <td className='text-end'>{showDefault && showDefault.offer_amount ? (showDefault.offer_amount) : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td>Discount Percentage</td>
                                        <td className='text-end'>{showDefault && showDefault.discount_percentage ? (showDefault.discount_percentage + " " + "%") : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td>Discount Item Restriction</td>
                                        <td className='text-end'>{showDefault && showDefault.discount_item_restriction ? capitalizeAllFirstLetters(SpecialCharacter(showDefault.discount_item_restriction)) : "N/A"}</td>
                                    </tr>
                                    {/* <tr>
                                        <td>Applicable On</td>
                                        <td>{showDefault && showDefault.applicable_on ? showData(showDefault.applicable_on) : "N/A"}</td>
                                    </tr> */}
                                    <tr>
                                        <td>Usage Condition</td>
                                        <td className='text-end'>{showDefault && showDefault.usage_condition ? capitalizeAllFirstLetters(SpecialCharacter(showDefault.usage_condition)) : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td>Usage Condition Number</td>
                                        <td className='text-end'>{showDefault && showDefault.usage_condition_number ? (showDefault.usage_condition_number) : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td>Usage Per Customer</td>
                                        <td className='text-end'> {showDefault && showDefault.usage_condition ? capitalizeAllFirstLetters(SpecialCharacter(showDefault.usage_per_customer)) : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td>Usage Per Customer Number</td>
                                        <td className='text-end'>{showDefault && showDefault.usage_per_customer_number ? (showDefault.usage_per_customer_number) : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td>Customer Type</td>
                                        <td className='text-end'>{showDefault && showDefault.customer_type ? capitalizeAllFirstLetters(showDefault.customer_type) : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td>Authentication Type </td>
                                        <td className='text-end'>{showDefault && showDefault.authentication_type ? SpecialCharacter(capitalizeAllFirstLetters(showDefault.authentication_type)) : "N/A"}</td>
                                    </tr>
                                    {/* <tr>
                                        <td>Autdentication User </td>
                                        <td>{showDefault && showDefault.autdenticate_user ? (capitalizeAllFirstLetters(showDefault.autdenticate_user)) : "N/A"}</td>
                                    </tr> */}
                                    <tr>
                                        <td>Created Date </td>
                                        <td className='text-end'>{showDefault && showDefault.created_at ? formateDate(showDefault.created_at) : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td>Start Date </td>
                                        <td className='text-end'>{showDefault && showDefault.start_date ? (formateDate(showDefault.start_date)) : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td>End Date </td>
                                        <td className='text-end'>{showDefault && showDefault.end_date ? (formateDate(showDefault.end_date)) : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td>Start Time </td>
                                        <td className='text-end'>{showDefault && showDefault.start_time ? (convertTo12Hour(showDefault.start_time)) : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td>End Time </td>
                                        <td className='text-end'>{showDefault && showDefault.end_time ? (convertTo12Hour(showDefault.end_time)) : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td>Week </td>
                                        <td className='d-flex justify-content-end'>{showDefault && showDefault.week ? showWeek((showDefault.week)) : "N/A"}</td>
                                    </tr>
                                </tbody>
                                <button className="btn btn-primary m-4" onClick={() => navigate(`/company/crm/promos/${params.id}/edit/${params.id}`)}>Update Promo</button>
                            </table>
                        </div>
                    </div> : <Loader />}
            </div>
        </>
    )
}

export default PromosView