import './App.css';
import Login from './components/public/login';
import { Navigate, createBrowserRouter } from "react-router-dom";
import AdminPrivateRoute from './utils/adminPrivateRoute';
import PublicRoute from './utils/publicRoute';
import AdminPublicRoute from './utils/adminPublicRoute';
import CompanyPrivateRoute from './utils/companyPrivateRoute';
import CompanyPublicRoute from './utils/companyPublicRoute';
import ForgotPwdPage from './components/public/forgot';
import OtpVerificationPage from './admin/otpverification';
import ResetPwdPage from './components/public/resetpwd';
import Changepassword from './components/private/changepassword';
import Dashboard from './admin/dashboard';
import Profile from './admin/profile';
import Error404 from './admin/404';
import Error401 from './admin/401';
import CompanyTable from './admin/company/companytable';
import CompanyForm from './admin/company/companyform';
import ViewCompany from './admin/company/viewcompany';
import EditCompany from './admin/company/editcompany';

import BrandTable from './admin/brand/brandtable';
import BrandForm from './admin/brand/brandform';
import ViewBrand from './admin/brand/viewbrand';
import EditBrand from './admin/brand/editbrand';

import OutletManagementTable from './admin/outlet-management/outletmanagementtable';
import OutletManagementForm from './admin/outlet-management/outletmanagementform';
import ViewOutletManagement from './admin/outlet-management/viewoutletmanagement';
import EditOutletManagement from './admin/outlet-management/editoutletmanagement';

import EmailTemplatesTable from './admin/emailtemplates/emailtemptable';
import EmailTempEdit from './admin/emailtemplates/emailtempedit';
import EmailTempAdd from './admin/emailtemplates/emailTempAdd';
import ViewEmailTemp from './admin/emailtemplates/viewemailtemp';

import GlobalSettings from './admin/settings/settings';

import CompanyRequestTable from './admin/company-request/companyrequesttable';
import ViewCompanyRequest from './admin/company-request/viewcompanyrequest';

import PlatformUsersTable from './admin/platform-users/list';

import CompanyAdd from './company_panel/company/add';
import HomePage from './web/homepage';
import TermsAndConditions from './web/terms_and_conditions';
import PrivacyPolicy from './web/privacy_policy';
import CompanyLogin from './company_panel/auth/companylogin';
import CompanyOtpVerification from './company_panel/auth/companyotpverification';
import brandListShow from './company_panel/auth/brandList';
import CompanyDashboard from './company_panel/dashboard';
import CompanyForgotPwdPage from './company_panel/auth/companypasswordforgot';
import CompanyResetPwdPage from './company_panel/auth/companyresetpwd';
import CompanyChangePassword from './company_panel/auth/companychangepassword';
import ViewCompanyProfile from './company_panel/company/view';
import EditCompanyProfile from './company_panel/company/edit';

import ViewProfile from './company_panel/profile/view';
import EditProfile from './company_panel/profile/edit';


import BrandList from './company_panel/brand/list';
import BrandAdd from './company_panel/brand/add';
import BrandView from './company_panel/brand/view';
import BrandEdit from './company_panel/brand/edit';

import SupplierUserList from './company_panel/supplier_manage/list';
// import BrandAdd from './company_panel/brand/add';
// import BrandView from './company_panel/brand/view';
// import BrandEdit from './company_panel/brand/edit';



import OutletList from './company_panel/outlet/list';
import OutletAdd from './company_panel/outlet/add';
import OutletView from './company_panel/outlet/view';
import OutletEdit from './company_panel/outlet/edit';

import TenderConfiguration from './company_panel/setting/tender_configuration/list';
import OrderModeConfiguration from './company_panel/setting/order_mode_configuration/list';
import AggregatorConfiguration from './company_panel/setting/aggregator_configuration/list';
import CurrencyConfiguration from './company_panel/setting/currency_configuration/list';
import TipConfiguration from './company_panel/setting/tip_configuration/list';
import TaxConfiguration from './company_panel/setting/tax_configuration/list';


import OutletUser from './company_panel/platform_user/outlet_user/list';
import OutletUserAdd from './company_panel/platform_user/outlet_user/add';
import OutletUserView from './company_panel/platform_user/outlet_user/view';
import OutletUserEdit from './company_panel/platform_user/outlet_user/edit';
import role from './company_panel/platform_user/role/list';
import roleAdd from './company_panel/platform_user/role/add';
import roleEdit from './company_panel/platform_user/role/edit';

import ViewPlatformUser from './admin/platform-users/view';
import ListSubscription from './company_panel/subscription/ListSubscription';
import SubscriptionTable from './admin/subscription/list';
import ViewSubscription from './admin/subscription/viewSubscription';
import SubscriptionEdit from './admin/subscription/editSubscription';
import DevicesTable from './admin/devices-management/list';
import ViewDevice from './admin/devices-management/view';
import { editDevice } from './services/devicesService';
import EditDevice from './admin/devices-management/edit';
import AddDevice from './admin/devices-management/add';
import CompanySubscription from './admin/company/ViewSubscription';
import OutletHardwares from './admin/company/OutletHardwares';
import ViewOutletHardware from './admin/company/ViewOutletHardware';
import CompanyLayout from './companyLayout';
import SampleSocket from './admin/socket/sample-socket';
import SampleSocketCompany from './company_panel/socket/sample-socket';
import CategoryList from './company_panel/category/list';
import CategoryAdd from './company_panel/category/add';
import CategoryView from './company_panel/category/view';
import CategoryEdit from './company_panel/category/edit';
import InventoryList from './company_panel/inventory/list';
import InventoryAdd from './company_panel/inventory/add';
import InventoryView from './company_panel/inventory/view';
import InventoryEdit from './company_panel/inventory/edit';
import SupplierAdd from './company_panel/supplier_manage/add';
import SupplierEdit from './company_panel/supplier_manage/edit';
import SupplierView from './company_panel/supplier_manage/view';
import TransferOutList from './company_panel/transfer_stock/transfer_out/list';
import TransferInList from './company_panel/transfer_stock/transfer_in/list';
import TransferOutAdd from './company_panel/transfer_stock/transfer_out/add';
import OrderList from './company_panel/purchase_order/list';
import OrderAdd from './company_panel/purchase_order/add';
import OrderView from './company_panel/purchase_order/view';
import OrderEdit from './company_panel/purchase_order/edit';
import MenuCategoryList from './company_panel/menu/categories/list';
import MenuItemList from './company_panel/menu/menu_items/list';
import AreaList from './company_panel/setting/add_area/list';
import SubCategoryList from './company_panel/sub_category/list';
import SubCategoryAdd from './company_panel/sub_category/add';
import SubCategoryView from './company_panel/sub_category/view';
import SubCategoryEdit from './company_panel/sub_category/edit';
import CouponsList from './company_panel/Coupons/list';
import CouponAdd from './company_panel/Coupons/add';
import CouponsView from './company_panel/Coupons/view';
import CouponEdit from './company_panel/Coupons/edit';
import UOMList from './company_panel/setting/uom/list';
import RoutingGroups from './company_panel/routing_station/groups/list';
import RoutingStation from './company_panel/routing_station/stations/list';
import PromosList from './company_panel/Coupons/promos/list';
import PromosAdd from './company_panel/Coupons/promos/add';
import PromosView from './company_panel/Coupons/promos/view';
import PromosEdit from './company_panel/Coupons/promos/edit';
import AddOnsList from './company_panel/menu/add_ons/list';
import AddOnsAdd from './company_panel/menu/add_ons/add';
import AddOnsView from './company_panel/menu/add_ons/view';
import AddOnsEdit from './company_panel/menu/add_ons/edit';
import MenuList from './company_panel/menu/menu_card/list';
import CreateMenu from './company_panel/menu/menu_card/addMenu';
import MenuItemsAdd from './company_panel/menu/menu_items/add';
import LoyaltyPointsList from './company_panel/LoyaltyPoints/list';
import RefundList from './company_panel/Refund/list';
import TransactionList from './company_panel/Transcation/list';
import KitchenInvoiceSetting from './company_panel/setting/kitchen_invoice/invoiceSetting';
import MenuItemsEdit from './company_panel/menu/menu_items/edit';
import MenuItemsView from './company_panel/menu/menu_items/view';
import CustomerInvoiceSetting from './company_panel/setting/customer_invoice/invoiceSetting';
import AddLoyaltyPoints from './company_panel/LoyaltyPoints/add';
import LoyaltyPointView from './company_panel/LoyaltyPoints/view';
import CreateMealsCombos from './company_panel/menu/meals_combos/add';
import EditMenu from './company_panel/menu/menu_card/edit';
import MealsAndCombosList from './company_panel/menu/meals_combos/list';
import EditMealsCombos from './company_panel/menu/meals_combos/edit';
import ViewMealsAndCombos from './company_panel/menu/meals_combos/view';
import AssignItem from './company_panel/routing_station/groups/assignItem';
import AssignGroups from './company_panel/routing_station/stations/assignGroup';
import StationFlow from './company_panel/routing_station/stations/stationFlow';
import RefundOrder from './company_panel/Order/list';
import ResetPasswordGuard from './utils/ResetPasswordGuard';
import WasteManagementView from './company_panel/wasteManagement/view';
import WasteManagementList from './company_panel/wasteManagement/list';
import AddWaste from './company_panel/wasteManagement/add';
import EditWaste from './company_panel/wasteManagement/edit';
import RefundOrderView from './company_panel/Order/view';
import { RefundOrderAdd } from './company_panel/Order/add';
import { ContinueOrderAdd } from './company_panel/Order/continueOrder';
import CustomerList from './company_panel/customer/list';
import CustomerView from './company_panel/customer/view';
import RefundConfiguration from './company_panel/Refund/configuration';
import RefundReasons from './company_panel/Refund/reasons';
import WasteConfiguration from './company_panel/setting/waste_configuration/waste_config';
import OrderModeTypes from './company_panel/Order/OrdeMode';
import SubscriptionHistory from './admin/subscriptionHistory/lits';
import WasteManagementReasons from './company_panel/wasteManagement/reasons';
import TransactionView from './company_panel/Transcation/view';
import WasteManagementReasonsView from './company_panel/wasteManagement/reasonsView';
import RefundView from './company_panel/Refund/view';
import RefundReasonsView from './company_panel/Refund/reasonView';
import WeeklyList from './company_panel/inventory/InventoryEndOfDay/weekly';
import DailyList from './company_panel/inventory/InventoryEndOfDay/daily';
import BiWeeklyList from './company_panel/inventory/InventoryEndOfDay/biWeekly';
import MonthlyList from './company_panel/inventory/InventoryEndOfDay/monthly';
import SalesReportList from './company_panel/Report/SalesReportList';
import CustomerReportList from './company_panel/Report/CustomerReportList';
import PrimaryCategoryReportList from './company_panel/Report/PrimaryCategoryReportList';
import InventoryReportList from './company_panel/Report/InventoryReportList';
import PaymentReportList from './company_panel/Report/PaymentReportList';
import ItemCostList from './company_panel/Report/ItemCostList';


const router = createBrowserRouter([

  { path: "*", element: <Error404 /> },
  { path: "/401error", element: <Error401 /> },
  { path: "/", element: <PublicRoute component={HomePage} /> },
  { path: "/company-request", element: <PublicRoute component={CompanyAdd} /> },
  { path: "/term-condition", element: <PublicRoute component={TermsAndConditions} /> },
  { path: "/privacy-policy", element: <PublicRoute component={PrivacyPolicy} /> },


  /**ADMIN ROUTE START */
  {
    path: "admin",
    children: [
      { path: "", element: <Navigate to="/admin/login" /> },
      { path: "login", element: <AdminPublicRoute component={Login} /> },
      { path: "forgot-password", element: <AdminPublicRoute component={ForgotPwdPage} /> },
      { path: "forgot/otpverification/:email", element: <AdminPublicRoute component={OtpVerificationPage} /> },
      { path: "otpverification/:email", element: <AdminPublicRoute component={OtpVerificationPage} /> },
      { path: "resetpassword", element: <AdminPublicRoute component={ResetPwdPage} /> },
      { path: "changepassword", element: <AdminPrivateRoute component={Changepassword} /> },
      { path: "dashboard", element: <AdminPrivateRoute component={Dashboard} /> },
      { path: "profile", element: <AdminPrivateRoute component={Profile} /> },

      { path: "company/list/:id", element: <AdminPrivateRoute component={CompanyTable} /> },
      { path: "company/add", element: <AdminPrivateRoute component={CompanyForm} /> },

      { path: "company/view/:id", element: <AdminPrivateRoute component={ViewCompany} /> },
      { path: "company/view/subscription-history/list/:id/:pgno", element: <AdminPrivateRoute component={SubscriptionHistory} /> },
      { path: "company/brands/list/:id", element: <AdminPrivateRoute component={BrandTable} /> },
      { path: "company/outlets/list/:id", element: <AdminPrivateRoute component={OutletManagementTable} /> },
      { path: "company/subscription/:id", element: <AdminPrivateRoute component={CompanySubscription} /> },
      { path: "company/outlet-hardwares/:id", element: <AdminPrivateRoute component={OutletHardwares} /> },
      { path: "outlet-hardwares/view/:id", element: <AdminPrivateRoute component={ViewOutletHardware} /> },

      // {
      //   path: "company/view/:id",
      //   element: <AdminPrivateRoute component={ViewCompany} />,
      //   children: [
      //     {
      //       path: "company/brands/list/:id",
      //       element: <AdminPrivateRoute component={BrandTable}/>,
      //     },
      //     {
      //       path: "company/outlets/list/:id",
      //       element: <AdminPrivateRoute component={OutletManagementTable}/>,
      //     },
      //   ],
      // },

      { path: "company/:pgno/edit/:id", element: <AdminPrivateRoute component={EditCompany} /> },


      { path: "brand/list/:id", element: <AdminPrivateRoute component={BrandTable} /> },
      { path: "brand/add", element: <AdminPrivateRoute component={BrandForm} /> },
      { path: "brand/view/:id", element: <AdminPrivateRoute component={ViewBrand} /> },
      { path: "brand/:pgno/edit/:id", element: <AdminPrivateRoute component={EditBrand} /> },


      { path: "outlet-management/list/:id", element: <AdminPrivateRoute component={OutletManagementTable} /> },
      { path: "outlet-management/add", element: <AdminPrivateRoute component={OutletManagementForm} /> },
      { path: "outlet-management/view/:id", element: <AdminPrivateRoute component={ViewOutletManagement} /> },
      { path: "outlet-management/:pgno/edit/:id", element: <AdminPrivateRoute component={EditOutletManagement} /> },


      { path: "email-templates/:id", element: <AdminPrivateRoute component={EmailTemplatesTable} /> },
      { path: "email-templates/add", element: <AdminPrivateRoute component={EmailTempAdd} /> },
      { path: "email-templates/view/:id", element: <AdminPrivateRoute component={ViewEmailTemp} /> },
      { path: "email-templates/:pgno/edit/:id", element: <AdminPrivateRoute component={EmailTempEdit} /> },

      { path: "settings", element: <AdminPrivateRoute component={GlobalSettings} /> },

      { path: "company-request/list/:id", element: <AdminPrivateRoute component={CompanyRequestTable} /> },
      { path: "company-request/view/:id", element: <AdminPrivateRoute component={ViewCompanyRequest} /> },

      { path: "platform-users/list/:id", element: <AdminPrivateRoute component={PlatformUsersTable} /> },
      { path: "platform-users/view/:id", element: <AdminPrivateRoute component={ViewPlatformUser} /> },

      { path: "subscription", element: <AdminPrivateRoute component={SubscriptionTable} /> },
      { path: "subscription/view/:id", element: <AdminPrivateRoute component={ViewSubscription} /> },
      { path: "subscription/edit/:id", element: <AdminPrivateRoute component={SubscriptionEdit} /> },

      { path: "devices-management/list/:id", element: <AdminPrivateRoute component={DevicesTable} /> },
      { path: "devices-management/view/:id", element: <AdminPrivateRoute component={ViewDevice} /> },
      { path: "devices-management/edit/:id", element: <AdminPrivateRoute component={EditDevice} /> },
      { path: "devices-management/add", element: <AdminPrivateRoute component={AddDevice} /> },

      { path: "socket", element: <AdminPrivateRoute component={SampleSocket} /> },
    ]
  },
  /**ADMIN ROUTE END */
  /**company ROUTE START */
  {
    path: "company",
    children: [
      { path: "", element: <Navigate to="/company/login" /> },
      { path: "login", element: <CompanyPublicRoute component={CompanyLogin} /> },
      { path: "otpverification/:email", element: <CompanyPublicRoute component={CompanyOtpVerification} /> },
      { path: "brand", element: <CompanyPublicRoute component={brandListShow} /> },
      { path: "forgot-password", element: <CompanyPublicRoute component={CompanyForgotPwdPage} /> },
      { path: "forgot/otpverification/:email", element: <CompanyPublicRoute component={CompanyOtpVerification} /> },
      { path: "resetpassword", element: <ResetPasswordGuard component={CompanyResetPwdPage} /> },
    ]
  },
  {
    path: "company",
    element: <CompanyLayout />,
    children: [
      { path: "dashboard", element: <CompanyPrivateRoute component={CompanyDashboard} /> },
      { path: "changepassword", element: <CompanyPrivateRoute component={CompanyChangePassword} /> },
      { path: "company-profile", element: <CompanyPrivateRoute component={ViewCompanyProfile} /> },
      { path: "company-profile/edit", element: <CompanyPrivateRoute component={EditCompanyProfile} /> },
      { path: "profile", element: <CompanyPrivateRoute component={ViewProfile} /> },
      { path: "profile/edit", element: <CompanyPrivateRoute component={EditProfile} /> },

      /** Company Business Routes  Start */
      {
        path: "business", children: [
          { path: "brand/list/:id", element: <CompanyPrivateRoute component={BrandList} moduleName='store_brands' permissionName='view' /> },
          { path: "brand/add", element: <CompanyPrivateRoute component={BrandAdd} moduleName='store_brands' permissionName='add' /> },
          { path: "brand/view/:id", element: <CompanyPrivateRoute component={BrandView} moduleName='store_brands' permissionName='view' /> },
          { path: "brand/:pgno/edit/:id", element: <CompanyPrivateRoute component={BrandEdit} moduleName='store_brands' permissionName='edit' /> },

          { path: "outlet/list/:id", element: <CompanyPrivateRoute component={OutletList} moduleName='store_outlets' permissionName='view' /> },
          { path: "outlet/add", element: <CompanyPrivateRoute component={OutletAdd} moduleName='store_outlets' permissionName='add' /> },
          { path: "outlet/view/:id", element: <CompanyPrivateRoute component={OutletView} moduleName='store_outlets' permissionName='view' /> },
          { path: "outlet/:pgno/edit/:id", element: <CompanyPrivateRoute component={OutletEdit} moduleName='store_outlets' permissionName='edit' /> },


          { path: "subscription", element: <CompanyPrivateRoute component={ListSubscription} moduleName='store_subscription' permissionName='view' /> },

          { path: "platform-user/outlet-user/list/:id", element: <CompanyPrivateRoute component={OutletUser} moduleName='store_platform_users' permissionName='view' /> },
          { path: "platform-user/outlet-user/add", element: <CompanyPrivateRoute component={OutletUserAdd} moduleName='store_platform_users' permissionName='add' /> },
          { path: "platform-user/outlet-user/view/:id", element: <CompanyPrivateRoute component={OutletUserView} moduleName='store_platform_users' permissionName='view' /> },
          { path: "platform-user/outlet-user/:pgno/edit/:id", element: <CompanyPrivateRoute component={OutletUserEdit} moduleName='store_platform_users' permissionName='edit' /> },

          { path: "platform-user/role/list/:id", element: <CompanyPrivateRoute component={role} moduleName='store_roles' permissionName='view' />  },
          { path: "platform-user/role/add", element: <CompanyPrivateRoute component={roleAdd} moduleName='store_roles' permissionName='add' /> },
          { path: "platform-user/role/edit/:id", element: <CompanyPrivateRoute component={roleEdit} moduleName='store_roles' permissionName='edit'  /> },
        ]
      },
      /** Company Business Routes End */

      /** Company Raw Materials Routes Start  */
      {
        path: "raw_materials", children: [
          { path: "supplier_manage/list/:id", element: <CompanyPrivateRoute component={SupplierUserList} moduleName='store_suppliers' permissionName='view' /> },
          { path: "supplier_manage/add", element: <CompanyPrivateRoute component={SupplierAdd} moduleName='store_suppliers' permissionName='add' /> },
          { path: "supplier_manage/view/:id", element: <CompanyPrivateRoute component={SupplierView} moduleName='store_suppliers' permissionName='view' /> },
          { path: "supplier_manage/:pgno/edit/:id", element: <CompanyPrivateRoute component={SupplierEdit} moduleName='store_suppliers' permissionName='edit' /> },

          { path: "category/list/:id", element: <CompanyPrivateRoute component={CategoryList} moduleName='store_inventory_category' permissionName='view' /> },
          { path: "category/add", element: <CompanyPrivateRoute component={CategoryAdd} moduleName='store_inventory_category' permissionName='add' /> },
          { path: "category/view/:id", element: <CompanyPrivateRoute component={CategoryView} moduleName='store_inventory_category' permissionName='view' /> },
          { path: "category/:pgno/edit/:id", element: <CompanyPrivateRoute component={CategoryEdit} moduleName='store_inventory_category' permissionName='edit' /> },

          { path: "sub_category/list/:id", element: <CompanyPrivateRoute component={SubCategoryList} moduleName='store_inventory_subcategory' permissionName='view' /> },
          { path: "sub_category/add", element: <CompanyPrivateRoute component={SubCategoryAdd}  moduleName='store_inventory_subcategory' permissionName='add'/> },
          { path: "sub_category/view/:id", element: <CompanyPrivateRoute component={SubCategoryView} moduleName='store_inventory_subcategory' permissionName='view' /> },
          { path: "sub_category/:pgno/edit/:id", element: <CompanyPrivateRoute component={SubCategoryEdit} moduleName='store_inventory_subcategory' permissionName='edit' /> },

          { path: "inventory/list/:id", element: <CompanyPrivateRoute component={InventoryList} moduleName='store_inventories' permissionName='view' /> },
          { path: "inventory/add", element: <CompanyPrivateRoute component={InventoryAdd} moduleName='store_inventories' permissionName='add' /> },
          { path: "inventory/view/:id", element: <CompanyPrivateRoute component={InventoryView} moduleName='store_inventories' permissionName='view' /> },
          { path: "inventory/:pgno/edit/:id", element: <CompanyPrivateRoute component={InventoryEdit} moduleName='store_inventories' permissionName='edit' /> },
          { path: "inventory/end_of_day/daily/list/:id", element: <CompanyPrivateRoute component={DailyList} moduleName='inventories' /> },
          { path: "inventory/end_of_day/weekly/list/:id", element: <CompanyPrivateRoute component={WeeklyList} moduleName='inventories' /> },
          { path: "inventory/end_of_day/bi_weekly/list/:id", element: <CompanyPrivateRoute component={BiWeeklyList} moduleName='inventories' /> },
          { path: "inventory/end_of_day/monthly/list/:id", element: <CompanyPrivateRoute component={MonthlyList} moduleName='inventories' /> },
        ]
      },
      /** Company Raw Materials Routes End  */

      /** Company Menu Routes Start  */
      {
        path: "menu", children: [
          { path: "routing-station/groups/list/:id", element: <CompanyPrivateRoute component={RoutingGroups} moduleName='store_routing_groups' permissionName='view' /> },
          { path: "routing-station/groups/assign-item/:id", element: <CompanyPrivateRoute component={AssignItem} /> },
          { path: "routing-station/stations/list/:id", element: <CompanyPrivateRoute component={RoutingStation} moduleName='store_inventories' permissionName='view' /> },
          { path: "routing-station/stations/assign-groups/:id", element: <CompanyPrivateRoute component={AssignGroups} /> },
          { path: "routing-station/stations/station-flow/:id", element: <CompanyPrivateRoute component={StationFlow} /> },

          { path: "menu/categories/list/:id", element: <CompanyPrivateRoute component={MenuCategoryList} moduleName='store_menu_category' permissionName='view'/> },
          { path: "menu/menu_items/list/:id", element: <CompanyPrivateRoute component={MenuItemList} moduleName='store_menu_items' permissionName='view' /> },
          { path: "menu/menu_items/add", element: <CompanyPrivateRoute component={MenuItemsAdd} moduleName='store_menu_items' permissionName='add' /> },
          { path: "menu/menu_items/edit/:id", element: <CompanyPrivateRoute component={MenuItemsEdit} moduleName='store_menu_items' permissionName='edit' /> },
          { path: "menu/menu_items/view/:id", element: <CompanyPrivateRoute component={MenuItemsView} moduleName='store_menu_items' permissionName='view' /> },
          { path: "menu/menu_card/list/:id", element: <CompanyPrivateRoute component={MenuList} moduleName='store_menus' permissionName='view' /> },
          { path: "menu/menu_card/addMenu", element: <CompanyPrivateRoute component={CreateMenu} moduleName='store_menus' permissionName='add' /> },
          { path: "menu/menu_card/edit/:id", element: <CompanyPrivateRoute component={EditMenu} moduleName='store_menus' permissionName='edit' /> },
          { path: "menu/add_ons/list/:id", element: <CompanyPrivateRoute component={AddOnsList} moduleName='store_menu_addons' permissionName='view' /> },
          { path: "menu/add_ons/add", element: <CompanyPrivateRoute component={AddOnsAdd} moduleName='store_menu_addons' permissionName='add' /> },
          { path: "menu/add_ons/view/:id", element: <CompanyPrivateRoute component={AddOnsView} moduleName='store_menu_addons' permissionName='view' /> },
          { path: "menu/add_ons/edit/:id", element: <CompanyPrivateRoute component={AddOnsEdit} moduleName='store_menu_addons' permissionName='edit' /> },
          { path: "menu/meals_combos/list/:id", element: <CompanyPrivateRoute component={MealsAndCombosList}   moduleName='store_menu_meal_combos' permissionName='view'/> },
          { path: "menu/meals_combos/add", element: <CompanyPrivateRoute component={CreateMealsCombos}  moduleName='store_menu_meal_combos' permissionName='add' /> },
          { path: "menu/meals_combos/:pgno/edit/:id", element: <CompanyPrivateRoute component={EditMealsCombos}  moduleName='store_menu_meal_combos' permissionName='edit' /> },
          { path: "menu/meals_combos/view/:id", element: <CompanyPrivateRoute component={ViewMealsAndCombos}  moduleName='store_menu_meal_combos' permissionName='view' /> },
        ]
      },
      /** Company Menu Routes End  */

      /** Company Store Manager Routes Start  */
      {
        path: "store_manager", children: [
          { path: "transfer_stock/transfer_out/list/:id", element: <CompanyPrivateRoute component={TransferOutList} moduleName='store_transfer_out' permissionName='view' /> },
          { path: "transfer_stock/transfer_out/add", element: <CompanyPrivateRoute component={TransferOutAdd} moduleName='store_transfer_out' permissionName='add' /> },

          { path: "transfer_stock/transfer_in/list/:id", element: <CompanyPrivateRoute component={TransferInList} moduleName='store_transfer_in' permissionName='view' /> },

          { path: "purchase_order/list/:id", element: <CompanyPrivateRoute component={OrderList} moduleName='store_purchase_order' permissionName='view' /> },
          { path: "purchase_order/add", element: <CompanyPrivateRoute component={OrderAdd} moduleName='store_purchase_order' permissionName='add' /> },
          { path: "purchase_order/view/:id", element: <CompanyPrivateRoute component={OrderView} moduleName='store_purchase_order' permissionName='view' /> },
          { path: "purchase_order/:pgno/edit/:id", element: <CompanyPrivateRoute component={OrderEdit} moduleName='store_purchase_order' permissionName='edit' /> },

          { path: "waste_management/list/:id", element: <CompanyPrivateRoute component={WasteManagementList} moduleName='store_waste_management' permissionName='view' /> },
          { path: "waste_management/add", element: <CompanyPrivateRoute component={AddWaste} moduleName='store_waste_management' permissionName='add' /> },
          { path: "waste_management/view/:id", element: <CompanyPrivateRoute component={WasteManagementView} moduleName='store_waste_management' permissionName='view' /> },
          { path: "waste_management/:pgno/edit/:id", element: <CompanyPrivateRoute component={EditWaste} moduleName='store_waste_management' permissionName='edit' /> },
          { path: "waste_management/reasons/list/:id", element: <CompanyPrivateRoute component={WasteManagementReasons}  /> },
          { path: "waste_management/reasons/view/:id", element: <CompanyPrivateRoute component={WasteManagementReasonsView}  /> },
        ]
      },
      /** Company Store Manager Routes End  */


      /** Company CRM Routes Start  */
      {
        path: "crm", children: [
          { path: "coupons/coupon/list/:id", element: <CompanyPrivateRoute component={CouponsList} moduleName='store_coupons' permissionName='view' /> },
          { path: "coupons/coupon/add", element: <CompanyPrivateRoute component={CouponAdd} moduleName='store_coupons' permissionName='add' /> },
          { path: "coupons/view/:id", element: <CompanyPrivateRoute component={CouponsView} moduleName='store_coupons' permissionName='view' /> },
          { path: "coupons/:pgno/edit/:id", element: <CompanyPrivateRoute component={CouponEdit} moduleName='store_coupons' permissionName='edit' /> },

          { path: "coupons/promos/list/:id", element: <CompanyPrivateRoute component={PromosList} moduleName='store_promos' permissionName='view' /> },
          { path: "coupons/promos/add", element: <CompanyPrivateRoute component={PromosAdd} moduleName='store_promos' permissionName='view' /> },
          { path: "promos/view/:id", element: <CompanyPrivateRoute component={PromosView} moduleName='store_promos' permissionName='view' /> },
          { path: "promos/:pgno/edit/:id", element: <CompanyPrivateRoute component={PromosEdit} moduleName='store_promos' permissionName='view' /> },

          { path: "customers/list/:id", element: <CompanyPrivateRoute component={CustomerList} moduleName='store_customers' permissionName='view' /> },
          { path: "customers/view/:id", element: <CompanyPrivateRoute component={CustomerView} moduleName='store_customers' permissionName='view' /> },

          { path: "loyalty-points/list/:id", element: <CompanyPrivateRoute component={LoyaltyPointsList}  moduleName='store_loyalty_points' permissionName='view'/> },
          { path: "loyalty-points/add", element: <CompanyPrivateRoute component={AddLoyaltyPoints}  moduleName='store_loyalty_points' permissionName='add'/> },
          { path: "loyalty-points/view/:id", element: <CompanyPrivateRoute component={LoyaltyPointView} moduleName='store_loyalty_points' permissionName='view' /> },
        ]
      },
      /** Company CRM Routes End  */

      /** Company Transaction Routes Start  */
      {
        path: "transaction", children: [
          { path: "refund/list/:id", element: <CompanyPrivateRoute component={RefundList} moduleName='store_refund' permissionName='view'/> },
          { path: "refund/view/:id", element: <CompanyPrivateRoute component={RefundView} moduleName='store_refund' permissionName='view' /> },
          { path: "refund/configuration", element: <CompanyPrivateRoute component={RefundConfiguration} moduleName='store_refund' permissionName='add' /> },
          { path: "refund/reasons/list/:id", element: <CompanyPrivateRoute component={RefundReasons} /> },
          { path: "refund/reasons/view/:id", element: <CompanyPrivateRoute component={RefundReasonsView} /> },

          { path: "transaction/list/:id", element: <CompanyPrivateRoute component={TransactionList} moduleName='store_transaction' permissionName='view' /> },
          { path: "transaction/view/:id", element: <CompanyPrivateRoute component={TransactionView} moduleName='store_transaction' permissionName='view' /> },

          { path: "order/list/:id", element: <CompanyPrivateRoute component={RefundOrder} moduleName='store_orders' permissionName='view' /> },
          { path: "order/view/:id", element: <CompanyPrivateRoute component={RefundOrderView} moduleName='store_orders' permissionName='view'  /> },
          { path: "order/order-mode", element: <CompanyPrivateRoute component={OrderModeTypes} /> },
          { path: "order/add/:id", element: <CompanyPrivateRoute component={RefundOrderAdd} moduleName='store_orders' permissionName='add' /> },
          { path: "order/continueOrder", element: <CompanyPrivateRoute component={ContinueOrderAdd} /> }
        ]
      },
      /** Company Transaction Routes End  */


      /** Company Report Routes Start  */
      { path: "report/sales_report/list/:id", element: <CompanyPrivateRoute component={SalesReportList} /> },
      { path: "report/customer_report/list/:id", element: <CompanyPrivateRoute component={CustomerReportList} /> },
      { path: "report/primary_category_report/list/:id", element: <CompanyPrivateRoute component={PrimaryCategoryReportList} /> },
      { path: "report/inventory_report/list/:id", element: <CompanyPrivateRoute component={InventoryReportList} /> },
      { path: "report/payment_report/list/:id", element: <CompanyPrivateRoute component={PaymentReportList} /> },
      { path: "report/item_cost/list/:id", element: <CompanyPrivateRoute component={ItemCostList} /> },
      /** Company Report Routes Start  */

      { path: "setting/tender-configuration/list/:id", element: <CompanyPrivateRoute component={TenderConfiguration} moduleName='store_settings' permissionName='view' /> },
      { path: "setting/order-mode-configuration/list/:id", element: <CompanyPrivateRoute component={OrderModeConfiguration} moduleName='store_settings' permissionName='view' /> },
      { path: "setting/aggregator-configuration/list/:id", element: <CompanyPrivateRoute component={AggregatorConfiguration}  moduleName='store_settings' permissionName='view'/> },
      { path: "setting/kitchen-invoice/setting", element: <CompanyPrivateRoute component={KitchenInvoiceSetting}  moduleName='store_settings' permissionName='view'/> },
      { path: "setting/customer-invoice/setting", element: <CompanyPrivateRoute component={CustomerInvoiceSetting} moduleName='store_settings' permissionName='view' /> },
      { path: "setting/currency-configuration/list/:id", element: <CompanyPrivateRoute component={CurrencyConfiguration} moduleName='store_settings' permissionName='view' /> },
      { path: "setting/tip-configuration/list/:id", element: <CompanyPrivateRoute component={TipConfiguration} moduleName='store_settings' permissionName='view' /> },
      { path: "setting/tax-configuration/list/:id", element: <CompanyPrivateRoute component={TaxConfiguration} moduleName='store_settings' permissionName='view' /> },
      { path: "setting/add_area/list/:id", element: <CompanyPrivateRoute component={AreaList} moduleName='store_settings' permissionName='view' /> },
      { path: "setting/uom/list/:id", element: <CompanyPrivateRoute component={UOMList} moduleName='store_settings' permissionName='view' /> },
      { path: "setting/waste-configuration", element: <CompanyPrivateRoute component={WasteConfiguration} moduleName='store_settings' permissionName='view'/> },

      { path: "setting/aggregator-configuration/list/:id", element: <CompanyPrivateRoute component={AggregatorConfiguration} moduleName='store_settings' permissionName='view' /> },
      { path: "setting/currency-configuration/list/:id", element: <CompanyPrivateRoute component={CurrencyConfiguration}  moduleName='store_settings' permissionName='view'/> },
      { path: "setting/tip-configuration/list/:id", element: <CompanyPrivateRoute component={TipConfiguration} moduleName='store_settings' permissionName='view' /> },
      { path: "setting/tax-configuration/list/:id", element: <CompanyPrivateRoute component={TaxConfiguration}  moduleName='store_settings' permissionName='view'/> },



      { path: "socket", element: <CompanyPrivateRoute component={SampleSocketCompany} /> }

    ]
  },




]);

export default router;