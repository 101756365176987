import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useFormik } from 'formik';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import $ from "jquery";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';
import { capitalizeFirstLetter, formateDate, getBrandId, getCompany, getOutletId, removeModalBackdrops, TrimText } from '../../helpers/commonFunctions';
import Sorting from '../../components/company_panel/common/sorting';
import CustomPagination from '../../components/company_panel/common/custompagination';
import Loader from '../../components/company_panel/common/loader';
import { ChangeStatusWasteReason, DeleteWasteReason, ListWasteReason, UpdateWasteReason, WasteAddReason, WasteDetailsReason } from '../../services/company_panel/wasteManagement.services';
import { LOADER_TIME } from '../../utils/Constants';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import { DeleteRefundReason, ListRefundReason, RefundAddReason, RefundDetailsReason, UpdateRefundReason } from '../../services/company_panel/refund.services';

const RefundReasons = () => {
    const breadcrumbs = [
        {
            title: "Refund ",
            url: "/company/transaction/refund/list/1"
        },
        {
            title: "Reasons",
            url: ""
        },
    ]
    const [submitted, setSubmitted] = useState(false);
    const addCardModalCloseBtn = useRef();
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [list, setList] = useState([]);
    const [statsUpdate, setStatusUpdate] = useState("false");
    const [dataLength, setDataLength] = useState();
    const [itemPerPage, setItemPerPage] = useState(10);;
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState({});
    const [globalSearch, setGlobalSearch] = useState("");
    const [showDefault, setShowDefault] = useState({});
    const [actionType, setActionType] = useState('add');
    const [isOutletChanged, setIsOutletChanged] = useState(false);
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [currentOutlet] = useState(getOutletId());


    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
    });

    useEffect(() => {
        return () => {
            removeModalBackdrops();
        }
    }, []);

    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            formData.append("brand_id", getBrandId())
            ListRefundReason(formData).then(data => {
                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response ? data?.data?.response?.rows : [])
                setTimeout(() => {
                    setLoader(false)
                }, LOADER_TIME);
            }).catch(error => {
                setTimeout(() => {
                    setLoader(false)
                }, LOADER_TIME);
                console.log("error ====> ", error);
            })
        }
    }, [location, statsUpdate, sorting, search, globalSearch, isOutletChanged, isBrandChanged])

    // Delete functionality
    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData()
                formdata.append("id", row)
                DeleteRefundReason(formdata).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: 'Waste reason deleted successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError", error)
                })
            }
        })
    }

    const editFunction = (row) => {
        RefundDetailsReason({ id: row }).then(data => {
            console.log(data.data.response.id);
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
        setActionType('edit');
    }
    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    const closeModel = (id) => {
        $('#addCardModal').hide();
        $('.modal-backdrop').remove();
        setShowDefault({});
        setStatusUpdate(!statsUpdate)
        formik.setErrors({});
        formik.resetForm();
    }

    const validationSchema = yup.object().shape({
        reason: yup.string().trim().required('Reason is required').max(150, "Reason cannot exceed 150 digits"),
        type_of_refund: yup.string().required('Type of refund is required'),
        return_to_inventory: yup.string().required('Return to inventory is required'),
        percentage: yup
        .number()
        .nullable()
        .test('is-required-if-partial', 'Percentage is required for partial refund', function (value){
            const { type_of_refund } = this.parent;
            if(type_of_refund === 'partial' && (value === null || value === '' || value === undefined)){
                return false;
            }
            return true;
        })
        .test('is-valid-percent','Percentage must be a whole number between 0 to 100', function(value){
            const { type_of_refund } = this.parent;
            if(type_of_refund === 'partial' && (value !== null || value !== '' || value !== undefined)){
                return value > 0 && value < 100 && Number.isInteger(value)
            }
            return true;
        })
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            reason: showDefault && showDefault?.reason ? showDefault?.reason : "",
            type_of_refund: showDefault && showDefault?.type_of_refund ? showDefault?.type_of_refund : "",
            return_to_inventory: showDefault && showDefault?.return_to_inventory ? showDefault?.return_to_inventory : "",
            percentage: showDefault && showDefault?.percentage ? showDefault?.percentage : "",
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            setSubmitted(true)
            let formData = new FormData();
            formData.append('reason', values.reason.trim());
            formData.append('type_of_refund', values.type_of_refund);
            formData.append('return_to_inventory', values.return_to_inventory);
            if (values.type_of_refund === 'partial') {
                formData.append('percentage', values.percentage);
            }
            if (actionType === 'edit') {
                formData.append('id', showDefault.id);
                formData.append('brand_id', getBrandId());
                UpdateRefundReason(formData).then(response => {
                    if (response.data.status === 1 || response.data.status === "1") {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                        setTimeout(() => {
                            addCardModalCloseBtn?.current?.click();
                            setStatusUpdate(!statsUpdate)
                            formik.resetForm();
                            setShowDefault({});
                        }, 1000);
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'error',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                        setSubmitted(false)
                    }
                }).catch(error => {
                    console.log("error ====> ", error);
                })
            } else {
                formData.append('brand_id', getBrandId());
                RefundAddReason(formData).then(response => {
                    if (response.data.status === 1 || response.data.status === "1") {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                        setTimeout(() => {
                            $('#addCardModal').hide();
                            $('.modal-backdrop').remove();
                            setStatusUpdate(!statsUpdate)
                            formik.resetForm();
                            setShowDefault({});
                        }, 1000);
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'error',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                        setSubmitted(false)
                    }
                }).catch(error => {
                    console.log("error ====> ", error);
                })

            }

        },
    });

    const viewFunction = (row) => {
        navigate(`/company/transaction/refund/reasons/view/${row}`)
    }

    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <Breadcrums data={breadcrumbs} />
                        <h4>Refund</h4>
                    </div>
                    <div className='page-header-right ms-auto'>
                        <div className='page-header-right ms-auto'>
                            <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#addCardModal"
                                onClick={() => setActionType('add')}
                            >Add Reason</button>
                        </div>
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <ul className="d-flex setting w-100 mb-0 p-0">
                                <li className=""><Link to={`/company/transaction/refund/configuration`} className="btn">Refund Configuration</Link></li>
                                <li className="ms-3 active"><Link to={`/company/transaction/refund/reasons/list/${params?.id}`} className="btn btn-primary">Reasons</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th width="25%">
                                            <div className='sorting_column'>
                                                <span>Reason</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="reason" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Type Of Refund</span>
                                                {/* <Sorting sort={sorting} handleSort={handleSort} column="reason" /> */}
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Return To Inventory</span>
                                                {/* <Sorting sort={sorting} handleSort={handleSort} column="reason" /> */}
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Percentage</span>
                                                {/* <Sorting sort={sorting} handleSort={handleSort} column="reason" /> */}
                                            </div>
                                        </th>
                                        <th className="created_head" width="20%">
                                            <div className='sorting_column'>
                                                <span>Created Date</span>
                                                <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="created_at" />
                                            </div>
                                        </th>
                                        {/* <th width="20%">Status</th> */}
                                        <th className='action_head text-end' width="10%">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={12}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => (
                                                <tr>
                                                    <td>{capitalizeFirstLetter(TrimText(row?.reason, 30))}</td>
                                                    <td>{capitalizeFirstLetter(row?.type_of_refund)}</td>
                                                    <td>{capitalizeFirstLetter(row?.return_to_inventory)}</td>
                                                    <td>{row?.type_of_refund === "partial" ? row?.percentage : 'N/A'}</td>
                                                    <td>{row.created_at ? formateDate(row?.created_at) : "N/A"}</td>
                                                    <td className='text-end'>
                                                        <div className="dropdown  d-inline-block">
                                                            <button className="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li><div className='dropdown-item' onClick={() => { viewFunction(row.id) }}><i class="ti ti-eye" aria-hidden="true"></i> View details</div></li>
                                                                <li><div className='dropdown-item' type="button" data-bs-toggle="modal" data-bs-target="#addCardModal" onClick={() => { editFunction(row.id) }}><i className="ti ti-edit" aria-hidden="true"></i> Edit</div></li>
                                                                <li> <div className='dropdown-item' onClick={() => { deleteFunction(row.id) }}><i className="ti ti-trash" aria-hidden="true"></i> Delete</div></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr>
                                                <td colSpan={11} className="text-center">
                                                    {/* <img src={notFound} style={{ width: "5%" }} alt="Not Found" /> */}
                                                    <p>No record found</p>
                                                </td>
                                            </tr>
                                            }</>}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div className="" id="example1_info table-result" role="status" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "reasons", path: "/company/transaction/refund/reasons/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>

                {/*  Modal */}

                <div className="modal fade" id="addCardModal" aria-labelledby="addCardModalLabel" aria-hidden="true" data-bs-backdrop="static">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="modal-title" id="addCardModalLabel">{actionType === 'edit' ? 'Edit' : 'Add'} Reason</h2>
                                <button type="button" className="btn-close" ref={addCardModalCloseBtn} onClick={(e) => closeModel('addCardModal')} data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <label htmlFor="reason" className="col-form-label">Reason<span className=' requirestar'>*</span></label>
                                        <input type="text" className="form-control" id="reason" name="reason" placeholder='Enter reason' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.reason} autoComplete='off' />
                                        <span className='text-danger d-flex text-left'>{formik.errors.reason && formik.touched.reason && formik.errors.reason}</span>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="type_of_refund" className="form-label">Type of Refund<span className="requirestar">*</span></label>
                                        <select
                                            className="form-control"
                                            name="type_of_refund"
                                            id="type_of_refund"
                                            onChange={(e)=>{
                                                formik.handleChange(e);
                                                if(e.target.value !== 'partial'){
                                                    formik.setFieldValue('percentage', '')
                                                }
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.type_of_refund}
                                            autoComplete='off'
                                        >
                                            <option value="">Select refund type</option>
                                            <option value="full">Full Refund</option>
                                            <option value="partial">Partial Refund</option>
                                        </select>
                                        <div className='text-danger'>
                                            {formik.errors.type_of_refund && formik.touched.type_of_refund && formik.errors.type_of_refund}
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="return_to_inventory" className="form-label">Return to Inventory<span className="requirestar">*</span></label>
                                        <select
                                            className="form-control"
                                            name="return_to_inventory"
                                            id="return_to_inventory"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.return_to_inventory}
                                            autoComplete='off'
                                        >
                                            <option value="">Please select</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                        <div className='text-danger'>{formik.errors.return_to_inventory && formik.touched.return_to_inventory && formik.errors.return_to_inventory}</div>
                                    </div>
                                    {formik?.values?.type_of_refund === 'partial' &&
                                        <div className="mb-3">
                                            <label htmlFor="percentage" className="form-label">Percent of Refund<span className="requirestar">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="percentage"
                                                id="percentage"
                                                placeholder='Enter percent of refund'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.percentage}
                                                autoComplete='off'
                                            />
                                            <div className='text-danger'>{formik.errors.percentage && formik.touched.percentage && formik.errors.percentage}</div>
                                        </div>
                                    }
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-primary" style={{ width: '48%' }} data-bs-dismiss="modal" onClick={(e) => closeModel('addCardModal')}>Cancel</button>
                                    <button type="submit" className="btn btn-primary" style={{ width: '48%' }}>{actionType === 'edit' ? 'Update' : 'Add'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={true | 1000}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />
            </div>
        </>
    );
}

export default RefundReasons
