import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { capitalizeAllFirstLetters, capitalizeFirstLetter, formatToTwoDecimals, getOutletId, removeHyphens } from '../../helpers/commonFunctions';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import Loader from '../../components/company_panel/common/loader'
import { GetSingleRefund } from '../../services/company_panel/refund.services';


const RefundView = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [showDefault, setShowDefault] = useState({});
    const breadcrumbs = [{ title: "Refund", url: "/company/transaction/refund/list/1" }, { title: "View Refund", url: "" }];
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [currentOutlet] = useState(getOutletId());
    const [isOutletChanged, setIsOutletChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        });
        if (currentOutlet != getOutletId()) {
            navigate(`/company/transaction/refund/list/${1}`);
        }
    });

    useEffect(() => {
        GetSingleRefund({ id: params.id }).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : {})
            console.log("data.data.response.rows ====> ", data.data.response);
        }).catch(error => {
            console.log("error ====> ", error);
        })
    }, [])

    const showReason = () => {
        const transaction = showDefault?.transaction;
        
        if (transaction?.refund_reason_id) {
            return capitalizeFirstLetter(transaction?.refundReason?.reason);
        } else if (transaction?.refund_reason) {
            return capitalizeFirstLetter(transaction.refund_reason);
        }
        
        return '-';
    };
    

    return (
        <>
            <div class="page-header d-flex align-items-center">
                <div class="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>View Refund</h4>
                </div>
            </div>
            <div className="card custom-card">
                {showDefault && Object.keys(showDefault).length > 0 ?
                    <div className="card-body p-0">
                        <div className="col-lg-12 form-group">
                            <table id="simple-table" className="table table-custom">
                                <tbody>
                                    <tr >
                                        <td>Order ID</td>
                                        <td className='text-end'>{(showDefault?.order_id)}</td>
                                    </tr>
                                    <tr >
                                        <td>Order Type</td>
                                        <td className='text-end'>{capitalizeAllFirstLetters(showDefault?.order_mode_configuration?.name)}</td>
                                    </tr>

                                    <tr>
                                        <td>Outlet Name</td>
                                        <td className='text-end'>{showDefault && showDefault.outlet ? capitalizeAllFirstLetters(showDefault?.outlet?.outlet_name) : "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>Customer Name</td>
                                        <td className='text-end'>{showDefault && showDefault.customer ? capitalizeAllFirstLetters(showDefault?.customer?.customer_name) : "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>Refunded Amount</td>
                                        <td className='text-end'>{showDefault && showDefault.transaction ? formatToTwoDecimals(showDefault?.transaction?.refunded_amount) : "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>Payment Status</td>
                                        <td className='text-end'>{showDefault && showDefault.transaction ? capitalizeAllFirstLetters(showDefault?.transaction?.payment_status) : "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>Reason For Refund</td>
                                        {/* <td className='refund-text'>{showDefault && showDefault.transaction ? capitalizeFirstLetter(showDefault?.transaction?.refund_reason) : "-"}</td> */}
                                        <td className='refund-text'>{showReason()}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div> : <Loader />}
            </div>
        </>
    )
}

export default RefundView;