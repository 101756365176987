import React, { useState, useEffect, useMemo } from 'react'
import { useFormik } from 'formik';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import * as yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetBrandOutletsList, GetOutletAllList } from '../../services/company_panel/outletService';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import { capitalizeAllFirstLetters, capitalizeFirstLetter, endOfDay, getBrandId, getCompany, getOutletId } from '../../helpers/commonFunctions';
import { TagPicker } from 'rsuite';
import { AddCoupon, CouponDetails, UpdateCoupon } from '../../services/company_panel/coupons.service';
import { COUPON_TYPE, DURATION, USER, WEEK } from '../../utils/Constants';
import Swal from 'sweetalert2';
import CustomDatetimepicker from '../../helpers/customDatepicker';
import { globalLoader } from '../../helpers/global';
import Loader from '../../components/company_panel/common/loader';
import { AllUsers } from '../../services/company_panel/platform_user/platformUserService';
import { GetActiveOrderModes } from '../../services/company_panel/setting/orderModeConfigurationService';
import CustomMultiSelect from '../../components/common/CustomMultiSelect';
import { getAllMenuItems } from '../../services/company_panel/mealService';

const CouponEdit = () => {

  const breadcrumbs = [
    {
      title: "Coupons ",
      url: "/company/crm/coupons/coupon/list/1"
    },
    {
      title: "Edit Coupon",
      url: ""
    },
  ]
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();

  const [outletList, setOutletList] = useState([]);
  const [showDefault, setShowDefault] = useState({});
  const [data, setData] = useState([]);
  const [orderModeData, setOrderModesData] = useState([]);
  const params = useParams()
  const [selectedType, setSelectedType] = useState();
  const [loader, setLoader] = useState(true);
  const [isBrandChanged, setIsBrandChanged] = useState(false);
  const [currentBrand] = useState(getBrandId());

  useMemo(() => {
    document.addEventListener('brand-changed', function () {
      setIsBrandChanged(!isBrandChanged);
    });
    if (currentBrand != getBrandId()) {
      navigate('/company/crm/coupons/coupon/list/1')
    }
  });

  useEffect(() => {
    GetBrandOutletsList({ brand_id: getBrandId() }).then(data => {
      setOutletList(data && data.data && data.data.response ? data.data.response : []);
      setLoader(false);
    }).catch(error => {
      console.log("error=====>", error)
    })
  }, [isBrandChanged])

  useEffect(() => {
    CouponDetails({ id: params.id }).then(data => {
      setShowDefault(data && data.data && data.data.response ? data.data.response : {});
    }).catch(error => {
      console.log("error=====>", error)
    })
  }, [])

  useEffect(() => {
    AllUsers({ company_id: getCompany().company_id }).then(data => {
      setData(data && data.data && data.data.response ? data.data.response : []);
      setLoader(false);
    }).catch(error => {
      console.log("error=====>", error)
    })
  }, [])

  useEffect(() => {
    GetActiveOrderModes(getBrandId()).then(data => {
      setOrderModesData(data && data.data && data.data.response ? data.data.response : []);
      setLoader(false);
    }).catch(error => {
      console.log("error=====>", error)
    })
  }, [isBrandChanged]);

  const validationSchema = yup.object().shape({
    name: yup.string().trim().required('Name is required'),
    coupon_code: yup.string().trim().required('Coupon code is required'),
    type: yup.string().trim().required('Type is required'),
    // percentage: yup.string().trim().required('Percentage is required'),
    // maximum_discount_amount: yup.string().trim().required('Maximum discount amount is required'),
    // minimum_applicable_order_amount: yup.string().trim().required('Minimum applicable order amount is required'),
    // maximum_discount_amount: yup
    //   .string()
    //   .trim()
    //   .required('Maximum discount amount is required')
    //   .matches(/^\d{1,4}$/, 'Maximum discount amount must be a positive number up to 4 digits')
    //   .test('is-positive', 'Maximum discount amount cannot be negative', value => {
    //     const numberValue = parseInt(value, 10);
    //     return !isNaN(numberValue) && numberValue >= 0;
    //   }),
    // minimum_applicable_order_amount: yup
    //   .string()
    //   .trim()
    //   .required('Minimum applicable order amount is required')
    //   .matches(/^\d{1,4}$/, 'Minimum applicable order amount must be a positive number up to 4 digits')
    //   .test('is-positive', 'Minimum applicable order amount cannot be negative', value => {
    //     const numberValue = parseInt(value, 10);
    //     return !isNaN(numberValue) && numberValue >= 0;
    //   }),
    usageCondition: yup.string()
      .trim()
      .required('Usage condition is required'),

    usagePerCustomer: yup.string()
      .trim()
      .test(
        'is-required-when-condition-matches',
        'Usage per customer is required',
        function (value) {
          const { usageCondition } = this.parent;
          if (usageCondition === 'number_of_times') {
            return !!value;
          }
          return true; // Not required if the condition doesn't match
        }
      ),
    customerType: yup.string().trim().required('Customer type is required'),
    authenticationType: yup.string().trim().required('Authentication type is required'),
    start_date: yup.date()
      .required("Start date is required")
      .test({
        name: 'start-date-check',
        message: 'Start date cannot be after end date',
        test: function (value) {
          const { end_date } = this.parent;
          if (value && end_date) {
            return new Date(value) <= new Date(end_date);
          }
          return true;
        },
      }),
    end_date: yup.date()
      .required("End date is required")
      .test({
        name: 'end-date-check',
        message: 'End date cannot be before start date',
        test: function (value) {
          const { start_date } = this.parent;
          if (value && start_date) {
            return new Date(value) >= new Date(start_date);
          }
          return true;
        },
      }),
    // start_time: yup.string().trim()
    //   .required('Start time is required')
    //   .test({
    //     name: 'start-time-check',
    //     message: 'Start time cannot be after end time on the same date',
    //     test: function (value) {
    //       const { start_date, end_date, end_time } = this.parent;
    //       if (start_date && end_date && start_date.toString() === end_date.toString() && value && end_time) {
    //         return value <= end_time;
    //       }
    //       return true;
    //     },
    //   }),
    // end_time: yup.string().trim()
    //   .required('End time is required')
    //   .test({
    //     name: 'end-time-check',
    //     message: 'End time cannot be before start time on the same date',
    //     test: function (value) {
    //       const { start_date, end_date, start_time } = this.parent;
    //       if (start_date && end_date && start_date.toString() === end_date.toString() && value && start_time) {
    //         return value >= start_time;
    //       }
    //       return true;
    //     },
    //   }),
    start_time: yup.string().trim()
      .required('Start time is required')
      .test({
        name: 'start-time-check',
        message: 'Start time cannot be after end time on the same date',
        test: function (value) {
          const { start_date, end_date, end_time } = this.parent;
          if (start_date && end_date && start_date.toString() === end_date.toString() && value && end_time) {
            return value <= end_time;
          }
          return true;
        },
      }),
    end_time: yup.string().trim()
      .required('End time is required')
      .test({
        name: 'end-time-check',
        message: 'End time cannot be before start time on the same date',
        test: function (value) {
          const { start_date, end_date, start_time } = this.parent;
          if (start_date && end_date && start_date.toString() === end_date.toString() && value && start_time) {
            return value >= start_time;
          }
          return true;
        },
      })
      .test({
        name: 'start-time-before-end-time',
        message: 'Start time cannot be greater than end time',
        test: function () {
          const { start_date, end_date, start_time, end_time } = this.parent;
          if (start_date && end_date && start_date.toString() !== end_date.toString() && start_time && end_time) {
            return start_time <= end_time;
          }
          return true;
        },
      }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: showDefault?.name ? showDefault.name : '',
      coupon_code: showDefault?.coupon_code ? showDefault.coupon_code : '',
      type: showDefault?.type ? showDefault.type : '',
      percentage: showDefault?.percentage ? showDefault.percentage : '',
      maximum_discount_amount: showDefault?.maximum_discount_amount ? showDefault.maximum_discount_amount : 0,
      minimum_applicable_order_amount: showDefault?.minimum_applicable_order_amount ? showDefault.minimum_applicable_order_amount : 0,
      applicable_on: showDefault?.applicable_on ? showDefault.applicable_on : '',
      outlet_id: showDefault && showDefault.outlet_id ? showDefault.outlet_id : "",
      usageCondition: showDefault?.usage_condition ? showDefault.usage_condition : '',
      usagePerCustomer: showDefault?.usage_per_customer ? showDefault.usage_per_customer : '',
      customerType: showDefault?.customer_type ? showDefault.customer_type : '',
      authenticationType: showDefault?.authentication_type ? showDefault.authentication_type : '',
      usage_condition_number: showDefault?.usage_condition_number ? showDefault.usage_condition_number : '',
      usage_per_customer_number: showDefault?.usage_per_customer_number ? showDefault.usage_per_customer_number : '',
      authenticateUser: showDefault?.authenticate_user ? showDefault.authenticate_user : '',
      start_date: showDefault && showDefault.start_date ? new Date(showDefault?.start_date) : "",
      end_date: showDefault && showDefault.end_date ? new Date(showDefault?.end_date) : "",
      start_time: showDefault && showDefault.end_date ? (showDefault?.start_time) : "",
      end_time: showDefault && showDefault.end_date ? (showDefault?.end_time) : "",
      week: showDefault && showDefault.week ? (showDefault?.week) : "",
      menu_items: showDefault && showDefault.menu_items ? (showDefault?.menu_items) : [],
    },
    validationSchema: validationSchema,
    validate: values => {
      const errors = {};
      if (values?.usageCondition === "number_of_times") {
        const usageConditionError = validateNumber(values.usage_condition_number);
        if (usageConditionError) {
          errors.usage_condition_number = usageConditionError;
        }
        if (values?.usagePerCustomer === "number_of_times") {
          const usagePerCustomerError = validateNumber(values.usage_per_customer_number);
          if (usagePerCustomerError) {
            errors.usage_per_customer_number = usagePerCustomerError;
          }
        }
        if (values?.usageCondition === "number_of_times" && values?.usagePerCustomer === "number_of_times") {
          const usageConditionNumber = parseInt(values.usage_condition_number, 10);
          const usagePerCustomerNumber = parseInt(values.usage_per_customer_number, 10);
          if (usagePerCustomerNumber > usageConditionNumber) {
            errors.usage_per_customer_number = 'Usage per customer number cannot be greater than usage condition number';
          }
        }
      }

      // if (values?.usageCondition === "number_of_times") {
      //   const usageConditionError = validateNumber(values.usage_condition_number);
      //   if (usageConditionError) {
      //     errors.usage_condition_number = usageConditionError;
      //   }
      // }
      // if (values?.usagePerCustomer === "number_of_times") {
      //   const usagePerCustomerError = validateNumber(values.usage_per_customer_number);
      //   if (usagePerCustomerError) {
      //     errors.usage_per_customer_number = usagePerCustomerError;
      //   }
      // }
      if (selectedType === "item_free") {
        if (!values?.menu_items || values.menu_items.length === 0) {
          errors.menu_items = "This field is required";
        }
      }
      if (values?.usageCondition === "number_of_times") {
        if (!values.usagePerCustomer) errors.usagePerCustomer = "This field is required";
      }
      if (values?.authenticationType == "authentication_approval") {
        if (!values.authenticateUser || values.authenticateUser.length === 0) errors.authenticateUser = "This field is required";
      }
      if (!values?.applicable_on || values.applicable_on.length === 0) {
        errors.applicable_on = "This field is required";
      }
      if (!values?.week || values.week.length === 0) {
        errors.week = "This field is required";
      }
      if (!values?.outlet_id || values.outlet_id.length === 0) {
        errors.outlet_id = "This field is required";
      }
      if (selectedType === "flat") {
        if (!values.percentage) {
          errors.percentage = "Flat discount is required";
        } else {
          const flatDiscount = parseFloat(values.percentage);
          if (isNaN(flatDiscount)) {
            errors.percentage = "Flat discount must be a valid number";
          } else if (!Number.isInteger(flatDiscount)) {
            errors.percentage = "Flat discount cannot be a decimal";
          } else if (flatDiscount < 0) {
            errors.percentage = "Flat discount cannot be negative";
          }
          else if (flatDiscount > 99999999) {
            errors.percentage = "Flat discount cannot exceed 8 digits";
          }
          // else if (values.minimum_applicable_order_amount) {
          //   const minOrderAmount = parseFloat(values.minimum_applicable_order_amount);
          //   if (flatDiscount > minOrderAmount) {
          //     errors.percentage = "Flat discount cannot be greater than minimum applicable order amount";
          //   } else {
          //     delete errors.percentage;
          //   }
          // }
        }
      }
      if (selectedType === "percentage") {
        if (!values.percentage) {
          errors.percentage = "Discount percentage is required";
        } else if (isNaN(values.percentage)) {
          errors.percentage = "Discount percentage must be a number";
        } else if (values.percentage < 0) {
          errors.percentage = "Discount percentage cannot be negative";
        } else if (values.percentage > 100) {
          errors.percentage = "Discount percentage cannot exceed 100";
        } else if (!Number.isInteger(Number(values.percentage))) {
          errors.percentage = "Discount percentage cannot be a decimal";
        }
      }
      if (selectedType === "percentage" || selectedType === "flat" || selectedType === "ask_for_amount" || selectedType === "ask_for_percentage") {
        if (!values.minimum_applicable_order_amount) {
          errors.minimum_applicable_order_amount = "Minimum applicable order amount is required";
        } else {
          const discountValue = parseFloat(values.minimum_applicable_order_amount);
          if (isNaN(discountValue)) {
            errors.minimum_applicable_order_amount = "Minimum applicable order amount must be a valid number";
          } else if (!Number.isInteger(discountValue)) {
            errors.minimum_applicable_order_amount = "Minimum applicable order amount cannot be a decimal";
          } else if (discountValue < 0) {
            errors.minimum_applicable_order_amount = "Minimum applicable order amount cannot be negative";
          }
          else if (discountValue > 99999999) {
            errors.minimum_applicable_order_amount = "Minimum applicable order amount cannot exceed 8 digits";
          }
          else {
            delete errors.minimum_applicable_order_amount;
          }
        }
      }
      if (selectedType === "percentage" || selectedType === "ask_for_amount" || selectedType === "ask_for_percentage") {
        if (!values.maximum_discount_amount) {
          errors.maximum_discount_amount = "Maximum discount amount is required";
        } else {
          const discountValue = parseFloat(values.maximum_discount_amount);
          if (isNaN(discountValue)) {
            errors.maximum_discount_amount = "Maximum discount amount must be a valid number";
          } else if (!Number.isInteger(discountValue)) {
            errors.maximum_discount_amount = "Maximum discount amount cannot be a decimal";
          } else if (discountValue < 0) {
            errors.maximum_discount_amount = "Maximum discount amount cannot be negative";
          }
          else if (discountValue > 99999999) {
            errors.maximum_discount_amount = "Maximum discount amount cannot exceed 8 digits";
          }
          // else if (values.minimum_applicable_order_amount) {
          //   const minOrderAmount = parseFloat(values.minimum_applicable_order_amount);
          //   if (discountValue > minOrderAmount) {
          //     errors.maximum_discount_amount = "Maximum discount amount cannot be greater than minimum applicable order amount";
          //   } else {
          //     delete errors.maximum_discount_amount;
          //   }
          // }
          else {
            delete errors.maximum_discount_amount;
          }
        }
      }

      return errors;
    },

    onSubmit: values => {
      // globalLoader(true);
      // console.log("***************",errors)
      let formData = new FormData();
      formData.append("id", params.id)
      formData.append('name', values.name);
      formData.append('coupon_code', values.coupon_code);
      formData.append('type', values.type);
      formData.append('percentage', values.percentage);
      if(values?.type == "percentage" || values?.type == "ask_for_amount" || values?.type == "ask_for_percentage"){
        formData.append('maximum_discount_amount', values.maximum_discount_amount);
      }
      if(values?.type == "percentage" || values?.type == "flat" || values?.type == "ask_for_amount" || values?.type == "ask_for_percentage"){
      formData.append('minimum_applicable_order_amount', values.minimum_applicable_order_amount);
      }
      formData.append('applicable_on', JSON.stringify(values.applicable_on));
      formData.append('outlet_id', JSON.stringify(values.outlet_id));
      formData.append('usage_condition', values.usageCondition);

      if (values?.usageCondition == 'number_of_times') {
        formData.append('usage_condition_number', values.usage_condition_number);
        formData.append('usage_per_customer', values.usagePerCustomer);
        if (values?.usagePerCustomer == 'number_of_times') {
          formData.append('usage_per_customer_number', values.usage_per_customer_number);
        }
      }

      formData.append('customer_type', values.customerType);
      formData.append('authentication_type', values.authenticationType);

      if (values?.authenticationType == "authentication_approval") {
        formData.append('authenticate_user', JSON.stringify(values.authenticateUser));
      }

      // if (values?.usageCondition == 'number_of_times') {
      //   formData.append('usage_condition_number', values.usage_condition_number);
      // }
      // if (values?.usagePerCustomer == 'number_of_times') {
      //   formData.append('usage_per_customer_number', values.usage_per_customer_number);
      // }
      formData.append("start_date", values.start_date);
      formData.append("end_date", endOfDay(values.end_date));
      formData.append("start_time", values.start_time);
      formData.append("end_time", values.end_time);
      formData.append("week", JSON.stringify(values.week));
      if(values?.type === 'item_free'){
        formData.append('menu_items', JSON.stringify(values.menu_items));
      }
      formData.append('company_id', getCompany().company_id);
      formData.append('brand_id', getBrandId());

      if (!searchParams.get('copy')) {
        UpdateCoupon(formData).then(response => {
          if (response.data.status === 1 || response.data.status === "1") {
            Swal.fire({
              customClass: 'swal-wide',
              icon: 'success',
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500,
              toast: true,
              position: 'top-right',
            });
            globalLoader(false);
            setTimeout(() => {
              navigate(`/company/crm/coupons/coupon/list/${1}`)
            }, 1000);
          } else {
            Swal.fire({
              customClass: 'swal-wide',
              icon: 'error',
              title: response.data.message,
              showConfirmButton: false,
              timer: 5000,
              toast: true,
              position: 'top-right',
            })
            globalLoader(false);
          }
        }).catch(error => {
          console.log("error ====> ", error);
        })
      } else {
        AddCoupon(formData).then(response => {
          if (response.data.status === 1 || response.data.status === "1") {
            Swal.fire({
              customClass: 'swal-wide',
              icon: 'success',
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500,
              toast: true,
              position: 'top-right',
            });
            globalLoader(false);
            setTimeout(() => {
              navigate(`/company/crm/coupons/coupon/list/${1}`)
            }, 1000);
          } else {
            Swal.fire({
              customClass: 'swal-wide',
              icon: 'error',
              title: response.data.message,
              showConfirmButton: false,
              timer: 5000,
              toast: true,
              position: 'top-right',
            })
            globalLoader(false);
          }
        }).catch(error => {
          console.log("error ====> ", error);
        })
      }

    }

  });

  const handleCheckboxChange = (e, setFieldValue, values) => {
    const { checked, value } = e.target;
    console.log(checked, "===", value)
    if (checked) {
      setFieldValue('applicable_on', [...values.applicable_on, Number(value)]);
    } else {
      setFieldValue(
        'applicable_on',
        values.applicable_on.filter(item => item !== Number(value))
      );
    }
  };

  const outListData =
    outletList &&
    outletList.map((item) => ({ label: capitalizeAllFirstLetters(item?.outlet_name), value: item?.id }));


  const weekData = [{ value: "sunday", label: "Sunday" }, { value: "monday", label: "Monday" }, { value: "tuesday", label: "Tuesday" },
  { value: "wednesday", label: "Wednesday" }, { value: "thursday", label: "Thursday" },
  { value: "friday", label: "Friday" }, { value: "saturday", label: "Saturday" }]

  // const UserData = data && data.map((item) => ({ label: capitalizeAllFirstLetters(item?.first_name + " " + item.last_name), value: item?.id }));

  useEffect(() => {
    setSelectedType(formik.values.type);
  }, [formik.values.type]);

  const validateNumber = (value) => {
    if (value === undefined || value === null || value === '') {
      return 'This field is required';
    }
    if (!/^\d+$/.test(value)) {
      return 'This field must be a whole number';
    }
    const length = value.length;
    // if (length > 8) {
    //   return 'This field must be between 1 and 99999999';
    // }
    // const number = parseInt(value, 10);
    // if (number < 1 || number > 99999999) {
    //   return 'This field must be between 1 and 99999999';
    // }
    return null;
  };

  const [menuItemData, setMenuItemsData] = useState([]);

  const menuData = menuItemData && menuItemData.map((item) => ({
    label: capitalizeAllFirstLetters(item?.item_name),
    value: item?.id
  }));

  useEffect(() => {
    getAllMenuItems({ brand_id: getBrandId() }).then(data => {
      if (data && data.data && data.data.response) {
        setMenuItemsData(data.data.response.rows);
      }
      setLoader(false);
    }).catch(error => {
      console.log("error=====>", error);
    });
  }, []);


  return (
    <>

      <div class="page-header d-flex align-items-center">
        <div class="page-header-left">
          <Breadcrums data={breadcrumbs} />
          <h4>Coupons</h4>
        </div>
        <div class="page-header-right ms-auto">

        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        {showDefault && Object.keys(showDefault).length > 0 ?
          <div className="row-sm">
            <div className="card custom-card">
              <div className="card-body">
                <div className="row row-sm">
                  <div className='col-lg-6 text-center form-group mt-3'>
                    <label htmlFor='name' className='text-left d-flex'>Name <span className="requirestar">*</span></label>
                    <input name='name' id="name" type='text' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} className='form-control' />
                    <span className='text-danger d-flex text-left'>{formik.errors.name && formik.touched.name && formik.errors.name}</span>
                  </div>
                  <div className='col-lg-6 text-center form-group mt-3'>
                    <label htmlFor='coupon_code' className='text-left d-flex'>Coupon Code<span className="requirestar">*</span></label>
                    <input name='coupon_code' id="coupon_code" type='text' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.coupon_code} className='form-control' />
                    <span className='text-danger d-flex text-left'>{formik.errors.coupon_code && formik.touched.coupon_code && formik.errors.coupon_code}</span>
                  </div>
                  <div className='col-lg-6 text-center form-group mt-3'>
                    <label htmlFor='type' className='text-left d-flex'>Type <span className="requirestar">*</span></label>
                    <select
                      className="form-control"
                      name='type'
                      value={formik?.values?.type}
                      onChange={(e) => {
                        const value = e.target.value;
                        setSelectedType(value);
                        formik.setFieldValue("type", value);
                      }}
                      id="type"
                    >
                      <option value="flat">Amount</option>
                      <option value="percentage">Percentage</option>
                      <option value="item_free">Free Item</option>
                      <option value='ask_for_amount'>Ask For Amount</option>
                      <option value='ask_for_percentage'>Ask For Percentage</option>
                    </select>
                    <span className='text-danger d-flex text-left'>{formik.errors.type && formik.touched.type && formik.errors.type}</span>
                  </div>
                  {selectedType == 'flat' || selectedType == 'percentage' ?
                    <div className='col-lg-6 text-center form-group mt-3'>
                      <label htmlFor='percentage' className='text-left d-flex'>
                        {selectedType === 'flat' ? 'Discount' : 'Discount(Percentage)'}
                        <span className="requirestar">*</span>
                      </label>
                      <input
                        name='percentage'
                        id="percentage"
                        type='number'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.percentage}
                        placeholder={selectedType === 'flat' ? 'Enter discount' : 'Enter percentage'}
                        className='form-control'
                      />
                      <span className='text-danger d-flex text-left'>
                        {formik.errors.percentage && formik.touched.percentage && formik.errors.percentage}
                      </span>
                    </div>
                    : null}
                  {selectedType === 'item_free' ?
                    <div className='col-lg-6 text-center form-group mt-3'>
                      {menuData?.length === 0 && (loader == false) ?
                        <div className="alert alert-warning">
                          There is no menu item created yet. Please{' '}
                          <Link to="/company/menu/menu/menu_items/add">
                            create a menu item
                          </Link>{' '}
                          to assign buy items.
                        </div>
                        : null}
                      <label htmlFor='menu_items' className='text-left d-flex'>Choose Menu Items<span className="requirestar">*</span></label>
                      <CustomMultiSelect data={menuData} overFlowVisibleOptions={1} form={formik} name='menu_items' />
                      <span className='text-danger d-flex text-left'>{formik.errors.menu_items && formik.touched.menu_items && formik.errors.menu_items}</span>
                    </div>
                    : null
                  }
                  {selectedType === 'percentage' || selectedType == 'ask_for_amount' || selectedType == 'ask_for_percentage' ?
                    <div className='col-lg-6 text-center form-group mt-3'>
                      <label htmlFor='maximum_discount_amount' className='text-left d-flex'>Maximum Discount Amount<span className="requirestar">*</span></label>
                      <input name='maximum_discount_amount' id="maximum_discount_amount" type='number' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.maximum_discount_amount} className='form-control' />
                      <span className='text-danger d-flex text-left'>{formik.errors.maximum_discount_amount && formik.touched.maximum_discount_amount && formik.errors.maximum_discount_amount}</span>
                    </div>
                    : null}
                  {selectedType !== 'item_free' ?
                    <div className='col-lg-6 text-center form-group mt-3'>
                      <label htmlFor='minimum_applicable_order_amount' className='text-left d-flex'>Minimum Applicable Order Amount<span className="requirestar">*</span></label>
                      <input name='minimum_applicable_order_amount' id="minimum_applicable_order_amount" type='number' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.minimum_applicable_order_amount} className='form-control' />
                      <span className='text-danger d-flex text-left'>{formik.errors.minimum_applicable_order_amount && formik.touched.minimum_applicable_order_amount && formik.errors.minimum_applicable_order_amount}</span>
                    </div>
                    : null}
                  <div className='col-lg-12 text-center form-group mt-3'>
                    {orderModeData?.length === 0 && (loader == 'false' || loader == false) ?
                      <div className="alert alert-warning">
                        There is no order mode created yet. Please{' '}
                        <Link to="/company/setting/order-mode-configuration/list/1">
                          create a order mode
                        </Link>{' '}
                        to assign to the applicable.
                      </div>
                      : null}
                    <label htmlFor="order_mode" className="text-left d-flex">
                      Applicable On<span className="requirestar">*</span>
                    </label>
                    <div className='row ps-3 mt-2'>
                      {orderModeData.map(mode => (
                        <div key={mode.id} className="form-check w-25 text-start">
                          <input
                            className="form-check-input cp"
                            type="checkbox"
                            name="applicable_on"
                            id={`mode-${mode.id}`}
                            value={mode.id}
                            autoComplete='off'
                            checked={formik.values.applicable_on.includes(mode.id)}
                            onChange={(e) => handleCheckboxChange(e, formik.setFieldValue, formik.values)}
                            onBlur={formik.handleBlur}
                          />
                          <label className="form-check-label cp" htmlFor={`mode-${mode.id}`}>
                            {capitalizeAllFirstLetters(mode.name)}
                          </label>
                        </div>
                      ))}
                    </div>
                    <span className='text-danger d-flex text-left'>
                      {formik.errors.applicable_on && formik.touched.applicable_on && formik.errors.applicable_on}
                    </span>
                  </div>

                  <div className='col-lg-6 text-center form-group mt-3'>
                    <h2 className='text-left d-flex mb-2'>Choose Outlet</h2>
                    {outListData?.length === 0 && (loader == 'false' || loader == false) ?
                      <div className="alert alert-warning">
                        There is no outlet created yet. Please{' '}
                        <Link to="/company/business/outlet/add">
                          create a outlet
                        </Link>{' '}
                      </div>
                      : null}
                    <CustomMultiSelect data={outListData} overFlowVisibleOptions={1} form={formik} name='outlet_id' />
                    <span className='text-danger d-flex text-left'>{formik.errors.outlet_id && formik.touched.outlet_id && formik.errors.outlet_id}</span>
                  </div>
                  <div className='col-lg-6 text-center form-group mt-3'>
                  </div>
                  <div className='col-lg-6 text-center form-group mt-3'>
                    <label htmlFor='start_date' className='text-left d-flex'>Start Date <span className="requirestar">*</span></label>
                    <CustomDatetimepicker
                      placeholder={"Start date"}
                      setField={formik.setFieldValue}
                      fieldname={`start_date`}
                      setFieldTouched={formik.setFieldTouched}
                      value={formik.values.start_date}
                    />
                    <span className='text-danger d-flex text-left'>{formik.errors.start_date && formik.touched.start_date && formik.errors.start_date}</span>
                  </div>
                  <div className='col-lg-6 text-center form-group mt-3'>
                    <label htmlFor='end_date' className='text-left d-flex'>End Date <span className="requirestar">*</span></label>
                    <CustomDatetimepicker
                      placeholder={"End date"}
                      setField={formik.setFieldValue}
                      fieldname={`end_date`}
                      setFieldTouched={formik.setFieldTouched}
                      value={formik.values.end_date}
                    />
                    <span className='text-danger d-flex text-left'>{formik.errors.end_date && formik.touched.end_date && formik.errors.end_date}</span>
                  </div>
                  <div className='col-lg-6 text-center form-group mt-3'>
                    <label htmlFor='start_time' className='text-left d-flex'>Start Time <span className="requirestar">*</span></label>
                    <input
                      placeholder={"Select"}
                      onChange={(e) => { formik.setFieldValue("start_time", e?.target?.value) }}
                      // onClick={(e) => e.target.showPicker()}
                      name={`start_time`}
                      id='start_time'
                      value={formik.values.start_time}
                      type="time"
                      className='form-control'
                    />
                    <span className='text-danger d-flex text-left'>{formik.errors.start_time && formik.touched.start_time && formik.errors.start_time}</span>
                  </div>
                  <div className='col-lg-6 text-center form-group mt-3'>
                    <label htmlFor='end_time' className='text-left d-flex'>End Time <span className="requirestar">*</span></label>
                    <input
                      placeholder={"Select"}
                      onChange={(e) => { formik.setFieldValue("end_time", e?.target?.value) }}
                      // onClick={(e) => e.target.showPicker()}
                      name={`end_time`}
                      id='end_time'
                      value={formik.values.end_time}
                      type="time"
                      className='form-control'
                    />
                    <span className='text-danger d-flex text-left'>{formik.errors.end_time && formik.touched.end_time && formik.errors.end_time}</span>
                  </div>
                  <div className='col-lg-6 text-center form-group mt-3'>
                    <label htmlFor='week' className='text-left d-flex'>Week Selection <span className="requirestar">*</span></label>
                    <CustomMultiSelect data={weekData} form={formik} name='week' />
                    <span className='text-danger d-flex text-left'>{formik.errors.week && formik.touched.week && formik.errors.week}</span>
                  </div>
                  <div className='col-lg-6 text-center form-group mt-3'>
                  </div>
                  <div className='row'>
                    <div className='col-lg-6 text-center form-group mt-3'>
                      <label className="text-left d-flex">Usage Condition<span className="requirestar">*</span></label>
                      <div className='row ps-3 mt-3'>
                        <div className="form-check w-25 text-start">
                          <input className="form-check-input cp" type="radio" name="usageCondition" checked={formik.values.usageCondition === 'one_time'} id="OneTime" value="one_time" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                          <label className="form-check-label cp" htmlFor="OneTime">One time</label>
                        </div>
                        <div className="form-check w-25 text-start">
                          <input className="form-check-input cp" type="radio" name="usageCondition" checked={formik.values.usageCondition === 'number_of_times'} id="NumberOfTimes" value="number_of_times" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                          <label className="form-check-label cp" htmlFor="NumberOfTimes">Number of times</label>
                        </div>
                      </div>
                      {formik.values.usageCondition === 'number_of_times' && (
                        <div className='col-lg-6 text-center form-group mt-3'>
                          <label htmlFor='usage_condition_number' className='text-left d-flex'></label>
                          <input name='usage_condition_number' type='usage_condition_number' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.usage_condition_number} placeholder='Enter number' className='form-control' />
                          <span className='text-danger d-flex text-left'>{formik.errors.usage_condition_number && formik.touched.usage_condition_number && formik.errors.usage_condition_number}</span>
                        </div>
                      )}
                      <span className='text-danger d-flex text-left'>{formik.errors.usageCondition && formik.touched.usageCondition && formik.errors.usageCondition}</span>
                    </div>
                    {formik.values.usageCondition == 'number_of_times' && (
                      <div className='col-lg-6 text-center form-group mt-3'>
                        <label className="text-left d-flex">Usage Per Customer<span className="requirestar">*</span></label>
                        <div className='row ps-3 mt-3'>
                          <div className="form-check w-25 text-start">
                            <input className="form-check-input cp" type="radio" name="usagePerCustomer" checked={formik.values.usagePerCustomer === 'one_time'} id="OneTimeCustomer" value="one_time" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            <label className="form-check-label cp" htmlFor="OneTimeCustomer">One time</label>
                          </div>
                          <div className="form-check w-25 text-start">
                            <input className="form-check-input cp" type="radio" name="usagePerCustomer" checked={formik.values.usagePerCustomer === 'number_of_times'} id="NumberOfTimesCustomer" value="number_of_times" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            <label className="form-check-label cp" htmlFor="NumberOfTimesCustomer">Number of times</label>
                          </div>
                        </div>
                        {formik.values.usagePerCustomer === 'number_of_times' && (
                          <div className='col-lg-6 text-center form-group mt-2'>
                            <label htmlFor='usage_per_customer_number' className='text-left d-flex'></label>
                            <input name='usage_per_customer_number' type='usage_per_customer_number' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.usage_per_customer_number} placeholder='Enter number' className='form-control' />
                            <span className='text-danger d-flex text-left'>{formik.errors.usage_per_customer_number && formik.touched.usage_per_customer_number && formik.errors.usage_per_customer_number}</span>
                          </div>
                        )}
                        <span className='text-danger d-flex text-left'>{formik.errors.usagePerCustomer && formik.touched.usagePerCustomer && formik.errors.usagePerCustomer}</span>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 text-center form-group mt-3'>
                    <label for="order_mode" className="text-left d-flex">Customer Type<span className="requirestar">*</span></label>
                    <div className='row ps-3 mt-3'>
                      <div className="form-check w-25 text-start">
                        <input className="form-check-input cp" type="radio" name="customerType" checked={formik.values.customerType === 'all'} id="All" value="all" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        <label className="form-check-label cp" for="All">All</label>
                      </div>
                      <div className="form-check w-25 text-start">
                        <input className="form-check-input cp" type="radio" name="customerType" checked={formik.values.customerType === 'new'} id="New" value="new" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        <label className="form-check-label cp" for="New">New</label>
                      </div>
                      <div className="form-check w-25 text-start">
                        <input className="form-check-input cp" type="radio" name="customerType" checked={formik.values.customerType === 'existing'} id="Existing" value="existing" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        <label className="form-check-label cp" for="Existing">Existing</label>
                      </div>
                    </div>
                    <span className='text-danger d-flex text-left'>{formik.errors.customerType && formik.touched.customerType && formik.errors.customerType}</span>
                  </div>
                  <div className='col-lg-6 text-center form-group mt-3'>
                  </div>
                  <div className='col-lg-6 text-center form-group mt-3'>
                    <label for="order_mode" className="text-left d-flex">Authentication Type<span className="requirestar">*</span></label>
                    <div className='row ps-3 mt-3'>
                      <div className="form-check w-25 text-start">
                        <input className="form-check-input cp" type="radio" name="authenticationType" checked={formik.values.authenticationType === 'no-authentication'} id="NoAuthentication" value="no-authentication" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        <label className="form-check-label cp" for="NoAuthentication">No authentication</label>
                      </div>
                      <div className="form-check w-25 text-start">
                        <input className="form-check-input cp" type="radio" name="authenticationType" checked={formik.values.authenticationType === 'phone_otp_authentication'} id="PhoneOtpAuthentication" value="phone_otp_authentication" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        <label className="form-check-label cp" for="PhoneOtpAuthentication">Customer phone otp authentication</label>
                      </div>
                      <div className="form-check w-25 text-start">
                        <input className="form-check-input cp" type="radio" name="authenticationType" checked={formik.values.authenticationType === 'authentication_approval'} id="AuthenticationApproval" value="authentication_approval" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        <label className="form-check-label cp" for="AuthenticationApproval">Authentication Approval</label>
                      </div>
                    </div>
                    {formik.values.authenticationType === 'authentication_approval' && (
                      <div className='col-lg-6 text-center form-group mt-2'>
                        <label htmlFor='authenticateUser' className='text-left d-flex'></label>
                        {/* <CustomMultiSelect data={UserData} overFlowVisibleOptions={1} form={formik} name='authenticateUser' /> */}
                        <select
                        className={`form-control cp ${data && data.length > 5 ? 'scrollable-select' : ''}`}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          formik.setFieldValue("authenticateUser", selectedValue ? [selectedValue] : []);
                        }}
                        id="authenticateUser"
                        value={formik?.values?.authenticateUser}
                      >
                        <option value="">Select User</option>
                        {data &&
                          data.length > 0 &&
                          data.map((option, i) => (
                            <option key={i} value={option?.id}>
                              {capitalizeFirstLetter(option?.first_name + " " + option?.last_name)}
                            </option>
                          ))}
                      </select>
                        <span className='text-danger d-flex text-left'>{formik.errors.authenticateUser && formik.touched.authenticateUser && formik.errors.authenticateUser}</span>
                      </div>
                    )}
                    <span className='text-danger d-flex text-left'>{formik.errors.authenticationType && formik.touched.authenticationType && formik.errors.authenticationType}</span>
                  </div>
                  <div className="mt-2">
                    <button className="btn btn-primary mr-2" type="submit" >
                      {searchParams.get('copy') === 'true' ? 'Create' : 'Update'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : <Loader />}
      </form>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        autoClose={true | 1000}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
    </>
  );
}

export default CouponEdit